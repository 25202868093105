import { Field } from 'formik';
import React, { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';

const BooleanCustom = ({ props }) => {
    const [checked, setChecked] = useState(false)
    const checkboxChange = (e) => {
        props.value = e.target.checked
        setChecked(e.target.checked)
    }

    return (
        <>
            <div className="row my-2">
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <label>
                        {props.friendlyName}
                    </label>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                    <Field type="checkbox" className={'form-control'} style={{ 'height': '1.25rem' }}
                            name={props.fieldName} checked={checked}
                            onChange={(e) => checkboxChange(e)} 
                    />
                </div>
            </div>
        </>
    )
}
export default BooleanCustom;