import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';


const ListGroups = ({ match }) => {
  const { path } = match;
  const user = accountService.userValue;
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    setIsInitialLoading(true)
    accountService.getGroupsData().then(x => {
      setGroups(x.Items)
      setIsLoading(false)
      setIsInitialLoading(false)
    });
  }, [groups.length == 0]);

  const handleSearch = (e) => {
    setIsLoading(true)
    const query = e.target.value
    accountService.searchGroups(query).then(x => {
      setGroups(x.Items)
      setIsLoading(false)
    })
  };

  function deleteUser(id) {
    // setUsers(users.map(x => {
    //     if (x.id === id) { x.isDeleting = true; }
    //     return x;
    // }));
    // accountService.delete(id).then(() => {
    //     setUsers(users => users.filter(x => x.id !== id));
    // });
  }
  return (
    <div>
      {user && (user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.GroupAdmin.length > 0) &&
        <>

          <div className="row">
            <div className='col-12 mb-5'>
              {groups && <div className='row'>
                <div className="col-lg-6">

                  <h3 className="page-title">
                    <span className="text-white mr-2">
                      {/* <i className="mdi mdi-home-group"></i> */}
                    </span> Groups </h3>
                </div>
                <div className="col-lg-6 text-right">
                  <input type="text" className="form-control border-0" style={{ "boxShadow": '#63636333 0px 2px 8px 0px' }} disabled={isInitialLoading} placeholder={isInitialLoading ? "Loading... Please wait." : "Search Groups"} onChange={e => handleSearch(e)} />
                  <p className="col-lg-12 text-left mb-4">Please enter at least 3 characters to search</p>
                </div>
              </div>}
            </div>

            {isLoading &&
              <div className='col-12 text-center'>
                <LoadingIndicator />
              </div>
            }
          </div>
          <div className='row'>
            {groups && !isLoading && groups.map((group, key) =>
              <div className="col-md-4 grid-margin stretch-card" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="clearfix mb-4">
                      <h4 className="card-title float-left">{group.ClientName}</h4>
                      <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right">
                        <ul>
                          <li>
                            <Link to={`/edit-group/${group.name}`} className="btn btn-sm btn-primary btn-primary-new mr-1">Edit</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='card-description'>
                      <div className='row'>
                        <div className='col-12'>
                          <img src={group.GroupURL && group.GroupURL || "http://hereafter.in/surrogate/Server/imageData/persons/NoContact.jpg"} width="100" />
                          <br />
                          <b>Group ID:</b> {group.name}

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
      {user && (user.Item.Abilities.isDeveloper == 'false' && user.Item.Abilities.isGlobalAdmin == 'false' && user.Item.Abilities.GroupAdmin.length == 0) &&
        <div className="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
          <div className="row flex-grow">
            <div className="col-lg-8 mx-auto text-white">
              <div className="row align-items-center d-flex flex-row">
                <div className="col-lg-6 text-lg-right pr-lg-4">
                  <h1 className="display-1 mb-0">404</h1>
                </div>
                <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>SORRY!</h2>
                  <h3 className="font-weight-light">The page you’re looking for was not found.</h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 text-center mt-xl-2">
                  <Link className="text-white font-weight-medium" to="/dashboard">Back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ListGroups;