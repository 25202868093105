import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";

// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const GroupAdmin = ({ability, setFieldValue, type, setAbilities}) => {
//console.log("ability",ability)
  const onSubmit = (values) => {
    setAbilities(values.GroupAdmin)
      //console.log("values", values)
    //   setFieldValue('Favorites', arrayHelpers, false)
  }
  return(
    <div>
    {
       ability && ability.length > 0 &&
        <Formik
            initialValues={{ GroupAdmin: ability }}
            onSubmit={values =>
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // }, 500)
                onSubmit(values)
            }
            render={({ values }) => (
                <Form>
                <FieldArray
                    name="GroupAdmin"
                    render={arrayHelpers => (
                    <div>
                        
                        {values.GroupAdmin && values.GroupAdmin.length > 0 ? (
                        values.GroupAdmin.map((friend, index) => (
                            <div key={index} className={'field-arrangement'}>
                            <Field name={`GroupAdmin.${index}`} className="form-control"/>
                            <button
                                type="button"
                                className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                                -
                            </button>
                            <button
                                type="button" className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                            >
                                +
                            </button>
                            </div>
                        ))
                        ) : (
                        <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-sm btn-primary btn-primary-new mb-2">
                            {/* show this when user has removed all GroupAdmin from the list */}
                            +
                        </button>
                        )}
                        <div>
                        <button type="submit" className="btn btn-sm btn-primary btn-primary-new">Save Group Admin</button>
                        </div>
                    </div>
                    )}
                />
                </Form>
      )}
    />
    }
    {
       ability && ability.length == 0 && 
        <Formik
            initialValues={{ GroupAdmin: ability }}
            onSubmit={values =>
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // }, 500)
                onSubmit(values)
            }
            render={({ values }) => (
                <Form>
                <FieldArray
                    name="GroupAdmin"
                    render={arrayHelpers => (
                    <div>
                        {values.GroupAdmin && values.GroupAdmin.length > 0 ? (
                        values.GroupAdmin.map((friend, index) => (
                            <div key={index} className={'field-arrangement'}>
                            <Field name={`GroupAdmin.${index}`} className="form-control"/>
                            <button
                                type="button"
                                className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                                -
                            </button>
                            <button
                                type="button" className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                            >
                                +
                            </button>
                            </div>
                        ))
                        ) : (
                        <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-sm btn-primary btn-primary-new mb-2" title={'Add a Group Admin'}>
                            {/* show this when user has removed all Favorites from the list */}
                            +
                        </button>
                        )}
                        <div>
                        <button type="submit" className="btn btn-sm btn-primary btn-primary-new" title={'Click to Submit GroupAdmin before saving'}>Save</button>
                        </div>
                    </div>
                    )}
                />
                </Form>
      )}
    />
    }
  </div>
  )
  };

export default GroupAdmin;
