import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";

// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const DownloadsArray = ({items, setFieldValue, type, setDownloads}) => {

  const onSubmitDownloads = (values) => {
        setDownloads(values.DownloadsRequired)
      //console.log("values", values)
    //   setFieldValue('DownloadsRequired', arrayHelpers, false)
  }
  return(
    <div>
    {
       items && items.length > 0 && 
        <Formik
            initialValues={{ DownloadsRequired: items }}
            onSubmit={values =>
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // }, 500)
                onSubmitDownloads(values)
            }
            render={({ values }) => (
                <Form>
                <FieldArray
                    name="DownloadsRequired"
                    render={arrayHelpers => (
                    <div>
                        
                        {values.DownloadsRequired && values.DownloadsRequired.length > 0 ? (
                        values.DownloadsRequired.map((friend, index) => (
                            <div key={index} className={'field-arrangement'}>
                            <Field name={`DownloadsRequired.${index}`} className="form-control"/>
                            <button
                                type="button"
                                className="btn btn-inverse-success btn-icon"
                                onClick={() => values.DownloadsRequired.length > 1 && arrayHelpers.remove(index)} // remove a friend from the list
                            >
                                -
                            </button>
                            <button
                                type="button" className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                            >
                                +
                            </button>
                            </div>
                        ))
                        ) : (
                        <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                            {/* show this when user has removed all DownloadsRequired from the list */}
                            Add a Downloads
                        </button>
                        )}
                        <div>
                        <button type="submit" className="btn btn-primary">Submit Downloads before saving</button>
                        </div>
                    </div>
                    )}
                />
                </Form>
      )}
    />
    }
    {
       items && items.length == 0 && 
        <Formik
            initialValues={{ DownloadsRequired: items }}
            onSubmit={values =>
                // setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // }, 500)
                onSubmitDownloads(values)
            }
            render={({ values }) => (
                <div>
                <FieldArray
                    name="DownloadsRequired"
                    render={arrayHelpers => (
                    <div>
                        
                        {values.DownloadsRequired && values.DownloadsRequired.length > 0 ? (
                        values.DownloadsRequired.map((friend, index) => (
                            <div key={index} className={'field-arrangement'}>
                            <Field name={`DownloadsRequired.${index}`} className="form-control"/>
                            <button
                                type="button"
                                className="btn btn-inverse-success btn-icon"
                                onClick={() => values.DownloadsRequired.length > 1 && arrayHelpers.remove(index)} // remove a friend from the list
                            >
                                -
                            </button>
                            <button
                                type="button" className="btn btn-inverse-success btn-icon"
                                onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                            >
                                +
                            </button>
                            </div>
                        ))
                        ) : (
                        <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                            {/* show this when user has removed all DownloadsRequired from the list */}
                            Add a Download
                        </button>
                        )}
                        <div>
                        <button type="submit" className="btn btn-primary">Submit Downloads before saving</button>
                        </div>
                    </div>
                    )}
                />
                </div>
      )}
    />
    }
  </div>
  )
  };

export default DownloadsArray;
