import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import BooleanCustom from './Core/BooleanCustom';
import ColorCustom from './Core/ColorCustom';
import DateTimeCustom from './Core/DateTimeCustom';
import EnumCustom from './Core/EnumCustom';
import FileLinkCustom from './Core/FileLinkCustom';
import ImageLinkCustom from './Core/ImageLinkCustom';
import PdfCustom from './Core/PdfCustom';
import TextCustom from './Core/TextCustom';
import WorldIdCustom from './Core/WorldIdCustom';
import CoreCustom from './CoreCustom';

const SplitCustom = ({ isLoading, setIsLoading, CF, changeCustomFields, keyName, setCustomFieldStructure, formikRef, mapName, setFilledCustomFields, filledCustomFields }) => {
    console.log(`Custom Fields Data SplitCustom cod1: `, CF)
    //console.log(`Custom Fields Key Name: `,keyName)
    // filledCustomFields && filledCustomFields[0] === 'PLAYZONE_left_playlistVideo' && console.log(`codx Displaying ${filledCustomFields} in SplitCustom for ${mapName} : ${JSON.stringify(filledCustomFields)} ${JSON.stringify(keyName)}`)

    const [splitRepeatFields, setSplitRepeatFields] = useState({})
    const repeatFields = (e, rep) => {
        e.preventDefault()
        let counter = 0
        //const splitRepeatField = CF.splitRepeat;

        counter += ['Options', 'Correct'].includes(rep[0]) ? Object.entries(splitRepeatFields).filter(entry => entry[0].startsWith(rep[0])).length : Object.entries(splitRepeatFields).length
        const newItems = rep.reduce((key, item) => { return { [key + counter]: item } })
        //Object.assign({}, CF.splitRepeat, newItems)

        console.log("splitRepeatFields", newItems)
        setSplitRepeatFields(Object.assign({}, splitRepeatFields, newItems))
    }

    useEffect(() => {
        const cfSplitRepeatField = Object.entries(CF.splitRepeat)
        console.log("setSplitRepeatFields filledCustomFields codx =======>", filledCustomFields, CF, cfSplitRepeatField)
        if (cfSplitRepeatField.length > 0) {
            const splitRepeatFields = {}
            if (cfSplitRepeatField[0].length && (Array.isArray(filledCustomFields) && (filledCustomFields).length)) {
                Object.keys(filledCustomFields[1]).filter(field => field.includes(cfSplitRepeatField[0][0])).forEach(splitRepeatField => { splitRepeatFields[splitRepeatField] = cfSplitRepeatField[0][1] })
                if (mapName.startsWith('Vote_Q')) {
                    Object.keys(filledCustomFields[1]).filter(field => field.includes(cfSplitRepeatField[1][0])).forEach(splitRepeatField => { splitRepeatFields[splitRepeatField] = cfSplitRepeatField[1][1] })
                }
                setSplitRepeatFields(splitRepeatFields)
            } else {
                setSplitRepeatFields(CF.splitRepeat)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div
            //  style={{"border" :"2px solid red"}}
            >
                {/* SPLIT Custom*****************************<br /><br /><br /> */}
                {/* <h4>{CF.friendlyName}</h4> */}
                {/* Displaying filledCustomFields in SplitCustom for {mapName} : {JSON.stringify(filledCustomFields)} {JSON.stringify(keyName)} */}
                {
                    (
                        Object.entries(CF.splitFields).length > 0 && (
                            Object.entries(CF.splitFields).map((customField, index) => (
                                <>
                                    {index === 0 && <br />}
                                    {/* Displaying fieldName in SplitCustom CoreCustom: {JSON.stringify(customField)} */}
                                    <CoreCustom isLoading={isLoading} setIsLoading={setIsLoading} customField={customField} changeCustomFields={changeCustomFields} mapName={mapName} keyName={keyName} friendlyName={CF.friendlyName} filledCustomFields={filledCustomFields}
                                    //changeCustomFields={changeCustomFields} key={customField[0]} setFilledCustomFields={setFilledCustomFields} 
                                    //CFData = {Object.entries(formikRef.current.values.CustomFields).filter(cf=>cf[0]==customField.mapName)[0]}
                                    />
                                </>
                            )

                            )
                        )
                    )



           /* <div className="form-group col-6">
                
                <div className="card bg-light">
                <label>{CF.mapName}</label><br/><br/>
                </div>
                {customFieldsData.length > 0 && 
                    customFieldsData.map((spl) => (
                                
                                    spl['type']== "TEXT" &&
                                    <div className="form-group col-12">
                                        <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <TextCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                        </div>
                                        </div>
                                 ||
                                 spl['type']== "WORLDID" &&
                                 <div className="form-group col-12">
                                     <div className="card bg-light">
                                         <label>{spl[0]}</label>
                                         <WorldIdCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']}  mapName={CF.mapName}/>
                                     </div>
                                     </div>
                                ||
                                
                                 spl['type']== "FILELINK" &&
                                    <div className="form-group col-12">
                                    <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <FileLinkCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                ||
                                
                                spl['type']== "BOOLEAN" &&
                                    <div className="form-group col-12">
                                        <div className="bg-light d-flex justify-content-center align-items-center">
                                            <label>{spl[0]}</label>
                                            <BooleanCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                ||
                                
                                spl['type'].split("(")[0] == "ENUM" &&
                                    <div className="form-group col-12">
                                        <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <EnumCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                ||
                                
                                spl['type']== "IMAGELINK" &&
                                    <div className="form-group col-12">
                                        <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <ImageLinkCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                ||

                                spl['type']== "PDFLINK" &&
                                <div className="form-group col-12">
                                    <div className="card bg-light">
                                        <label>{spl[0]}</label>
                                        <PdfCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                        </div>
                                    </div>
                                ||
                                
                                spl['type']== "DATETIME" &&
                                    <div className="form-group col-12">
                                        <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <DateTimeCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']}  mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                ||
                                
                                spl['type']== "COLOR" &&
                                    <div className="form-group col-12">
                                        <div className="card bg-light">
                                            <label>{spl[0]}</label>
                                            <ColorCustom customName={spl} changeCustomFields={e=>changeCustomFields(e, CF.mapName)} textVal={spl['value']} mapName={CF.mapName}/>
                                            </div>
                                        </div>
                                
                            )
                    )  
                }
            </div> */}
                {Object.entries(CF.splitRepeat).length > 0 && (
                    Object.entries(CF.splitRepeat).map(rep => (
                        <div className='d-flex align-items-center justify-content-center'>
                            <h4>{rep[0]}</h4>
                            <button className='btn btn-link col-4' onClick={e => repeatFields(e, rep)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#198754" class="bi bi-file-plus-fill" viewBox="0 0 16 16">
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z" />
                                </svg>
                                Add {rep[0]}</button><br /> <br />
                        </div>
                    )))
                }
                {
                    (
                        Object.entries(splitRepeatFields).length > 0 && (
                            Object.entries(splitRepeatFields).map(customField => (
                                < CoreCustom isLoading={isLoading} setIsLoading={setIsLoading} customField={customField} changeCustomFields={changeCustomFields} mapName={mapName} keyName={keyName} friendlyName={CF.friendlyName} filledCustomFields={filledCustomFields}
                                //changeCustomFields={changeCustomFields} key={customField[0]} setFilledCustomFields={setFilledCustomFields} 
                                //CFData = {Object.entries(formikRef.current.values.CustomFields).filter(cf=>cf[0]==customField.mapName)[0]}
                                />
                            )

                            )
                        )
                    )
                }
                {/* End SPLIT Custom*****************************<br /><br /><br /> */}
            </div>
        </>
    )
}
export default SplitCustom;