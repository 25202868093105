import React from 'react'
import Core from '../Core'

const SplitFields = ({splitFields}) => {
    return (
        <>
         <h1>SPLIT FIELDS</h1>
            {
                Object.values(splitFields).map((splitField, k) => (splitField.fType === 'CORE') && (
                            <div key={k}>
                                <Core coreFields={splitField} />
                            </div>
                        )
                )
            }

        </>
    )
}

export default SplitFields