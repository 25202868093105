import { ProgressBar } from 'react-bootstrap';
import {Bar, Doughnut} from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Role } from '../_helpers';

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";



const GroupAccess = ({ match }) => {
    const { path } = match;
    const user = accountService.userValue;
    const [groups, setGroups] = useState({})
    const [allPendingGroups, setAllPendingGroups] = useState({})
    const [allActiveGroups, setAllActiveGroups] = useState({})
    const [allRejectedGroups, setAllRejectedGroups] = useState({})
    const [updatedUsers, setUpdatedUsers] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(()=>{ 
        if(user.Item.Abilities.isGlobalAdmin|| (user.Item.Abilities && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)){
            accountService.getAllGroups('Pending').then(x => {
                //console.log("all x",x)
                setAllPendingGroups(x)
            });
            accountService.getAllGroups('Approved').then(x => {
                //console.log("all x",x)
                setAllActiveGroups(x)
            });
            accountService.getAllGroups('Rejected').then(x => {
                //console.log("all x",x)
                setAllRejectedGroups(x)
            });
        }  
        
        if(user.Item.Role != Role.Admin){
            accountService.getGroups().then(x => {
                //console.log("x",x)
                setGroups(x)
            });
        }
       
    },[updatedUsers])

    const ApproveUsers =(groupName)=>{
        setIsLoading(true)
        accountService.approveUsersForGroups(groupName).then(x => {
            setUpdatedUsers(true)
            setIsLoading(false)
            setTimeout(()=>setUpdatedUsers(false))
            alertService.success(x.message)
        });
    }
    const RejectUsers =(groupName)=>{
        setIsLoading(true)
        accountService.rejectUsersForGroups(groupName).then(x => {
            setIsLoading(false)
            alertService.success(x.message)
        });
    }

    const OwningGroup = (id, groupName) => {
        setIsSubmitting(true);
       //console.log('groupName', groupName)
        accountService.getById(id).then(user => {
            user.OwningGroup = groupName;
            accountService.update(id, user)
            .then(() => {
                alertService.success('User Updated successfully', { keepAfterRouteChange: true });
                setIsSubmitting(false);
                // history.push("/users");
            })
            .catch(error => {
                setIsSubmitting(false);
                alertService.error(error);
            });
            // const fields = ['username', 'FirstName', 'LastName', 'Email', 'DisplayName', 'OwningGroup','Gender', 'Favorites', 'FaceImageURL', 'CompanyImageURL', 'phoneNumber', 'Company', 'Locale', 'StatusLine', 'Groups', 'Abilities', 'Avatar'];
            // fields.forEach(field => formikRef.current.setFieldValue(field, user[field], false));
            // if(user.Abilities.GroupAdmin == undefined){
            //   formikRef.current.setFieldValue('Abilities', {...formikRef.current.values.Abilities, ['GroupAdmin']: []}, false)
            // }
            // setImages({company: user.CompanyImageURL, face: user.FaceImageURL})
            // setFav(user.Favorites)
            // setAbilities(user.Abilities.GroupAdmin && user.Abilities.GroupAdmin.length > 0 ? user.Abilities.GroupAdmin : [])
            // setGroups(user.Groups)
        });
    }
    return (
      <div>
        <div className="page-header">
          {/* <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <i className="mdi mdi-group"></i>
            </span> User Group Permissions </h3> */}
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                   
                  </li>
                </ul>
              </nav>
            </div>
            
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
              <div>
                {isLoading && <LoadingIndicator />}
                {(user.Item.Abilities.isGlobalAdmin || (user.Item.Abilities && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) && Object.keys(allPendingGroups).length > 0 && allPendingGroups.Items.length > 0 &&
                    <>
                        {/* <h4>
                            User Groups Permissions
                        </h4> */}
                        <h3>Pending Users</h3>
                        <div style={{ overflowX: "auto" }}>
                        <table className="table">
                            <thead style={{'background': '#daf0ff'}}>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Group Name</th>
                                <th scope="col">status</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Actions</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                
                                allPendingGroups.Items.map(allGroup=>(
                                    <>
                                        <tbody key={allGroup.request_id}>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>{allGroup.group}</td>
                                                <td>{allGroup.status}</td>
                                                <td>{allGroup.user}</td>
                                                <td><button type='submit' className='btn btn-success' onClick={()=> ApproveUsers(allGroup.request_id)}>Approve</button> &nbsp;
                                                <button type='submit' className='btn btn-danger' onClick={()=>RejectUsers(allGroup.request_id)}>Reject</button>
                                                {(user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') && 
                                                <button type='submit' className='btn btn-primary' disabled={isSubmitting} onClick={()=>OwningGroup(allGroup.user, allGroup.group)}>
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Make Owning Group</button>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>
                                ))
                            }
                            </table>
                        </div>
                          
                        {/* <Link to={`${path}/update`}>Details</Link> */}
                    </>
                    
                }
                {(user.Item.Role === Role.Admin || (user.Item.Abilities && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) && Object.keys(allPendingGroups).length > 0 && allPendingGroups.Items.length == 0 && "No Pending Requests Found"}
                <br /><br />
                {(user.Item.Role === Role.Admin || (user.Item.Abilities && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) && Object.keys(allActiveGroups).length > 0 &&
                    <>
                        <h3>Approved Users</h3>
                        <div style={{ overflowX: "auto" }}>
                        <table className="table">
                        <thead style={{'background': '#daf0ff'}}>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Group Name</th>
                                <th scope="col">status</th>
                                <th scope="col">Actions</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                
                                allActiveGroups.Items.map(allGroup=>(
                                    <>
                                        <tbody key={allGroup.group}>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>{allGroup.group}</td>
                                                <td>{allGroup.status}</td>
                                                <td>{allGroup.user}</td>
                                                <td>@mdo</td>
                                            </tr>
                                        </tbody>
                                    </>
                                ))
                            }
                            </table>
                            </div>
                    </>
                    
                }
                <br /><br />
                {(user.Item.Role === Role.Admin || (user.Item.Abilities  && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) && Object.keys(allRejectedGroups).length > 0 &&
                    <>
                        <h3>Rejected Users</h3>
                        <div style={{ overflowX: "auto" }}>
                        <table className="table">
                        <thead style={{'background': '#daf0ff'}}>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Group Name</th>
                                <th scope="col">status</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Actions</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            {
                                
                                allRejectedGroups.Items.map(allGroup=>(
                                    <>
                                        <tbody key={allGroup.group}>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>{allGroup.group}</td>
                                                <td>{allGroup.status}</td>
                                                <td>@mdo</td>
                                            </tr>
                                        </tbody>
                                    </>
                                ))
                            }
                            </table>
                            </div>   
                    </>
                    
                }

                {user.Item.Role != Role.Admin && Object.keys(groups).length > 0 &&
                    <>
                        <div style={{ overflowX: "auto" }}>
                        {groups.Items.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Group Name</th>
                                    <th scope="col">status</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Actions</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                {
                                    
                                    groups.Items.map(group=>(
                                        <>
                                            <tbody key={group.group}>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>{group.group}</td>
                                                    <td>{group.status}</td>
                                                    <td>{group.user}</td>
                                                    <td>@mdo</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))
                                }
                            </table>
                        }
                        </div>
                        {Object.keys(groups).length > 0 && groups.Items.length == 0 && <>No Groups Found</>}
                        <br />                    </>
                }
            </div>
          </div>
          </div>
          </div>
        </div>
        
      </div> 
    );
}

export default GroupAccess;