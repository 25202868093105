import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';

const BooleanCustom = ({ CF, changeCustomFields, innerKeyName, mapName, filledCustomFields }) => {
    // console.log("boolean values", filledCustomFields)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (filledCustomFields && filledCustomFields[1] == 'true') {
            setChecked(true)
        }
    }, [filledCustomFields && filledCustomFields[1]])
    const checkboxChange = (e) => {
        changeCustomFields({ target: { name: CF[0], value: e.target.checked ? 'true' : 'false' } }, CF[0], mapName)
        setChecked(e.target.checked)
    }
    return (
        <>
            <div className="row">
                <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {CF[0]}
                    </label>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                    <Field type="checkbox" className={'form-control'} name={CF[0]} checked={checked}
                        onChange={(e) => checkboxChange(e)} style={{ 'height': '1.25rem' }}
                    />
                </div>
            </div>
        </>
    )
}
export default BooleanCustom;