import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import InputColor from 'react-input-color';

const ColorCustom = ({props}) =>{

    return (
        <>
          <div className="row my-2">
          <div className="col-4 d-flex align-items-center justify-content-end">
            <label>
                {props.friendlyName}
            </label>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
            <Field type="text" className={'form-control'} />
                <InputColor 
                placement="right"
            />
             </div>
            </div>
        </>
    )
}
export default ColorCustom;