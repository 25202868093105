import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';


const ListMaps = ({ match }) => {
  const { path } = match;
  const user = accountService.userValue;
  const [maps, setMaps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  useEffect(() => {
    fetchMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps.length === 0 && searchTerm.length === 0 && !isLoading]);

  const fetchMore = () => {
    setIsLoading(true)
    setIsInitialLoading(true)
    accountService.getAllMaps().then(x => {
      setMaps(x.Items)
      setIsLoading(false)
      setIsInitialLoading(false)
    });
  }
  const handleSearch = (e) => {
    const query = e.target.value
    setSearchTerm(String(query.trim()))
    if (query.length === 0) { fetchMore() } else {
      if (String(query.trim()).length < 3) return
      setIsLoading(true)
      accountService.searchMaps(query).then(x => {
        setMaps(x.Items)
        setIsLoading(false)
      })
    }
  };

  function deleteUser(id) {
    // setUsers(users.map(x => {
    //     if (x.id === id) { x.isDeleting = true; }
    //     return x;
    // }));
    // accountService.delete(id).then(() => {
    //     setUsers(users => users.filter(x => x.id !== id));
    // });
  }
  return (
    <div>

      <div className="row">
        <div className="col-12 mb-5">

          <div className='row justify-content-end d-flex'>
            <div className="col-lg-6">
              <h3 className="page-title">
                <span className="text-white mr-2">
                  {/* <i className="mdi mdi-google-maps"></i> */}
                </span> Maps </h3>
            </div>
            <div className="col-lg-6 text-right">
              <input type="text" className="form-control border-0" disabled={isInitialLoading} placeholder={isInitialLoading ? "Loading... Please wait." : "Search Maps"} style={{ "boxShadow": '#63636333 0px 2px 8px 0px' }} onChange={e => handleSearch(e)} />
              <p className=" text-left mb-4">Please enter at least 3 characters to search</p>
            </div>
          </div>

          <div className='row'>
            {maps && !isLoading && maps.map((map, key) =>
              <div className="col-md-4 grid-margin stretch-card" key={key}>
                <div className="card">
                  <div className="card-body">
                    <div className="clearfix mb-4">
                      <h4 className="card-title float-left">{map.mapName}</h4>

                    </div>
                    <div className='card-description'>
                      <div className='row'>
                        <div className='col-12'>
                          <ul>
                            <li><b>Map Name:</b> {map.mapName} </li>
                            <li><b>	World Name:</b> {map.WorldName}</li>
                            <li><b>Live:</b> {map.Live}</li>

                            {/* <li><b>Custom Fields:</b>    
                            <ul>{
                              Object.keys(world.CustomFields).length > 0 && 
                                Object.entries(world.CustomFields).map(([k, value]) =>(
                                      <li>{k} : {value}</li>
                                ))
                            } </ul>
                          </li>

                          <li><b>Downloads Required:</b>    
                            <ul>{
                              world.DownloadsRequired.length > 0 && 
                              world.DownloadsRequired.map(([k, value]) =>(
                                      <li>{k} : {value}</li>
                                ))
                            } </ul>
                          </li>
                          <li><b>Custom Admins:</b>    
                            <ul>{
                              (world.CustomAdmins).length > 0 && 
                              world.CustomAdmins.map(([k, value]) =>(
                                      <li>{k} : {value}</li>
                                ))
                            } </ul>
                          </li>
                          <li><b>Pak Downloads Required:</b>    
                            <ul>{
                              (world.PakDownloadsRequired).length > 0 && 
                              world.PakDownloadsRequired.map(([k, value]) =>(
                                      <li>{k} : {value}</li>
                                ))
                            } </ul>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-left">
                          <ul>
                            <li>
                              <Link to={`/edit-maps/${map.mapName}`} className="btn btn-sm btn-primary btn-primary-new mr-1">Edit</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row w-100">
            {maps && maps.length === 0 && !isLoading &&
              <>
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      No Maps Found
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
          {isLoading &&
            <div className='col-12 text-center'>
              <LoadingIndicator />
            </div>
          }


        </div>
      </div>

    </div>
  );
}

export default ListMaps;