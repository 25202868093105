import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import useWindowWidth from '../_hooks/useWindowWidth';
import classnames from 'classnames'


const ListWorlds = ({ match }) => {
  const { path } = match;
  const user = accountService.userValue;
  const [worlds, setWorlds] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const windowWidth = useWindowWidth();
  useEffect(() => {
    if ((worlds && worlds.length === 0) && !isLoading)
      fetchMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worlds && worlds.length === 0 && searchTerm.length === 0]);

  const handleSearch = (e) => {
    const query = e.target.value
    setSearchTerm(query)
    if (query.length === 0) { fetchMore() } else {
      if (String(query?.trim()).length < 3) return
      setIsLoading(true)
      setIsInitialLoading(true)
      setWorlds([])
      const delayDebounceFn = setTimeout(() => {
        callSearch(query)
        // Send Axios request here
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  };

  const fetchMore = (e) => {
    if (searchTerm.trim()) {
      callSearch(searchTerm)
    } else {
      setIsLoading(true)
      setIsInitialLoading(true)
      if (user?.Item?.Abilities && ((user?.Item?.Abilities.GroupAdmin && user?.Item?.Abilities.GroupAdmin.length > 0) || user?.Item?.Abilities?.isGlobalAdmin)) {
        accountService.getAllWorlds(lastEvaluatedKey).then(x => {
          setWorlds((prevWorlds = []) => [...prevWorlds, ...x.Items])
          setHasMore(x.Count === 20)
          setLastEvaluatedKey(x.LastEvaluatedKey ? JSON.stringify(x.LastEvaluatedKey) : '')
          setIsLoading(false)
          setIsInitialLoading(false)
        });
      };
    }
  }

  const callSearch = (query) => {
    setIsLoading(true)
    setIsInitialLoading(true)
    if (user?.Item?.Abilities && ((user?.Item?.Abilities.GroupAdmin && user?.Item?.Abilities.GroupAdmin.length > 0) || user?.Item?.Abilities?.isGlobalAdmin)) {
      accountService.searchWorlds(query, lastEvaluatedKey).then(x => {
        setWorlds((prevWorlds = []) => [...prevWorlds, ...x.Items])
        setHasMore(x.Count === 20)
        setLastEvaluatedKey(x.LastEvaluatedKey ? JSON.stringify(x.LastEvaluatedKey) : '')
        setIsLoading(false)
        setIsInitialLoading(false)
      })
    }

  }
  function deleteUser(id) {
    // setUsers(users.map(x => {
    //     if (x.id === id) { x.isDeleting = true; }
    //     return x;
    // }));
    // accountService.delete(id).then(() => {
    //     setUsers(users => users.filter(x => x.id !== id));
    // });
  }
  return (
    <div>
      {user && (user?.Item?.Abilities.isDeveloper == 'true' || user?.Item?.Abilities.isGlobalAdmin == 'true' || (user?.Item?.Abilities && (user?.Item?.Abilities.GroupAdmin && user?.Item?.Abilities.GroupAdmin.length > 0))) &&
        <>

          <div className="row">
            <div className='col-12 mb-5'>
              {worlds && <div className='row'>
                <div className="col-lg-6">
                  <h3 className="page-title">
                    <span className="text-white mr-2">
                      {/* <i className="mdi mdi-earth"></i> */}
                    </span> Worlds </h3>
                </div>
                <div className="col-lg-6 text-right">
                  <input type="text" className="form-control border-0" disabled={isInitialLoading} placeholder={isInitialLoading ? "Loading... Please wait." : "Search Worlds"} onChange={e => handleSearch(e)} style={{ "boxShadow": '#63636333 0px 2px 8px 0px' }} />
                  <p className="col-lg-12 text-left mb-4">Please enter at least 3 characters to search</p>
                </div>
              </div>}
            </div>
            <div className='row w-100'>
              {worlds && worlds.map((world, key) =>
                <div className={classnames(windowWidth >= "1500" ? "col-md-2" : windowWidth >= "1300" ? "col-md-3" : "col-md-4",
                  "grid-margin", "stretch-card")} key={key}>
                  <div className="card">
                    <div className="card-body">
                      <div className="clearfix mb-4">
                        <h5 className="float-left" style={{ fontSize: '14px' }}> {world.WorldFriendlyName}</h5>
                      </div>
                      <div className='card-description'>
                        <div className='row'>
                          <div className='col-12'>

                            <img src={world.WorldImageURL && world.WorldImageURL || "http://hereafter.in/surrogate/Server/imageData/persons/NoContact.jpg"} width="100%" />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Link to={`/edit-world/${world.WorldID}`} className="btn btn-sm btn-primary btn-primary-new mr-1">Edit</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row w-100 d-flex justify-content-center">
              {worlds && worlds.length > 0 && !isLoading && lastEvaluatedKey && hasMore &&
                <button class="btn btn-secondary" style={{ color: 'black' }} onClick={fetchMore}>Load More</button>}
            </div>
            <div className="row w-100">
              {worlds && worlds.length == 0 && !isLoading &&
                <>
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        No Worlds Found
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
            {isLoading &&
              <div className='col-12 text-center'>
                <LoadingIndicator />
              </div>
            }
          </div>
        </>
      }
      {
        user && (user?.Item?.Abilities.isDeveloper == 'false' && user?.Item?.Abilities.isGlobalAdmin == 'false' && (user?.Item?.Abilities && (user?.Item?.Abilities.GroupAdmin == null))) &&
        <div className="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
          <div className="row flex-grow">
            <div className="col-lg-8 mx-auto text-white">
              <div className="row align-items-center d-flex flex-row">
                <div className="col-lg-6 text-lg-right pr-lg-4">
                  <h1 className="display-1 mb-0">404</h1>
                </div>
                <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                  <h2>SORRY!</h2>
                  <h3 className="font-weight-light">The page you’re looking for was not found.</h3>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 text-center mt-xl-2">
                  <Link className="text-white font-weight-medium" to="/dashboard">Back to home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );
}

export default ListWorlds;