import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../_services';

import SplitCustom from './SplitCustom';
import CoreCustom from './CoreCustom';

const MultiCustom = ({ isLoading, setCustomFieldsMetaData, setIsLoading, CF, changeCustomFields, setCustomFieldStructure, customFields, formikRef, CFData, mapName, formikData, filledCustomFields, setFilledCustomFields }) => {
    // console.log("Multi Fields", filledCustomFields)
    // console.log("Multi Fields CF", CF)
    useEffect(() => { console.log("MultiCustom Formik CustomField filledCustomField codex", formikRef.current.values.CustomFields, filledCustomFields) }, [filledCustomFields])
    const [configField, setConfigField] = useState([])
    const [configFieldRepeat, setConfigFieldRepeat] = useState([])

    // const checkData = filledCustomFields.map(mf=>{
    //     if(mf[0].split('_')[1].toLowerCase() == 'config'){
    //         setConfigField(mf)
    //     } else {
    //         setConfigFieldRepeat(prevState=> {
    //             return {...prevState, ...mf}
    //         })
    //     } 
    // })
    const [multiFieldsData, setMultiFieldsData] = useState([])
    const [multiFieldsRepeatData, setMultiFieldsRepeatData] = useState([])
    const [customMultiFields, setCustomMultiFields] = useState([])
    const [customMultiFieldsRepeat, setCustomMultiFieldsRepeat] = useState([])
    useEffect(() => {
        Object.entries(CF.multiFields).map(cf => {
            // console.log("useEffect 29 cod1 multiFields", cf)
            accountService.getCustomFields(cf[1]).then(fields => {
                setCustomFieldsMetaData((prevState => {
                    return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
                }))

                setCustomMultiFields(prevState => {
                    return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
                })
            })
        })
        // console.log("useEffect 29 cod1 multiFields", CF, customFields, filledCustomFields)
        Object.entries(CF.multiRepeat).map((cf, index) => {
            accountService.getCustomFields(cf[1]).then(fields => {
                setCustomFieldsMetaData((prevState => {
                    return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiFields }]
                }))
                setCustomMultiFieldsRepeat(prevState => {
                    return [...prevState, { ...fields.Item, 'mapName': fields.Item.friendlyName, 'keyName': CF.multiRepeat }]
                })
            })
        })
    }, [])

    useEffect(() => {
        console.log("MultiCustom useEffect 45", filledCustomFields, customMultiFieldsRepeat.length, Object.entries(CF.multiRepeat).length)
        if (filledCustomFields.length > 0 && (customMultiFieldsRepeat.length == Object.entries(CF.multiRepeat).length)) {
            const customFilledFields = [];
            filledCustomFields.map(fcf => {
                if (fcf[0].split('_').length > 1 && fcf[0].split('_')[1].toLowerCase() == 'config') {
                    setMultiFieldsData(fcf)
                }
                else {
                    if (Object.entries(fcf[1]).length > 1) {
                        const creatingCustomRepeat = customMultiFieldsRepeat.filter(mfp => mfp.fieldName == (fcf[0].split('_').length > 1 && (fcf[0].split('_')[0] === 'Vote' ? 'pollElement' : fcf[0].split('_')[1]))).length > 0 && customMultiFieldsRepeat.filter(mfp => mfp.fieldName == (fcf[0].split('_')[0] === 'Vote' ? 'pollElement' : fcf[0].split('_')[1]))[0] || []
                        console.log('creatingCustomRepeat useEffect 67', customMultiFieldsRepeat, creatingCustomRepeat)
                        if (Object.entries(creatingCustomRepeat).length > 0) {
                            const newObj = {
                                "multiFields": {},
                                "splitFields": creatingCustomRepeat.splitFields,
                                "fType": creatingCustomRepeat.fType,
                                "prefix": "<<ElementType>>" + fcf[0].split('_')[0],
                                "splitRepeat": creatingCustomRepeat.splitRepeat,
                                "multiRepeat": creatingCustomRepeat.multiRepeat,
                                "fieldName": fcf[0].split('_').length > 2 && fcf[0].split('_')[1] + '_' + fcf[0].split('_')[2] || fcf[0].split('_')[1],
                                "friendlyName": fcf[0],
                                "mapName": creatingCustomRepeat.mapName,
                                "keyName": {
                                    [creatingCustomRepeat.mapName]: creatingCustomRepeat.fieldName
                                },
                                "filledData": fcf
                            }
                            customFilledFields.push(newObj)
                        }
                    }
                }
            })
            console.log("customFilledFields MultiCustom 75 cod1", customFilledFields)
            if (customFilledFields.length)
                console.log('xxxxxxxxxxxxx01')
            setCustomMultiFieldsRepeat(customFilledFields)
        }
    }, [(customMultiFieldsRepeat.length == Object.entries(CF.multiRepeat).length)])
    useEffect(() => {
        if (filledCustomFields.length > 0) {
            console.log("filledCustomFields MultiCustom length cod1 93", filledCustomFields)
            filledCustomFields.map(fcf => {
                console.log("filledCustomFields MultiCustom length cod1 95", fcf[0], fcf[0]?.split('_'))
                if (fcf[0].split('_').length > 1 && fcf[0].split('_')[1].toLowerCase() == 'config') {
                    setMultiFieldsData(fcf)
                }
                if (fcf[0].split('_').length > 1 && fcf[0].split('_')[0] == 'Vote') {
                    setMultiFieldsData(fcf)
                }
            })
        }
    }, [customMultiFields.length > 0])

    useEffect(() => {
        if (customMultiFields.length > 0) {
            console.log("customMultiFields", customMultiFields)
        }
    }, [customMultiFields])

    useEffect(() => {
        if (configFieldRepeat.length > 0) {
            console.log("Config Fields data with repeat", configFieldRepeat)
        }
    }, [configFieldRepeat])

    useEffect(() => {
        if (multiFieldsData) {
            console.log("customFields multiFieldsData", multiFieldsData)
        }
    }, [multiFieldsData])

    useEffect(() => {
        if (customMultiFieldsRepeat) {
            // console.log("customFields multi repeat", customMultiFieldsRepeat)
        }
    }, [customMultiFieldsRepeat])

    // useEffect(() => {
    //     console.log("MultiCustom 114 useEffect filledCustomFields cod1", filledCustomFields)
    // }, [filledCustomFields])


    const repeatFields = (e, type, CF, changeCustomFields) => {
        const length = customMultiFieldsRepeat.length
        const clone = JSON.parse(JSON.stringify(CF));
        const name = clone.prefix.split(">>")[1] != null ? clone.prefix.split(">>")[1] : clone.fieldName
        console.log("MultiCustom repeatFields CF cod1", CF)
        e.preventDefault();
        if (type == 'split') {
            clone.fieldName = (CF.fieldName + '_' + length);
            clone.friendlyName = CF.friendlyName + '_' + length;
            clone.mapName = name;
            console.log("MultiCustom repeatFields customFields cod1", customFields)
            console.log("MultiCustom repeatFields filledCustomFields cod1", filledCustomFields)
            console.log("MultiCustom repeatFields clone cod1", clone)
            console.log("MultiCustom repeatFields CF cod1", CF)
            let filledData = {}
            if ('filledData' in clone) {
                filledData = clone.filledData[1]

            } else {
                filledData['ElementType'] = clone.prefix.split('>')[1]
                filledData['PlayType'] = 'Stream'
                for (let field in clone.splitFields) {
                    if (field === 'PlayType') continue
                    filledData[field] = clone.splitFields[field] === 'BOOLEAN' ? false : ''
                }
            }
            console.log("MultiCustom repeatFields filledData cod1", filledData)
            setFilledCustomFields((prevState) => [...prevState, [clone.friendlyName, filledData]])
            setCustomMultiFieldsRepeat(prevState => {
                console.log("MultiCustom setCustomMultiFieldsRepeat cod1", prevState)
                return [...prevState, clone]
            })


        }
        if (type == 'text') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'boolean') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'enum') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'worldid') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'imagelink') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'filelink') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'pdflink') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'color') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
        if (type == 'datetime') {
            clone.fieldName = (name + "_Config_" + length).toLowerCase();
            clone.friendlyName = name + '_Config ' + length;
            setCustomMultiFieldsRepeat(prevState => {
                return [...prevState, clone]
            })


        }
    }
    return (
        <>
            <div
            // style={{"border" :"2px solid blue"}}
            >
                {/* <h1>Custom Muti Fields</h1> */}
                <br /><br />
                {/* <p>Custom Muti Fields config---------------------</p> */}
                <h4>{CF.friendlyName}</h4>
                <div className="form-group col-12">
                    {/* {JSON.stringify(customMultiFields)} */}
                    {/* {JSON.stringify(filledCustomFields)} */}
                    <div className="card bg-light">
                        {
                            customMultiFields.length > 0 && (
                                customMultiFields.map(multiFieldsNoRepeat => (

                                    multiFieldsNoRepeat.fType == 'SPLIT' &&
                                    <>
                                        <SplitCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsNoRepeat} setCustomFieldStructure={setCustomFieldStructure} formikRef={formikRef} changeCustomFields={changeCustomFields} key={CF.fieldName} mapName={mapName + '_config'} keyName={multiFieldsNoRepeat.keyName} filledCustomFields={multiFieldsData.length > 0 && multiFieldsData}
                                        // CFData={CFData}
                                        // CFData={CFData.filter(cf=>{
                                        //     const validString = (cf[0].split('_') == null ? cf[0] : cf[0].split('_')[1]).indexOf(multiFieldsNoRepeat.mapName) != -1
                                        //     if(validString){
                                        //         return cf
                                        //     }
                                        //     }
                                        //     )[0]}
                                        />
                                        <hr />
                                    </>
                                    ||
                                    multiFieldsNoRepeat.fType == 'CORE' &&
                                    <>
                                        CORE<CoreCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsNoRepeat} changeCustomFields={changeCustomFields} mapName={mapName + '_config'} keyName={multiFieldsNoRepeat.keyName} friendlyName={CF.friendlyName} filledCustomFields={multiFieldsData.length > 0 && multiFieldsData} />
                                        <hr />
                                    </>
                                ))

                            )
                        }
                    </div>
                </div>
                {/* <p>End Custom Muti Fields config---------------------</p> <br /><br /> */}
                <br /><br />
                <div
                // style={{"border":"4px solid green", "margin": "10px"}}
                >

                    {/* <p>Custom Muti Fields Repeat config---------------------</p> */}
                    <div className="form-group col-12">
                        {/* {JSON.stringify(customMultiFieldsRepeat)} */}
                        <div className="card bg-light">
                            {
                                customMultiFieldsRepeat.length > 0 && (
                                    customMultiFieldsRepeat.map(multiFieldsRepeat => (

                                        multiFieldsRepeat.fType == 'SPLIT' && <>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <h4>{multiFieldsRepeat.friendlyName}</h4>
                                                <button className='btn btn-link col-4' onClick={e => repeatFields(e, 'split', multiFieldsRepeat, changeCustomFields)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#198754" class="bi bi-file-plus-fill" viewBox="0 0 16 16">
                                                        <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0z" />
                                                    </svg>
                                                    Add {multiFieldsRepeat.mapName === 'Vote' ? 'Poll Question' : multiFieldsRepeat.mapName}</button><br /> <br />
                                            </div>
                                            <SplitCustom setIsLoading={setIsLoading} isLoading={isLoading} formikRef={formikRef} setCustomFieldStructure={setCustomFieldStructure} CF={multiFieldsRepeat} changeCustomFields={changeCustomFields} key={CF.fieldName} mapName={mapName + '_' + multiFieldsRepeat.fieldName}
                                                CFData={CFData} keyName={multiFieldsRepeat.keyName}
                                                filledCustomFields={filledCustomFields && filledCustomFields.find(e => e[0] === mapName + '_' + multiFieldsRepeat.fieldName)}
                                            />
                                            <hr />
                                        </>
                                        ||
                                        multiFieldsRepeat.fType == 'CORE' &&
                                        <>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <button className='btn btn-link col-4' onClick={e => repeatFields(e, 'split', multiFieldsRepeat, changeCustomFields)}>

                                                    Add {multiFieldsRepeat.mapName}</button>
                                            </div>
                                            <CoreCustom setIsLoading={setIsLoading} isLoading={isLoading} CF={multiFieldsRepeat} changeCustomFields={changeCustomFields} mapName={mapName + '_' + multiFieldsRepeat.fieldName} friendlyName={CF.friendlyName}
                                                filledCustomFields={multiFieldsRepeat.filledData && multiFieldsRepeat.filledData.length > 0 && multiFieldsRepeat.filledData}
                                                keyName={multiFieldsRepeat.keyName}
                                            />
                                            <hr />
                                        </>

                                    ))

                                )
                            }
                        </div>
                    </div>

                    {/* <p>End Custom Muti Fields Repeat config---------------------</p><br /><br /><br /> */}
                </div>
            </div>
        </>
    )
}
export default MultiCustom;