import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';

const EnumCustom = ({props}) =>{
   
    return (
        <>
          <div className="row my-2">
          <div className="col-4 d-flex align-items-center justify-content-end">
          <label>
                {props.friendlyName}
            </label>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
            <select className="form-control form-control-lg" id="exampleFormControlSelect2" defaultValue={'DEFAULT'} >
                <option value="DEFAULT" disabled>Choose a EnumCustom ...</option>
               
            </select>
            </div>
            </div>
        </>
    )
}
export default EnumCustom;