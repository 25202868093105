import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';

const EnumCustom = ({CF, changeCustomFields, mapName, innerKeyName,filledCustomFields}) =>{
    let init = (CF[1]).indexOf('(');
    let fin = (CF[1]).indexOf(')');
    let mainData = (CF[1]).substr(init+1,fin-init-1).split(",");
    //console.log("Enum Data", filledCustomFields)
    const[dropdown, setDropdown] = useState(mainData)

    const dropdownChangeHandler = (e) =>{
        const {name, value} = e.target
        changeCustomFields({target:{name:name, value:value}}, CF[0], innerKeyName)
    }
    return (
        <>
          <div className="row">
          <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
            <label>
                {CF[0]}
            </label>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
            <select className="form-control form-control-lg" id="exampleFormControlSelect2" defaultValue={'DEFAULT'} name={CF[0]} 
            value={filledCustomFields && filledCustomFields[1]} onChange={dropdownChangeHandler}>
                <option value="DEFAULT" disabled>Choose a {CF[0]} ...</option>
               {
                dropdown.map(dd =>(
                    <option name={dd} value={dd}>{dd}</option>
                ))
                }
            </select>
            </div>
            </div>
        </>
    )
}
export default EnumCustom;