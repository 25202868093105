import React, { useState, useEffect } from 'react';
import { NavLink, Route } from 'react-router-dom';

import { Role } from '../_helpers';
import { accountService } from '../_services';

const Sidebar = ({home, user}) => {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
      };
   

    return (
        <div>
            

                <div id="wrapper" className={isActive ? 'wrapper-content toggled': 'wrapper-content'} >
                <div id="sidebar-wrapper">
                    <ul className="sidebar-nav">
                        <li className="sidebar-brand">
                            <a href="#">
                               WIS
                            </a>
                        </li>
                        <li>
                            <NavLink to="/downloads" className="nav-item nav-link">Download Links</NavLink>
                        </li>
                        <li>
                            <NavLink to="/profile" className="nav-item nav-link">Edit My Profile</NavLink>
                        </li>
                        <li>
                            <NavLink to="/avatar" className="nav-item nav-link">Edit My Avatar</NavLink>
                        </li>
                        
                        <li>
                            <NavLink to="/group" className="nav-item nav-link">Request Group Access</NavLink>
                        </li>
                        <li>
                            <NavLink to="/search-users" className="nav-item nav-link">Search Users</NavLink>
                        </li>
                        <li>
                            <NavLink to="/approve-user" className="nav-item nav-link">Approve User for Group</NavLink>
                        </li>
                        <li>
                            <NavLink to="/pre-register" className="nav-item nav-link">Pre Register User</NavLink>
                        </li>
                        <li>
                            <NavLink to="/manage-floors" className="nav-item nav-link">Manage Floors</NavLink>
                        </li>
                        <li>
                            <NavLink to="/user-groups" className="nav-item nav-link">User Groups</NavLink>
                        </li>
                        <li>
                            <NavLink to="/maps" className="nav-item nav-link">Maps</NavLink>
                        </li>
                        <li>
                            <NavLink to="/custom-fields" className="nav-item nav-link">Custom fields</NavLink>
                        </li>
                        <li>
                            <NavLink to="/versions" className="nav-item nav-link">Versions</NavLink>
                        </li>

                    </ul>
                </div>

                <div id="page-content-wrapper">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <button className="btn-menu btn btn-success btn-toggle-menu" type="button" onClick={()=>{toggleClass()}}>
                                    <i className="fa fa-bars"></i>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse">
                                <ul className="nav navbar-nav navbar-right">
                                    <li>
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            <i className="ti-panel"></i>
                                            <p>Stats</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="ti-settings"></i>
                                            <p>Settings</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {home}
                </div>
            </div>
        </div>
    )
}

export  { Sidebar }