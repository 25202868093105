export default function replaceNthOccurrence(inputString, search, replacement, n) {
    let index = -1;
    for (let i = 0; i < n; i++) {
        index = inputString.indexOf(search, index + 1);
        if (index === -1) {
            // If the substring doesn't occur n times, exit.
            return inputString;
        }
    }

    // Create the new string by slicing and concatenating.
    return inputString.slice(0, index) + replacement + inputString.slice(index + search.length);
}

