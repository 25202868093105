import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DateTimeCustom = ({CF, changeCustomFields,innerKeyName, mapName, filledCustomFields}) =>{
    const [initialDate, setInitialDate] = useState(new Date())
    useEffect(()=>{
        if(filledCustomFields && filledCustomFields[1]){
            setInitialDate(new Date(filledCustomFields[1]))
        }
    },[filledCustomFields && filledCustomFields[1]])
    const ChangeDate = (date) =>{
        setInitialDate(date)
        changeCustomFields({target:{name:CF[0], value:moment(date).format('yyyy-MM-DD')}}, CF[0],mapName)
    }
    return (
        <>
        
        <div className="row">
        <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
            <label>
                {CF[0]}
            </label>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center datepicker">
            <DatePicker selected={initialDate}  className={'form-control w-100'} showTimeSelect
  dateFormat="Pp"

      format="yyyy-MM-dd HH:m:s"onChange={(date) => ChangeDate(date)}/>
       </div>
            </div>
        </>
    )
}
export default DateTimeCustom;