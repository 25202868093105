import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {Bar, Doughnut} from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { accountService } from '../_services';

// import "react-datepicker/dist/react-datepicker.css";
const DashboardPage = () => {
    const user = accountService.userValue;
    //console.log("user", user)
    
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            <span className="text-white mr-2">
            </span> Download the software </h3>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
               
              </li>
            </ul>
          </nav>
        </div>
        
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card card-round">
              <div className="card-body">

                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-sm-2 col-12 dashimage">
                    <a href='https://play.google.com/store/apps/details?id=com.Hereafter.ToolboxVR' target='_blank' title='Download Android version of ToolBox'>
                      <img src={'https://wisprofileimages.s3.ap-south-1.amazonaws.com/assets/big-icon-android.png'} width="200"/>
                    </a>
                  </div>
                  <div className='col-sm-2 col-12 dashimage'>
                    <a href='https://apps.apple.com/no/app/wis-vr-toolbox-3-0/id1553049152' target='_blank' title='Download iOS version of ToolBox'>
                      <img src={'https://wisprofileimages.s3.ap-south-1.amazonaws.com/assets/big-icon-ios.png'} width="200" />
                    </a>
                  </div>
                  <div className="col-sm-2 col-12 dashimage">
                    <a href='https://hereaftertoolboxvr.s3.eu-central-1.amazonaws.com/install/ToolboxVR_Mac.dmg' target='_blank' title='Download Mac version of ToolBox'>
                      <img src={'https://wisprofileimages.s3.ap-south-1.amazonaws.com/assets/big-icon-apple.png'}  width="200"/>
                    </a>
                  </div>
                  <div className="col-sm-2 col-12 dashimage">
                    <a href='https://hereaftertoolboxvr.s3.eu-central-1.amazonaws.com/install/ToolboxVR.exe' target='_blank' title='Download Windows version of ToolBox'>
                      <img src={'https://wisprofileimages.s3.ap-south-1.amazonaws.com/assets/big-icon-windows.png'}  width="200"/>
                    </a>
                  </div>
                  <div className="col-sm-2 col-12 dashimage">
                    <a href='https://hereaftertoolboxvr.s3.eu-central-1.amazonaws.com/install/ToolboxVROculusInstaller.exe' target='_blank' title='Download Quest version of ToolBox'>
                      <img src={'https://wisprofileimages.s3.ap-south-1.amazonaws.com/assets/big-icon-oculus.png'} width="200" />
                    </a>
                  </div>
                </div>
             
              </div>
            </div>
            
          </div>
          
          {/* <div className="col-md-5 grid-margin stretch-card">
            <div className="card">
            <div className="card-body">
                card 2
              </div>
            </div>
          </div> */}
        </div>
        <div className="row p-5 dashboard-buttons">
        {user && (user.Item.Abilities.isGlobalAdmin == 'true' || (user.Item.Abilities && (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0))) &&
          <div className="col-md-3 d-flex justify-content-center">
          <Link to='/users' title='Click to Manage Users'>
            <div class="card text-white mb-3 dash-button dash-button-users" >
              <div class="card-body">
                {/* <p class="card-text text-center">All Users</p> */}
              </div>
            </div>
            </Link>
          </div>
          }
           {user && ((user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true') || (user.Item.Abilities && (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)))  &&
          <div className="col-md-3 d-flex justify-content-center">
          <Link to='/avatar' title='Click to Manage Avatar'>
            <div class="card text-white mb-3 dash-button dash-button-avatar" >
              <div class="card-body">
                {/* <p class="card-text text-center">All Worlds</p> */}
              </div>
            </div>
            </Link>
          </div>
          }
          
          <div className="col-md-3 d-flex justify-content-center">
          <Link to='/worlds' title='Click to Manage Worlds'>
            <div class="card text-white mb-3 dash-button  dash-button-worlds" >
              <div class="card-body">
                {/* <p class="card-text text-center">Avatar Manager</p> */}
              </div>
            </div>
            </Link>
          </div>
          
          {user && (user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true' || (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) &&
          <div className="col-md-3 d-flex justify-content-center">
          <Link to='/group-access' title='Click to Manage Groups'>
            <div class="card text-white mb-3 dash-button dash-button-groups">
              <div class="card-body">
                {/* <p class="card-text text-center">List of user group requests / Request user group membership</p> */}
              </div>
            </div>
            </Link>
          </div>
          }
        </div>
        {/* <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
            <div className="card-body">
                card 3
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 grid-margin stretch-card">
            <div className="card">
              <div className="card-body p-0 d-flex">
                <div className="dashboard-custom-date-picker">
               
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 grid-margin stretch-card">
            <div className="card">
            <div className="card-body">
                card 1
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 grid-margin stretch-card">
            <div className="card">
            <div className="card-body">
                card 1
              </div>
            </div>
          </div>
          <div className="col-xl-5 grid-margin stretch-card">
            <div className="card">
            <div className="card-body">
                card 1
              </div>
            </div>
          </div>
        </div> */}
      </div> 
    );
}

export default DashboardPage;