
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Role } from '../_helpers';

import { accountService, alertService } from '../_services';
import { Alert } from '../_components';
// import "react-datepicker/dist/react-datepicker.css";



const Profile = ({ history }) => {
  const user = accountService.userValue;
  const [profileChooseImage, setProfileChooseUrl] = useState(true)

  const [groups, setGroups] = useState({})

  const [owningGroupName, setOwningGroupName] = useState('');

  const [allGroupNames, setAllGroupNames] = useState({ name: [] });

  const [adminGroups, setAdminGroups] = useState({ name: [] })

  console.log("user in profile", user)

  const [fieldsForUpdatingUser, setFieldsForUpdatingUser] = useState({})

  useEffect(() => {
    if (Object.keys(fieldsForUpdatingUser).length > 0) {
      accountService.getGroupsForSearchEquals(fieldsForUpdatingUser.OwningGroup).then(x => {
        setOwningGroupName(x.Items[0].ClientName)
      })
      const allGroups = []
      user.Item.Groups && user.Item.Groups.map(group =>
        accountService.getGroupsForSearchEquals(group).then(groupName => {
          allGroups.push(groupName.Items[0].ClientName)
          setAllGroupNames({ name: allGroups })
        })
      )

    }

  }, [Object.keys(fieldsForUpdatingUser).length > 0])


  useEffect(() => {
    accountService.getById(user.Item.username).then(x => {
      console.log("prof details", x)
      setFieldsForUpdatingUser(x)
      setProfileChooseUrl(x.FaceImageURL === "" ? true : false)
    });

    if (user.Item.Role != Role.Admin) {
      accountService.getGroups().then(x => {
        console.log("x", x)
        setGroups(x)
      });
    }
  }, [])

  const initialValues = {
    username: user.Item.username,
    U_FirstName: fieldsForUpdatingUser.FirstName,
    U_LastName: fieldsForUpdatingUser.LastName,
    U_DisplayName: fieldsForUpdatingUser.DisplayName,
    U_Gender: fieldsForUpdatingUser.Gender,
    U_FaceImageURL: fieldsForUpdatingUser.FaceImageURL,
    U_CompanyImageURL: fieldsForUpdatingUser.CompanyImageURL,
    U_Company: fieldsForUpdatingUser.U_Company,
    U_StatusLine: fieldsForUpdatingUser.StatusLine,
    U_Locale: fieldsForUpdatingUser.Locale
  }
  const changeHandler = (e) => {
    const { name, value } = e.target;

    if (value == null && name === "FaceImageURL") {
      setProfileChooseUrl(value === undefined ? true : false)
      setFieldsForUpdatingUser(prevState => ({
        ...prevState,
        [name]: value || ''
      }))
    }
    setFieldsForUpdatingUser(prevState => ({
      ...prevState,
      [name]: value || ''
    }))

  }
  const validationSchema = Yup.object().shape({
    U_FirstName: Yup.string()
      .required('First Name is required'),
    U_LastName: Yup.string()
      .required('Last Name is required'),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();

    if (fieldsForUpdatingUser.FaceImageURL == null) {
      fieldsForUpdatingUser.FaceImageURL = ''
    }
    if (fieldsForUpdatingUser.CompanyImageURL == null) {
      fieldsForUpdatingUser.CompanyImageURL = ''
    }
    accountService
      .update(user.Item.username, fieldsForUpdatingUser)
      .then(() => {
        alertService.success("Profile Updated successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false);
        history.push("/profile");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  const [isDeleting, setIsDeleting] = useState(false);
  function onDelete() {
    if (window.confirm("Are you sure?")) {
      setIsDeleting(true);
      accountService
        .delete(user.id)
        .then(() => alertService.success("Account deleted successfully"));
    }
  }
  const fileUpload = (event, type) => {

    const files = event.target.files[0];
    let myFiles = Array.from(files);
    if (type == 'company') {
      accountService
        .uploadFile(files)
        .then((data) => {
          setFieldsForUpdatingUser(prevState => ({
            ...prevState,
            CompanyImageURL: data.url
          }))
        });
    }
    if (type == 'face') {
      accountService
        .uploadFile(files)
        .then((data) => {
          setFieldsForUpdatingUser(prevState => ({
            ...prevState,
            FaceImageURL: data.url
          }))
          setProfileChooseUrl(data.url ? false : true)
        });
    }


  }
  if (Object.keys(fieldsForUpdatingUser).length == 0) return null;


  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span className="text-white mr-2">
          </span> Edit Profile </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">

            </li>
          </ul>
        </nav>
      </div>

      <div className="row">

        <div className="col-md-7 grid-margin stretch-card">

          <div className="card">

            <div className="card-body">
              <Alert />
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="form-row">
                      <div className="form-group col-sm-4 col-12">
                        <label>User Name</label>
                        <Field
                          name="username"
                          type="text"
                          disabled
                          className={
                            "form-control" +
                            (errors.userName && touched.userName ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-sm-4 col-12">
                        <label>First Name</label>
                        <Field
                          name="FirstName"
                          type="text"
                          onChange={changeHandler}
                          value={fieldsForUpdatingUser.FirstName}
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="FirstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group col-sm-4 col-12">
                        <label>Last Name</label>
                        <Field
                          name="LastName"
                          type="text"
                          onChange={changeHandler}
                          value={fieldsForUpdatingUser.LastName}
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="LastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group">
                        <label>Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div> */}
                    <div className="form-row">
                      <div className="form-group col-sm-4 col-12">
                        <label>Status Line</label>
                        <Field name="StatusLine" type="text" className={'form-control'} onChange={changeHandler} value={fieldsForUpdatingUser.StatusLine} />
                        <ErrorMessage name="StatusLine" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group col-sm-4 col-12">
                        <label>Display Name</label>
                        <Field name="DisplayName" type="text" className={'form-control'} onChange={changeHandler} value={fieldsForUpdatingUser.DisplayName} />
                        <ErrorMessage name="DisplayName" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group col-sm-4 col-12">
                        <label>Gender</label>
                        <Field name="Gender" as="select" className={'form-control'} onChange={changeHandler} value={fieldsForUpdatingUser.Gender}>
                          <option value=""></option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Field>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group  col-sm-7 col-12">
                        <label>Profile Picture</label>
                        {profileChooseImage && <Field name="FaceImageURL" value={undefined} className={'form-control'}
                          onChange={e => fileUpload(e, 'face')}
                          type="file" accept="image/*"
                          single="true"
                        />}
                        {fieldsForUpdatingUser.FaceImageURL && <div className="image-area">
                          <img src={fieldsForUpdatingUser.FaceImageURL} width="100" height="100" />
                          <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); changeHandler({ target: { name: 'FaceImageURL', value: undefined } }) }}>&#215;</a>
                        </div>}
                      </div>

                    </div>
                    <div className="form-row">
                      <div className="form-group  col-sm-4 col-12">
                        <label>Company</label>
                        <Field name="Company" value={fieldsForUpdatingUser.Company} type="text" className={'form-control'} onChange={changeHandler} />
                      </div>
                      <div className="form-group  col-sm-4 col-12">
                        <label>Locale</label>
                        <Field name="Locale" value={fieldsForUpdatingUser.Locale} type="text" className={'form-control'} onChange={changeHandler} />
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Submit
                      </button>
                      {/* <button type="button" onClick={() => onDelete()} className="btn btn-danger" style={{ width: '75px' }} disabled={isDeleting}>
                            {isDeleting
                                ? <span className="spinner-border spinner-border-sm"></span>
                                : <span>Delete</span>
                            }
                        </button> */}
                      <Link to="." className="btn text-avatar">Cancel</Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

          </div>
        </div>
        {user.Item.Role != Role.Admin && Object.keys(groups).length > 0 &&

          <div className="col-md-5 grid-margin stretch-card">
            <div className="card">

              <div className="card-body">
                <h4><b>Groups</b></h4>
                <p>
                  <u>Main Group</u>
                </p>
                <ul>
                  <li key={owningGroupName}>
                    {user.Item.OwningGroup && owningGroupName}
                  </li>
                </ul>
                <p>
                  <p>
                    <u>Other Groups</u>
                  </p>
                  {allGroupNames.name.length > 0 && <ul>
                    {allGroupNames.name.map(group => (
                      <li key={group}>
                        {group}
                      </li>
                    ))}
                  </ul> || 'No Other Groups found'}
                </p>
                {user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0 &&
                  <p>
                    <u>You are an admin to</u>
                    <ul>
                      {user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.map((admin) => (
                        <li key={admin}>
                          {admin}
                        </li>
                      ))}
                    </ul>
                  </p>
                }
                {/* {groups.Items.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Group Name</th>
                                    <th scope="col">status</th>
                                    <th scope="col">User Name</th>
                                    <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                {
                                    
                                    groups.Items.map(group=>(
                                        <>
                                            <tbody key={group.group}>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>{group.group}</td>
                                                    <td>{group.status}</td>
                                                    <td>{group.user}</td>
                                                    <td>@mdo</td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ))
                                }
                            </table>
                        } */}
                <br />

              </div>
            </div>
          </div>
        }
      </div>

    </div>
  );
}

export default Profile;