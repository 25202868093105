import React, { memo, useEffect, useState } from 'react'
import Core from './Core'
import Multi from './Multi'
import Split from './Split'

const CustomFields = memo(({allCustomFields, setAllCustomFields}) => {
    const [splitRepeatFields, setSplitRepeatFields] = useState({})
    const [multiRepeatFields, setMultiRepeatFields] = useState({})
    
    return (
        <div className='custom-fields'>
            {
                //Object.entries(allCustomFields).map(([customFieldKey,customFieldItem]) => {
                    Object.values(allCustomFields).map((customFieldItem, customFieldKey) => {
                    if(customFieldItem.fType === "SPLIT") {
                        return (
                            <div key={customFieldKey}>
                                <label><h2><u>{customFieldItem.friendlyName}</u></h2></label>
                                <Split 
                                    splitFields={customFieldItem} 
                                    splitFieldsKey={customFieldItem} 
                                    splitRepeat={customFieldItem.splitRepeat} 
                                    splitRepeatFields={splitRepeatFields} 
                                    setSplitRepeatFields={setSplitRepeatFields} 
                                    allCustomFields={allCustomFields}
                                    setAllCustomFields={setAllCustomFields}/>
                            </div>
                        )
                    }
                    if(customFieldItem.fType === "MULTI") {
                        return (
                            <div key={customFieldKey}>
                                <label><h2><u>{customFieldItem.friendlyName}</u></h2></label>
                                <Multi multiFields={customFieldItem.multiFields} multiRepeat={customFieldItem.multiRepeat} multiRepeatFields={multiRepeatFields} setMultiRepeatFields={setMultiRepeatFields}/>
                            </div>
                        )
                    }
                    if(customFieldItem.fType === "CORE") {
                        return (
                            <div key={customFieldKey}>
                                <label><h2><u>{customFieldItem.friendlyName}</u></h2></label>
                                <Core coreFields={customFieldItem}/>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
})

export default CustomFields