import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';


const ListUsers = ({ match }) => {
  const { path } = match;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  // const [isInitialLoading, setIsInitialLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    // setIsInitialLoading(true)
    accountService.getAll().then(x => {
      setUsers(x.Items.sort((a, b) => a.FirstName.localeCompare(b.FirstName)))
      setIsLoading(false)
      // setIsInitialLoading(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length === 0 && !searchTerm.trim()]);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true)
      if (!searchTerm.trim()) return
      accountService.searchUsers(searchTerm).then(x => {
        setUsers(x.Items.sort((a, b) => a.FirstName.localeCompare(b.FirstName)))
        setIsLoading(false)
      })
      // Send Axios request here
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  // const handleSearch = (e) => {
  //   setIsLoading(true)
  //   const query = e.target.value
  //     accountService.searchUsers(query).then(x=>{
  //       setUsers(x.Items)
  //       setIsLoading(false)
  //     })
  // };

  function deleteUser(id) {
    setUsers(users.map(x => {
      if (x.id === id) { x.isDeleting = true; }
      return x;
    }));
    accountService.delete(id).then(() => {
      setUsers(users => users.filter(x => x.id !== id));
    });
  }
  return (
    <div>

      {/* <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <div className='row'>
                <div className="col-lg-6"> <h4 className="card-title">Users</h4></div>
                <div className="col-lg-6 text-right">
                  <input type="text" className="form-control bg-transparent border-0" placeholder="Search Users" onChange={e => handleSearch(e)}/>
                </div>
              </div>
               
               
                <div className="table-responsive">
                  
                  <table className="table table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: '30%' }}>User Name</th>
                            <th style={{ width: '30%' }}>Name</th>
                            <th style={{ width: '30%' }}>Email</th>
                            <th style={{ width: '30%' }}>Role</th>
                            <th style={{ width: '30%' }}>Display Name</th>
                            <th style={{ width: '30%' }}>Gender</th>
                            <th style={{ maxWidth: '30%' }}>Profile Pic Url</th>
                            <th style={{ maxWidth: '100px' }}>Company Logo Url</th>
                            <th style={{ width: '30%' }}>Phone Number</th>
                            <th style={{ width: '30%' }}>Company</th>
                            <th style={{ width: '30%' }}>Locale</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                      {isLoading && 
                         <tr> 
                            <td colSpan={12}>
                              <LoadingIndicator />
                            </td>
                         </tr>
                        }
                        
                        {users && !isLoading && users.map((user, key) =>
                            <tr key={key}>
                                <td>{user.username}</td>
                                <td>{user.FirstName} {user.LastName}</td>
                                <td>{user.Email}</td>
                                <td>{user.Role}</td>
                                <td>{user.DisplayName}</td>
                                <td>{user.Gender}</td>
                                <td>{user.profilePicUrl}</td>
                                <td>{user.CompanyImageURL}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.Company}</td>
                                <td>{user.Locale}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <Link to={`/edit/${user.username}`} className="btn btn-sm btn-primary mr-1">Edit</Link>
                                    <button onClick={() => deleteUser(user.username)} className="btn btn-sm btn-danger" style={{ width: '60px' }} disabled={user.isDeleting}>
                                        {user.isDeleting 
                                            ? <span className="spinner-border spinner-border-sm"></span>
                                            : <span>Delete</span>
                                        }
                                    </button>
                                </td>
                            </tr>
                        )}
                        {!users &&
                            <tr>
                                <td colSpan="4" className="text-center">
                                    <span className="spinner-border spinner-border-lg align-center"></span>
                                </td>
                            </tr>
                        }
                    </tbody>
            </table>
            </div>
        </div>
        </div>
      </div>  */}
      <div className="row">
        <div className='col-12 mb-5'>
          {users && !isLoading && <div className='row'>
            <div className="col-lg-6">
              <h3 className="page-title">
                <span className="text-white mr-2">
                  {/* <i className="mdi mdi-account-multiple"></i> */}
                </span> Users </h3>

            </div>
            <div className="col-lg-6 text-right">
              <input type="text" className="form-control border-0" value={searchTerm} style={{ "boxShadow": '#63636333 0px 2px 8px 0px' }} disabled={isLoading} placeholder={isLoading ? "Loading... Please wait." : "Search Users"} onChange={(e) => setSearchTerm(e.target.value || '')} />
              <p className="col-lg-12 text-left mb-4">Please enter at least 3 characters to search</p>
            </div>
          </div>}
        </div>

        {isLoading &&
          <div className='col-12 text-center'>
            <LoadingIndicator />
          </div>
        }
      </div>
      <div className='row'>
        {users && !isLoading && users.map((user, key) =>

          <div className="col-md-4 grid-margin stretch-card" key={key}>
            <div className="card">
              <div className="card-body">
                <div className="clearfix mb-4">
                  <h5 className="float-left"> {user.username}</h5>
                </div>
                <div className='card-description'>
                  <div className='row'>
                    <div className='col-12'>

                      <img src={user.FaceImageURL && user.FaceImageURL || "http://hereafter.in/surrogate/Server/imageData/persons/NoContact.jpg"} width="100" />
                      <br />
                      <br />
                      <ul>
                        <li><b>Title:</b> {user.FirstName} {user.LastName}</li>
                        <li><b>Username:</b> {user.username}</li>
                        <li><b>Status:</b> {user.Status}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Link to={`/edit/${user.username}`} className="btn btn-sm btn-primary btn-primary-new mr-1">Edit</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListUsers;