import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DateTimeCustom = ({ props }) => {
    const [initialDate, setInitialDate] = useState(new Date())
    const onChangeDate = (date) => {
        setInitialDate(date)
        props.value = date
    }
    return (
        <>

            <div className="row my-2">
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <label>
                        {props.friendlyName}
                    </label>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center datepicker">
                    <DatePicker className={'form-control w-100'}
                        selected={initialDate}
                        showTimeSelect
                        dateFormat="Pp"
                        onChange={(date) => onChangeDate(date)}
                        format="yyyy-MM-dd HH:m:s" />
                </div>
            </div>
        </>
    )
}
export default DateTimeCustom;