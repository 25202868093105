import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';

const WorldIdCustom = ({props}) =>{
    const [worlds, setWorlds] = useState([]);

    useEffect(() => {
        accountService.getAllWorlds().then(x => {
          setWorlds(x.Items)
        });
    }, [worlds.length == 0]);

    const dropdownChangeHandler = (e) =>{
        props.value = e.target.value
        setWorlds(e.target.value)
    }

    return (
        <>  
        <div className="row my-2">
        <div className="col-4 d-flex align-items-center justify-content-end">
            <label>
                {props.friendlyName}
            </label>
        </div>
        
        <div className="col-6">
       
        {worlds.length >0 && 
             <select className="form-control form-control-lg" id="worldId" name={props.fieldName} value={props.value || ''} defaultValue={'DEFAULT'} onChange={dropdownChangeHandler}>
                <option value="DEFAULT" disabled>Choose a World ...</option>
               {
                    worlds.map(dd =>(
                        <option name={dd.WorldID} value={dd.WorldID} key={dd.WorldID}>{dd.WorldFriendlyName}</option>
                    ))
                }
            </select>
        }
        </div>
        </div>
       
        </>
    )
}
export default WorldIdCustom;