import { ProgressBar } from 'react-bootstrap';
import {Bar, Doughnut} from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; 


const RequestAccess = ({ history }) => {
    const user = accountService.userValue;

    //console.log("user in profile",user)
    const [singleSelections, setSingleSelections] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);


    const handleSearch = (query) => {
        setIsLoading(true);
        accountService.getGroupsForSearch(query).then(x=>{
            setOptions(x.Items);
            setIsLoading(false);
        })
      
       
      };
    
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
    });

		function onSubmit(fields, { setStatus, setSubmitting }) {
            //console.log("singleSelections",singleSelections[0])
            accountService.requestGroupAccess(singleSelections[0].name).then(() => {
                alertService.success("Update successful", {
                    			keepAfterRouteChange: true,
                    		});
                history.push(".");
            }).catch((error) => {
                		setSubmitting(false);
                		alertService.error(error);
                	});
			
		}

		const [isDeleting, setIsDeleting] = useState(false);
		function onDelete() {
			if (window.confirm("Are you sure?")) {
				setIsDeleting(true);
				accountService
					.delete(user.id)
					.then(() => alertService.success("Account deleted successfully"));
			}
		}
        const filterBy = () => true;
    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <i className="mdi mdi-google-circles-group"></i>
            </span> Request Group Access </h3>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                   
                  </li>
                </ul>
              </nav>
            </div>
            
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
            <Formik initialValues={options} onSubmit={onSubmit}>
				{({ errors, touched, isSubmitting }) => (
					<Form>
						<h1>Request Group Membership</h1>
						<div className="form-row">
							<div className="form-group col">
								<label></label>
								<AsyncTypeahead
                                    filterBy={filterBy}
                                    id="async-example"
                                    isLoading={isLoading}
                                    labelKey="name"
                                    minLength={3}
                                    onSearch={handleSearch}
                                    selected={singleSelections}
                                    onChange={setSingleSelections}
                                    options={options}
                                    placeholder="Search for a Group..."
                                    renderMenuItemChildren={(option, props) => (
                                        <Fragment>
                                        <img
                                            alt={option.name}
                                            src={option.LogoURL}
                                            style={{
                                            height: '24px',
                                            marginRight: '10px',
                                            width: '24px',
                                            }}
                                        />
                                        <span>{option.ClientName}</span>
                                        </Fragment>
                                    )}
                                    />
								<ErrorMessage
									name="username"
									component="div"
									className="invalid-feedback"
								/>
							</div>
							
						
						</div>
						{/* <div className="form-group">
                        <label>Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div> */}
                   
                      
                       
                   
                    <div className="form-group">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Request Access
                        </button>
                        {/* <button type="button" onClick={() => onDelete()} className="btn btn-danger" style={{ width: '75px' }} disabled={isDeleting}>
                            {isDeleting
                                ? <span className="spinner-border spinner-border-sm"></span>
                                : <span>Delete</span>
                            }
                        </button> */}
                        <Link to="." className="btn text-avatar">Cancel</Link>
                    </div>
                </Form>
            )}
        </Formik>   
        </div>
        </div>
        </div>
        </div>
      </div> 
    );
}

export default RequestAccess;