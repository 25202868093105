import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { accountService } from '../_services';
import { Role } from '../_helpers';

const Sidebar = ({ history, match }) => {
  const [state, setState] = useState({})
  const user = accountService.userValue;
  const { path } = match;
  console.log("path", path)

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState(prevState => ({ ...prevState, [menuState]: false }));
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach(i => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  }


  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      console.log("ssads", i)
      setState(prevState => ({ ...prevState, [i]: false }));
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/avatar', state: 'basicUiMenuOpen' },
      { path: '/profile', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/group-access', state: 'groupAccess' },
      { path: '/request-access', state: 'groupAccess' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/worlds', state: 'worldsOpen' },
      { path: '/add-world', state: 'worldsOpen' },
      { path: '/edit-world', state: 'worldsOpen' },
      { path: '/maps', state: 'mapsOpen' },
      { path: '/add-map', state: 'mapsOpen' },
      { path: '/edit-maps', state: 'mapsOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/add', state: 'usersMenuOpen' },
      { path: '/edit', state: 'usersMenuOpen' },
      { path: '/groups', state: 'groupsMenuOpen' },
      { path: '/add-group', state: 'groupsMenuOpen' },
      { path: '/edit-group', state: 'groupsMenuOpen' },

    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setState(prevState => ({ ...prevState, [obj.state]: true }))
        console.log("this.state", state)
      }
    }));

  }

  const isPathActive = (path) => {
    return window.location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    if (user) {
      setState({
        FirstName: user.Item.FirstName,
        LastName: user.Item.LastName,
        username: user.Item.username,
        FaceImageURL: user.Item.FaceImageURL,
        Admin: user.Item.Abilities && user.Item.Abilities.isGlobalAdmin || false,
        User: user.Item.Abilities && !user.Item.Abilities.isGlobalAdmin && !user.Item.Abilities.isDeveloper,
        Developer: user.Item.Abilities && user.Item.Abilities.isDeveloper || false
      })
    }
    console.log("user item", user && user.Item)
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [])


  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile no-hover">
          <span className="nav-link no-hover">
            <div className="nav-profile-image">
              <img src={user && user.Item.FaceImageURL ? user.Item.FaceImageURL : 'https://wisprofileimages.s3.ap-south-1.amazonaws.com/User_profile_2.png'} alt="profile" />
              <span className="login-status online"></span> {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2"><Trans>{user && user.Item.FirstName} {user && user.Item.LastName}</Trans></span>
              <span className="text-small purple-font"><Trans>{user && user.Item.username}</Trans></span>
            </div>
          </span>
        </li>
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/dashboard" title='Download and manage ToolBox '>
            <i className="mdi mdi-home menu-icon"></i>
            <span className="menu-title"><Trans>Home</Trans></span>
          </Link>
        </li>
        <li className={isPathActive('/profile') ? 'nav-item signout active' : 'nav-item signout'}>
          <Link className="nav-link" to="/profile">
            <i className="mdi mdi-account-edit menu-icon"></i>
            <span className="menu-title"><Trans>My Profile</Trans></span>
          </Link>
        </li>
        <li className={isPathActive('/avatar') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/avatar" title='Create or manage Avatar'>
            <i className="mdi mdi-human-handsdown menu-icon"></i>
            <span className="menu-title"><Trans>Avatar</Trans></span>
          </Link>
        </li>

        {user && (user.Item.Abilities.isDeveloper == 'false' && user.Item.Abilities.isGlobalAdmin == 'false') &&
          <li className={isPathActive('/request-access') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/request-access">
              <i className="mdi mdi-account-group menu-icon"></i>
              <span className="menu-title"><Trans>Request Group Membership</Trans></span>
            </Link>
          </li>}
        {/* <li className={ isPathActive('/avatar') ? 'nav-item active' : 'nav-item' }>
            <div className={ state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Profile</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ isPathActive('/avatar') ? 'nav-link active' : 'nav-link' } to="/avatar"><Trans>Avatar</Trans></Link></li>
                <li className="nav-item"> <Link className={ isPathActive('/profile') ? 'nav-link active' : 'nav-link' } to="/profile"><Trans>Profile</Trans></Link></li>
                {user && (user.Item.Abilities.isDeveloper == 'false' && user.Item.Abilities.isGlobalAdmin == 'false') && <li className="nav-item"> <Link className={ isPathActive('/request-access') ? 'nav-link active' : 'nav-link' } to="/request-access"><Trans>Request Access</Trans></Link></li>}
              </ul>
            </Collapse>
          </li> */}
        {user && ((user.Item.Abilities && user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) &&
          <li className={isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
            <div className={state.groupAccess ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('groupAccess')} data-toggle="collapse">
              <i className="mdi mdi-account-group menu-icon"></i>
              <span className="menu-title" title='Request access for specific groups '><Trans>Group Access</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.groupAccess}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/group-access') ? 'nav-link active' : 'nav-link'} to="/group-access" title='Request access for specific groups '><Trans>Group Access</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
        }
        {user && user.Item.Abilities.isDeveloper == 'true' && <li className={isPathActive('/maps') ? 'nav-item active' : 'nav-item'}>
          <div className={state.mapsOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('mapsOpen')} data-toggle="collapse">
            <i className="mdi mdi-google-maps menu-icon"></i>
            <span className="menu-title"><Trans>Maps</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.mapsOpen}>
            <ul className="nav flex-column sub-menu">

              <li className="nav-item"> <Link className={isPathActive('/maps') ? 'nav-link active' : 'nav-link'} to="/maps"><Trans>List Maps</Trans></Link></li>
              {user && (user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true') && <li className="nav-item"> <Link className={isPathActive('/add-map') ? 'nav-link active' : 'nav-link'} to="/add-map"><Trans>Add Maps</Trans></Link></li>}
            </ul>
          </Collapse>
        </li>}
        {user && ((user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true') || (user.Item.Abilities && (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0))) && <li className={isPathActive('/worlds') ? 'nav-item active' : 'nav-item'}>
          <div className={state.worldsOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('worldsOpen')} data-toggle="collapse">
            <i className="mdi mdi-earth menu-icon"></i>
            <span className="menu-title" title='Create or Manage Floors'><Trans>Worlds</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.worldsOpen}>
            <ul className="nav flex-column sub-menu">

              <li className="nav-item"> <Link className={isPathActive('/worlds') ? 'nav-link active' : 'nav-link'} to="/worlds" title='List all the Available worlds'><Trans>List Worlds</Trans></Link></li>
              <li className="nav-item"> <Link className={isPathActive('/add-worlds') ? 'nav-link active' : 'nav-link'} to="/add-worlds" title='Generate a New World for toolbox'><Trans>Add World</Trans></Link></li>
            </ul>
          </Collapse>
        </li>
        }
        {user && (user.Item.Abilities.isGlobalAdmin == 'true' || (user.Item.Abilities && (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0))) &&
          <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
            <div className={state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('usersMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title" title='Manage User’s Details and permissions'><Trans>Users</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.usersMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/users') ? 'nav-link active' : 'nav-link'} to="/users" title='List and manage permission of Users '><Trans>List Users</Trans></Link></li>
                {user.Item.Abilities.isGlobalAdmin == 'true' && <li className="nav-item"> <Link className={isPathActive('/add') ? 'nav-link active' : 'nav-link'} to="/add" title='Create a New User'><Trans>Add Users</Trans></Link></li>}
              </ul>
            </Collapse>
          </li>
        }
        {user && (user.Item.Abilities.isDeveloper == 'true' || user.Item.Abilities.isGlobalAdmin == 'true' || (user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0)) &&
          <li className={isPathActive('/groups') ? 'nav-item active' : 'nav-item'}>
            <div className={state.groupsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('groupsMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-home-group menu-icon"></i>
              <span className="menu-title" title='Create and manage group policies'><Trans>Groups</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={state.groupsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={isPathActive('/group-access') ? 'nav-link active' : 'nav-link'} to="/group-access" title='Request access for specific groups'><Trans>Access</Trans></Link></li>

                <li className="nav-item"> <Link className={isPathActive('/groups') ? 'nav-link active' : 'nav-link'} to="/groups" title='List all the available groups'><Trans>List Groups</Trans></Link></li>
                {user && user.Item.Abilities.isGlobalAdmin == 'true' && <li className="nav-item"> <Link className={isPathActive('/add') ? 'nav-link active' : 'nav-link'} to="/add-group" title='Create a new group'><Trans>Add Group</Trans></Link></li>}
              </ul>
            </Collapse>
          </li>
        }
        {/* <li className={ isPathActive('/request-access') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/request-access">
            <i className="mdi mdi-account-group menu-icon"></i>
              <span className="menu-title"><Trans>Request Group Membership</Trans></span>
            </Link>
          </li> */}
        <li className="nav-item signout">
          <a className="nav-link" href="!#" onClick={e => { e.preventDefault(); accountService.logout() }}>
            <i className="mdi mdi-logout menu-icon" style={{ 'color': '#9148a9' }}></i>Signout
          </a>
        </li>

      </ul>
    </nav>
  );
}

export default withRouter(Sidebar);