import { BehaviorSubject } from 'rxjs';

import { fetchWrapper, history } from '../_helpers';
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_BASE_URL;

const userSubject = new BehaviorSubject(null);
const mainBaseUrl = `${BASE_URL}`;
const baseUrl = `${BASE_URL}/accounts`;
const groupsUrl = `${BASE_URL}/groups`;
const mapsUrl = `${BASE_URL}/maps`;
const worldsUrl = `${BASE_URL}/worlds`;
const customUrl = `${BASE_URL}/fields`;
export const accountService = {
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    confirmPassword,
    validateResetToken,
    resetPassword,
    getAll,
    getById,
    getByMapName,
    getByWorldName,
    getWorldByGroupName,
    getWorldByMapName,
    searchUsers,
    searchGroups,
    getGroups,
    createGroups,
    getAllGroups,
    getGroupsData,
    getGroupsForSearch,
    confirmUser,
    approveUsersForGroups,
    rejectUsersForGroups,
    requestGroupAccess,
    getGroupsForSearchEquals,
    getAllMaps,
    getAllWorlds,
    getCustomFields,
    getAllCustomFields,
    searchMaps,
    searchWorlds,
    searchWorldsForGroupName,
    deleteWorld,
    deleteMap,
    create,
    createMaps,
    createWorlds,
    update,
    updateMaps,
    updateWorlds,
    updateGroup,
    uploadFile,
    deleteGroup,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value || JSON.parse(localStorage.getItem("user")) }
};

function login(email, password) {
    return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password })
        .then(user => {
            //console.log("user", userSubject)
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            localStorage.setItem("user", JSON.stringify(user))
            startRefreshTokenTimer();
            return user;
        });
}

function confirmUser(user) {
    return fetchWrapper.post(`${baseUrl}/confirm-user`, { "username": user });
}

function logout() {
    fetchWrapper.post(`${baseUrl}/revoke-token`)
    stopRefreshTokenTimer();
    userSubject.next(null);
    localStorage.removeItem("user")
    history.push('/user-pages/login');
    setTimeout(() => {
        window.location.reload();
    }, 100)
}


function refreshToken() {
    return fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            localStorage.setItem("user", JSON.stringify(user))
            startRefreshTokenTimer();
            return user;
        });
}

function register(params) {
    return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token) {
    return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(username) {
    return fetchWrapper.post(`${baseUrl}/${username}/forgot-password`);
}

function getPresignedUrl(data) {
    return fetchWrapper.get(`${mainBaseUrl}/files/presigned-url?filename=${data.filename}`)
}

async function convertPDFToJPG(data) {
    return await fetchWrapper.put(`${mainBaseUrl}/files/convert/pdftojpg`, data)
}

async function uploadFile(file) {
    // const formData = new FormData();

    // formData.append('file', file);   
    // return fetchWrapper.post(`${baseUrl}/files`, formData);
    const filename = file.name.replace(/[^a-zA-Z0-9äöüß.]/g, "_");
    file = new File([file], filename)
    return await getPresignedUrl({ filename: file.name }).then(async (data) => {

        await fetch(
            data.url,
            {
                method: 'PUT',
                mode: "cors",
                body: file,
            }
        )
        if (String(data.url.split('?')[0]).endsWith('pdf')) {
            const result = await convertPDFToJPG({ s3Url: data.url.split('?')[0] })
            const payload = JSON.parse(result.Payload)
            if (payload.statusCode === 200) {
                return { url: payload.body }
            }
        }
        return { url: data.url.split('?')[0] }
    })

    // const formData = new FormData();

    // formData.append('file', file);
    // return fetch(
    //     `${BASE_URL}/files`,
    //     {
    //         method: 'POST',
    //         body: formData,
    //     }
    // )
    //     .then((response) => response.json())
    //     .then((result) => {
    //         return result
    //     })
    //     .catch((error) => {
    //         console.error('Error:', error);
    //     });
}


function validateResetToken(token) {
    return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ username, oldPassword, newPassword }) {
    return fetchWrapper.post(`${baseUrl}/changePassword`, { username, oldPassword, newPassword });
}

function confirmPassword({ username, verificationCode, newPassword }) {
    return fetchWrapper.post(`${baseUrl}/${username}/confirm-password`, { username, verificationCode, newPassword });
}

function getAll() {
    return fetchWrapper.get(baseUrl);
}

function getById(username) {
    //console.log("getByIr", baseUrl)
    return fetchWrapper.get(`${baseUrl}/${username}`);
}

function getByMapName(mapname) {
    //console.log("mapsUrl", mapsUrl)
    return fetchWrapper.get(`${mapsUrl}/${mapname}`);
}

function getByWorldName(worldName) {
    //console.log("worldsUrl", worldsUrl)
    return fetchWrapper.get(`${worldsUrl}/${worldName}`);
}
function getWorldByGroupName(groupName) {
    //console.log("worldsUrl", worldsUrl)
    return fetchWrapper.get(`${worldsUrl}/group/${groupName}`);
}

function getCustomFields(field) {
    return fetchWrapper.get(`${customUrl}/${field}`);
}

function getWorldByMapName(mapName) {
    //console.log("mapName", mapName)
    return fetchWrapper.get(`${worldsUrl}/maps/${mapName}`);
}

function searchUsers(term) {
    return fetchWrapper.get(`${baseUrl}?searchTerm=${term}`);
}

function searchMaps(term) {
    return fetchWrapper.get(`${mapsUrl}?mapName=${term}`);
}

function searchGroups(term) {
    return fetchWrapper.get(`${groupsUrl}?groupName=${term}`);
}

function searchWorlds(term, lastEvaluatedKey) {
    return fetchWrapper.get(`${worldsUrl}?worldID=${term}&lastEvaluatedKey=${lastEvaluatedKey}`);
}
function searchWorldsForGroupName(term, groupName) {
    return fetchWrapper.get(`${worldsUrl}/search?worldID=${term}&groupName=${groupName}`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function createMaps(params) {
    return fetchWrapper.post(mapsUrl, params);
}

function createWorlds(params) {
    console.log('createWorlds============>', params)
    return fetchWrapper.post(`${worldsUrl}`, params);
}

function getGroupsData() {
    return fetchWrapper.get(`${groupsUrl}/editable`);
}

function createGroups(params) {
    return fetchWrapper.post(`${groupsUrl}`, { params });
}

function getGroups() {
    return fetchWrapper.get(`${groupsUrl}/requests/me`);
}

function getGroupsForSearch(groupName) {
    return fetchWrapper.get(`${groupsUrl}?groupName=${groupName}`);
}

function getGroupsForSearchEquals(groupName) {
    return fetchWrapper.get(`${groupsUrl}/requests/group?groupName=${groupName}`);
}

function getAllGroups(type) {
    return fetchWrapper.get(`${groupsUrl}/requests/all/?status=${type}`);
}


function getAllMaps(type) {
    return fetchWrapper.get(`${mapsUrl}`);
}
function getAllWorlds(lastEvaluatedKey = '') {
    return fetchWrapper.get(`${worldsUrl}?lastEvaluatedKey=${lastEvaluatedKey}`);
}

function saveMap(type) {
    return fetchWrapper.post(`${mapsUrl}`);
}

function editMap(mapId, params) {
    return fetchWrapper.put(`${mapsUrl}/${mapId}`, params)
        .then(userDetails => {
            return userDetails
        });
}

//get all custom Fields
function getAllCustomFields(mapName) {
    return fetchWrapper.get(`${mapsUrl}/${mapName}`);
}

function requestGroupAccess(group) {
    return fetchWrapper.post(`${groupsUrl}/${group}/request`);
}

function approveUsersForGroups(groupName) {
    return fetchWrapper.put(`${groupsUrl}/requests/${groupName}/approve`);
}
function rejectUsersForGroups(groupName) {
    return fetchWrapper.put(`${groupsUrl}/requests/${groupName}/reject`);
}



function update(username, params) {
    return fetchWrapper.put(`${baseUrl}/${username}`, params)
        .then(userDetails => {
            return userDetails
        });
}

function updateMaps(mapName, params) {
    return fetchWrapper.put(`${mapsUrl}/${mapName}`, params)
        .then(mapDetails => {
            return mapDetails
        });
}
function updateGroup(groupName, params) {
    return fetchWrapper.put(`${groupsUrl}/${groupName}`, params)
        .then(groupDetails => {
            return groupDetails
        });
}

function deleteGroup(groupName) {
    return fetchWrapper.post(`${groupsUrl}/delete/${groupName}`)
        .then(gd => {
            return gd
        });
}

function deleteWorld(world) {
    return fetchWrapper.post(`${worldsUrl}/delete/${world}`)
        .then(gd => {
            return gd
        });
}

function deleteMap(map) {
    return fetchWrapper.post(`${mapsUrl}/delete/${map}`)
        .then(gd => {
            return gd
        });
}
function updateWorlds(worldName, params) {
    return fetchWrapper.put(`${worldsUrl}/${worldName}`, params)
        .then(worldDetails => {
            return worldDetails
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            if (id === userSubject.value.id) {
                logout();
            }
            return x;
        });
}

// helper functions

let refreshTokenTimeout;



function startRefreshTokenTimer() {
    try {

        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(userSubject.value.jwtToken?.split('.')[1]));
        ////console.log("Jwt", jwtToken)
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        document.cookie = `jwtToken=${userSubject.value.jwtToken}; expires=${expires}; path=/`;
        refreshTokenTimeout = setTimeout(refreshToken, timeout);

    } catch (error) {

    }
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
