import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';
import LoadingIndicator from '../../../_components/LoadingIndicator';
const FileLinkCustom = ({ isLoading, setIsLoading, CF, changeCustomFields, innerKeyName, mapName, filledCustomFields }) => {
    // const uploadedImage = {}
    const [isFileLoading, setIsFileLoading] = useState(false)
    const [localFileUrl, setLocalFileUrl] = useState('')
    const fileUpload = (event, type) => {
        // setIsLoading(true)
        setIsFileLoading(true)
        const files = event.target.files[0];
        console.log("FileLinkCustom fileUpload ====>", files)
        accountService
            .uploadFile(files)
            .then((data) => {
                changeCustomFields({ target: { name: CF[0], value: data.url } }, CF[0], mapName)
                console.log("FileLinkCustom fileUpload data  ====>", data)
                setLocalFileUrl(data?.url)
                // setIsLoading(false)
                setIsFileLoading(false)
            }).catch((error) => {
                console.log("FileLinkCustom fileUpload error ====>", error)
            })

    }
    return (
        <>
            <div className="row">
                <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {CF[0]}
                    </label>
                    {/* <Field name={customName} type="file" value={undefined}  className={'form-control'} onChange={e => fileUpload(e, customName)} single="true"/> */}
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                    {/* Displaying filledCustomFields value for {CF[0]} : {JSON.stringify(filledCustomFields)} */}
                    <Field name={CF[0]} onChange={e => changeCustomFields({ target: { name: CF[0], value: e.target.value } }, CF[0], mapName)} type="text" className={'form-control'} value={filledCustomFields && filledCustomFields[1] || localFileUrl && localFileUrl || ''} />
                </div>
                <div className="offset-4 col-4 d-flex align-items-center justify-content-center">

                    {!isFileLoading && <Field name={CF[0]} type="file" value={undefined} className={'form-control'} onChange={e => fileUpload(e)} single="true" />}
                </div>
                {isFileLoading && <div className="offset-4 col-4 d-flex align-items-center justify-content-center">

                    <LoadingIndicator />


                </div>}
                <div className="col-2 d-flex align-items-center justify-content-center">
                    &nbsp;
                </div>
            </div>
        </>
    )
}
export default FileLinkCustom;