import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import EmailWildCardsArray from './emailWildCards';
import PredownloadChunksArray from './preDownloadChunks';
import InputColor from 'react-input-color';
import { Alert } from '../_components';

const AddGroup = ({ history, match }) => {
    const { id } = match.params;
    const user = accountService.userValue;

    //console.log("AddGroup yo", id)
    const isAddMode = !id;
    const [emailWildCards, setEmailWildCards] = useState([])
    const [predownloadChunks, setPreDownloadChunks] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [worldsAssociated, setWorldsAssociated] = useState([])
    const initialValues = {
        name: '',
        BannerURL: '',
        BGColor: '',
        ClientName: '',
        ContactEmailFormat: '',
        ContactEmailSubject: '',
        ContactSenderEmail: '',
        ContactSenderName: '',
        EmailWildcards: [],
        GroupURL: '',
        GroupVerseWorldID: '',
        LogoSquareURL: '',
        LogoURL: '',
        PredownloadChunks: [],
        TickerInfo: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        // BannerURL: Yup.string()
        //     .required('Banner URL is required'),
        // BGColor: Yup.string()
        //     .required('BG Color is required'),
        // ClientName: Yup.string()
        //     .required('Client Name is required'),
        // ContactEmailFormat: Yup.string()
        //     .required('Contact Email Format is required'),
        // ContactEmailSubject:Yup.string()
        //     .required('Contact Email Subject is required'),
        // ContactSenderEmail:Yup.string()
        //     .required('Contact Sender Email is required'),
        // ContactSenderName:Yup.string()
        //     .required('Contact Sender Name is required'),
        // GroupURL:Yup.string()
        //     .required('Group URL is required'),
        // GroupVerseWorldID:Yup.string()
        //     .required('Group Verse WorldID is required'),
        // LogoSquareURL:Yup.string()
        //     .required('Logo Square URL is required'),
        // LogoURL:Yup.string()
        //     .required('Logo URL is required'),
        // TickerInfo:Yup.string()
        //     .required('Ticker Info is required'),
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createGroup(fields, setSubmitting);
        } else {
            updateGroup(id, fields, setSubmitting);
        }
    }

    const fileUpload = async (event, type) => {
        setIsLoading(true)
        const files = event.target.files[0];


        const data = await accountService
            .uploadFile(files)
        if (type == 'bannerurl') {
            formikRef.current.setFieldValue('BannerURL', data?.url, false)
        }
        if (type == 'groupurl') {
            formikRef.current.setFieldValue('GroupURL', data?.url, false)
        }
        if (type == 'logourl') {
            formikRef.current.setFieldValue('LogoURL', data?.url, false)
        }
        if (type == 'logosquareurl') {
            formikRef.current.setFieldValue('LogoSquareURL', data?.url, false)
        }
        setIsLoading(false)
        // setFieldsForUpdatingUser(prevState => ({
        //   ...prevState,
        //   WorldImageURL: data.url
        // }))


    }


    function createGroup(fields, setSubmitting) {
        fields.EmailWildcards = emailWildCards
        fields.PredownloadChunks = predownloadChunks
        accountService.createGroups(fields)
            .then(() => {
                alertService.success('Groups added successfully', { keepAfterRouteChange: true });
                setTimeout(() => {
                    history.push('/groups');
                }, 2000)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const colorChangeHandler = (e, type) => {
        const { hex } = e;
        formikRef.current.setFieldValue('BGColor', hex, false)

    };

    const changeHandler = (e) => {
        const { name, value } = e.target;

        formikRef.current.setFieldValue(prevState => ({
            ...prevState,
            [name]: value
        }))

    }

    function updateGroup(id, fields, setSubmitting) {
        fields.EmailWildcards = emailWildCards
        fields.PredownloadChunks = predownloadChunks
        //console.log('fields with worlds',fields)
        accountService.updateGroup(id, fields)
            .then(() => {
                alertService.success('Groups updated successful', { keepAfterRouteChange: true });
                setTimeout(() => {
                    history.push('/groups');
                }, 2000)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }
    const formikRef = useRef();

    useEffect(() => {
        if (id != '') {
            accountService.getWorldByMapName(id).then(data => {
                //console.log("Worlds", data)
                setWorldsAssociated(data.Items)
            })
        }
    }, [])

    useEffect(() => {


        if (!isAddMode && formikRef.current) {
            // get user and set form fields
            accountService.getGroupsForSearchEquals(id).then(map => {
                const fields = ['name', 'BannerURL', 'BGColor', 'ClientName', 'ContactEmailFormat', 'ContactEmailSubject', 'ContactSenderEmail', 'ContactSenderName', 'EmailWildcards', 'GroupURL', 'GroupVerseWorldID', 'LogoSquareURL', 'LogoURL', 'PredownloadChunks', 'TickerInfo'];
                fields.forEach(field => {
                    return formikRef.current.setFieldValue(field, map.Items[0][field], false)
                });
                if (formikRef.current.values.EmailWildcards == undefined) {
                    formikRef.current.setFieldValue('EmailWildcards', [], false)
                }
                setEmailWildCards(formikRef.current.values.EmailWildcards)
                setPreDownloadChunks(formikRef.current.values.PredownloadChunks)
            });
        }
    }, []);

    useEffect(() => {
        if (isAddMode && formikRef.current) {
            // get user and set form fields
            const fields = ['name', 'BannerURL', 'BGColor', 'ClientName', 'ContactEmailFormat', 'ContactEmailSubject', 'ContactSenderEmail', 'ContactSenderName', 'EmailWildcards', 'GroupURL', 'GroupVerseWorldID', 'LogoSquareURL', 'LogoURL', 'PredownloadChunks', 'TickerInfo'];
            fields.forEach(field => formikRef.current.setFieldValue(field, initialValues[field], false));
        }
    }, [isAddMode]);

    const changeDropdown = (e) => {
        formikRef.current.setFieldValue('GroupVerseWorldID', e.target.value, false)
        // //console.log("e", e.target.value)
    }

    const deleteGroup = (e, groupName) => {
        e.preventDefault();
        if (window.confirm('Are sure you want to delete this Group. This is an irreversible action?') == true) {
            //console.log('deleted GroupName', groupName)
            accountService.deleteGroup(groupName).then(res => {
                //console.log("res", res)
                history.push('/groups');
            })
        } else {
            //console.log('cancelled', groupName)
        }

    }
    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="text-white mr-2">
                        {/* <i className="mdi mdi-account-plus"></i> */}
                    </span> {isAddMode ? 'Add Group' : 'Edit Group'} </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">

                        </li>
                    </ul>
                </nav>
            </div>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <Alert />
                        <div className="table-responsive">

                            <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formikRef} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting, setFieldValue }) => {


                                    return (
                                        <Form>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label title='Group ID of the group to be created'>Group Name</label>
                                                    <Field name="name" type="text" disabled={!isAddMode} className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='name of the client to which the world is assigned '>Client Name</label>
                                                    <Field name="ClientName" type="text" disabled={user.Item.Abilities.isGlobalAdmin != "true"} className={'form-control' + (errors.ClientName && touched.ClientName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="ClientName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='banner Image on the Responsive page/ Menu'>Banner Image</label>
                                                    <Field name="BannerURL" className={'form-control' + (errors.BannerURL && touched.BannerURL ? ' is-invalid' : '')}
                                                        type="file" value={undefined} onChange={e => fileUpload(e, 'bannerurl')} single="true" accept="image/*"
                                                    />
                                                    {isLoading &&
                                                        <div className='col-12 text-center'>
                                                            <LoadingIndicator />
                                                        </div>
                                                    }
                                                    <ErrorMessage name="BannerURL" component="div" className="invalid-feedback" />

                                                    {formikRef.current && formikRef.current.values.BannerURL &&
                                                        <div className="image-area">
                                                            <img src={formikRef.current && formikRef.current.values.BannerURL} width="100" height="100" />
                                                            <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); formikRef.current.setFieldValue('BannerURL', '', false) }}>&#215;</a>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='World name background label colour '>Background Color</label>
                                                    <Field name="BGColor" type="text" className={'form-control' + (errors.BGColor && touched.BGColor ? ' is-invalid' : '')} />
                                                    {formikRef.current && <InputColor name="BGColor"
                                                        initialValue={formikRef.current.values.BGColor}
                                                        onChange={e => colorChangeHandler(e, 'BGColor')}
                                                        placement="right"
                                                    />}
                                                    <ErrorMessage name="BGColor" component="div" className="invalid-feedback" />
                                                </div>

                                            </div>


                                            {/* 'name','BannerURL','BGColor','ClientName','ContactEmailFormat','ContactEmailSubject','ContactSenderEmail','ContactSenderName','EmailWildcards','GroupURL','GroupVerseWorldID','LogoSquareURL','LogoURL', 'PreDownloadChunks','TickerInfo'  */}
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label title='Image of the Group Verse button on the menu page'>Group Verse Button Image</label>
                                                    <Field name="GroupURL" type="file" value={undefined} onChange={e => fileUpload(e, 'groupurl')} single="true" accept="image/*" className={'form-control' + (errors.GroupURL && touched.GroupURL ? ' is-invalid' : '')} />
                                                    {isLoading &&
                                                        <div className='col-12 text-center'>
                                                            <LoadingIndicator />
                                                        </div>
                                                    }
                                                    <ErrorMessage name="GroupURL" component="div" className="invalid-feedback" />

                                                    {formikRef.current && formikRef.current.values.GroupURL &&
                                                        <div className="image-area">
                                                            <img src={formikRef.current && formikRef.current.values.GroupURL} width="100" height="100" />
                                                            <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); changeHandler({ target: { name: 'GroupURL', value: undefined } }) }}>&#215;</a>
                                                        </div>
                                                    }

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='Landing World for the group'>Group Verse World ID</label>
                                                    {(!id || worldsAssociated.length == 0) && <><Field name="GroupVerseWorldID" type="text" className={'form-control' + (errors.GroupVerseWorldID && touched.GroupVerseWorldID ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="ContactEmailSubject" component="div" className="invalid-feedback" /></>
                                                    }
                                                    {/* <Field name="GroupVerseWorldID" type="text" className={'form-control' + (errors.GroupVerseWorldID && touched.GroupVerseWorldID ? ' is-invalid' : '')} />
                                <ErrorMessage name="ContactEmailSubject" component="div" className="invalid-feedback" /> */}
                                                    {id && worldsAssociated.length > 0 &&
                                                        <Field name="GroupVerseWorldID" as="select" className={'form-control'} onChange={e => changeDropdown(e)}>
                                                            {worldsAssociated.map(worlds => (
                                                                <option value={worlds.WorldID} key={worlds.WorldID}>{worlds.WorldID}</option>
                                                            ))}
                                                        </Field>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='logo present at the bottom left corner after going inside the floor '>In-World Logo</label>
                                                    {isLoading &&
                                                        <div className='col-12 text-center'>
                                                            <LoadingIndicator />
                                                        </div>
                                                    }
                                                    <Field name="LogoSquareURL" type="file" value={undefined} onChange={e => fileUpload(e, 'logosquareurl')} single="true" accept="image/*" className={'form-control' + (errors.LogoSquareURL && touched.LogoSquareURL ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="LogoSquareURL" component="div" className="invalid-feedback" />

                                                    {formikRef.current && formikRef.current.values.LogoSquareURL &&
                                                        <div className="image-area">
                                                            <img src={formikRef.current && formikRef.current.values.LogoSquareURL} width="100" height="100" />
                                                            <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); formikRef.current.setFieldValue('LogoSquareURL', '', false) }}>&#215;</a>
                                                        </div>
                                                    }

                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='Logo of the group'>Main Logo</label>
                                                    {isLoading &&
                                                        <div className='col-12 text-center'>
                                                            <LoadingIndicator />
                                                        </div>
                                                    }
                                                    <Field name="LogoURL" className={'form-control' + (errors.LogoURL && touched.LogoURL ? ' is-invalid' : '')}
                                                        type="file" value={undefined} onChange={e => fileUpload(e, 'logourl')} single="true" accept="image/*"
                                                    />
                                                    <ErrorMessage name="LogoURL" component="div" className="invalid-feedback" />
                                                    {formikRef.current && formikRef.current.values.LogoURL &&
                                                        <div className="image-area">
                                                            <img src={formikRef.current && formikRef.current.values.LogoURL} width="100" height="100" />
                                                            <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); formikRef.current.setFieldValue('LogoURL', '', false) }}>&#215;</a>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="form-group col-12">
                                                    <label title='Welcome text present at the bottom on the menu/ responsive page'>Ticker</label>
                                                    <Field name="TickerInfo" type="text" className={'form-control' + (errors.TickerInfo && touched.TickerInfo ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="TickerInfo" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-mod-4">
                                                    <div className="card bg-light">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <label>Add Email Wild Card</label>
                                                                {formikRef.current && formikRef.current.values.EmailWildcards &&

                                                                    <EmailWildCardsArray items={formikRef.current && formikRef.current.values.EmailWildcards} setFieldValue={setFieldValue} type={"setEmailWildCards"} setEmailWildCards={setEmailWildCards} />}
                                                            </div></div></div></div>
                                                {/* <div className="form-group col-4">
                                <div className="card bg-light">
                                        <div className="card-body">
                                            <div className='row'>
                                    <label>Add Pre Download Chunks</label>
                                    {formikRef.current && formikRef.current.values.PredownloadChunks &&
                                                    
                                                    <PredownloadChunksArray items={formikRef.current && formikRef.current.values.PredownloadChunks  } setFieldValue={setFieldValue} type={"setPreDownloadChunks"} setPreDownloadChunks={setPreDownloadChunks}/>}
                                </div></div></div></div> */}
                                            </div>
                                            <div className="form-row bg-secondary p-5 mb-2">
                                                <div className="form-group col-md-6">
                                                    <label>Contact Email Format</label>
                                                    <Field name="ContactEmailFormat" type="text" className={'form-control' + (errors.ContactEmailFormat && touched.ContactEmailFormat ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="ContactEmailFormat" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Contact Email Subject</label>
                                                    <Field name="ContactEmailSubject" type="text" className={'form-control' + (errors.ContactEmailSubject && touched.ContactEmailSubject ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="ContactEmailSubject" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Contact Sender Email</label>
                                                    <Field name="ContactSenderEmail" type="text" className={'form-control' + (errors.ContactSenderEmail && touched.ContactSenderEmail ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="ContactSenderEmail" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Contact Sender Name (Use {"{name}"} for the Sender Name in format)</label>
                                                    <Field name="ContactSenderName" type="text" className={'form-control' + (errors.ContactSenderName && touched.ContactSenderName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="ContactSenderName" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Save
                                                </button>


                                                <Link to={isAddMode ? '.' : '..'} className="btn text-avatar">Cancel</Link>
                                                {id && (user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') && <button onClick={(e) => deleteGroup(e, formikRef.current.values.name)} className="btn btn-danger">
                                                    Delete
                                                </button>}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddGroup;