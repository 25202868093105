import React, { useEffect } from 'react'
import BooleanCustom from './Core/BooleanCustom'
import ColorCustom from './Core/ColorCustom'
import DateTimeCustom from './Core/DateTimeCustom'
import EnumCustom from './Core/EnumCustom'
import FileLinkCustom from './Core/FileLinkCustom'
import ImageLinkCustom from './Core/ImageLinkCustom'
import PdfCustom from './Core/PdfCustom'
import TextCustom from './Core/TextCustom'
import WorldIdCustom from './Core/WorldIdCustom'

const Core = ({coreFields}) => {

    return (
        <>
           {
               (coreFields.fieldName === 'BOOLEAN') && (
                    <div>
                        <BooleanCustom props={coreFields}/>
                    </div>
               ) ||
               (coreFields.fieldName === 'COLOR') && (
                    <div>
                        <ColorCustom />
                    </div>
                ) ||
                (coreFields.fieldName === 'DATETIME') && (
                    <div>
                        <DateTimeCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'ENUM') && (
                    <div>
                        <EnumCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'FILELINK') && (
                    <div>
                        <FileLinkCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'IMAGELINK') && (
                    <div>
                        <ImageLinkCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'PDF') && (
                    <div>
                        <PdfCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'TEXT') && (
                    <div>
                        <TextCustom props={coreFields}/>
                    </div>
                ) ||
                (coreFields.fieldName === 'WORLDID') && (
                    <div>
                        <WorldIdCustom props={coreFields}/>
                    </div>
                )
           }
        </>
    )
}

export default Core