import React from 'react'
import SplitFields from './Split/SplitFields'
import SplitRepeat from './Split/SplitRepeat'

const Split = ({splitFields, splitRepeat, splitRepeatFields, setSplitRepeatFields, allCustomFields, setAllCustomFields}) => {
    return (
        <>
            {
                Object.values(splitFields).length > 0 && (
                    <div>
                        <SplitFields splitFields={splitFields}/>
                    </div>
                )
            }
            {
                Object.values(splitRepeat).length > 0 && (
                    <div>
                        <SplitRepeat 
                            splitRepeat={splitRepeat} 
                            splitRepeatFields={splitRepeatFields} 
                            setSplitRepeatFields={setSplitRepeatFields} 
                            setAllCustomFields={setAllCustomFields} 
                            allCustomFields={allCustomFields}
                        />
                    </div>
                )
            }
        </>
    )
}

export default Split