import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';

const WorldIdCustom = ({CF, changeCustomFields,innerKeyName,mapName, filledCustomFields}) =>{
    // //console.log("filledCustomFields  world Id", filledCustomFields)
    const [worlds, setWorlds] = useState([]);
    let currentValue = CF[0] || "DEFAULT";

    useEffect(() => {
        
        accountService.getAllWorlds().then(x => {
          setWorlds(x.Items)
        });
    }, [worlds.length == 0]);
  
    const dropdownChangeHandler = (e) =>{
        const {name, value} = e.target
        changeCustomFields({target:{name:name, value:value}}, CF[0], innerKeyName)
    }
    // useEffect(()=>{
    //     if(textVal !=''){
    //         changeCustomFields({target:{name:customName['key'], value:textVal}}, mapName)
    //     }
        
    // },[textVal != ''])
    return (
        <>  
        <div className="row">
        <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
            <label>
                    {CF[0]} 
            </label>
        </div>
        
        <div className="col-4">
        {worlds.length >0 && 
             <select className="form-control form-control-lg" id="worldId" name={CF[0]} value={filledCustomFields && filledCustomFields[1]} defaultValue={'DEFAULT'} onChange={dropdownChangeHandler}>
                <option value="DEFAULT" disabled>Choose a World ...</option>
               {
                    
                    worlds.map(dd =>(
                        <option name={dd.WorldID} value={dd.WorldID} key={dd.WorldID}>{dd.WorldFriendlyName}</option>
                    ))

                }
            </select>
        }
        </div>
        </div>
       
        </>
    )
}
export default WorldIdCustom;