import React from "react"
import TextCustom from './Core/TextCustom';
import BooleanCustom from './Core/BooleanCustom';
import EnumCustom from './Core/EnumCustom';
import WorldIdCustom from './Core/WorldIdCustom';
import ImageLinkCustom from './Core/ImageLinkCustom';
import FileLinkCustom from './Core/FileLinkCustom';
import PdfCustom from './Core/PdfCustom';
import ColorCustom from './Core/ColorCustom';
import DateTimeCustom from './Core/DateTimeCustom';
const CoreCustom = ({ isLoading, setIsLoading, customField, formikRef, changeCustomFields, keyName, mapName, setFilledCustomFields, filledCustomFields }) => {
    // console.log("Core Data items", filledCustomFields)
    //  //console.log("Core Data items customField", customField)
    //  //console.log("Core Data items mapName", mapName)
    return (
        <>
            {/* filledCustomFields {JSON.stringify(filledCustomFields)} <br />
            customField0 {customField[0]}<br />
            customField1 {customField[1]}<br />
            mapName {mapName}<br /> */}
            {

                customField && Object.entries(customField).length > 0 &&
                customField[1] == 'TEXT' &&
                <>
                    {/* {filledCustomFields && filledCustomFields[0] === 'PLAYZONE_left_playlistVideo' && console.log("CoreCustom TEXT 21 cod1 ============>"
                        , '|', filledCustomFields
                        , '|', keyName
                        , '|', mapName, '|', filledCustomFields[0], '|', filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName))} */}

                    <TextCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            (/*filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0]
                                ||*/
                                filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&

                            Object.entries(filledCustomFields[1]).map(item => {
                                // console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    />  <br />
                </>
                ||

                customField[1] == 'BOOLEAN' &&
                <>
                    <BooleanCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0]
                            //     ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1].split('(') && customField[1].split('(')[0] == 'ENUM' &&
                <>
                    <EnumCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'WORLDID' &&
                <>
                    <WorldIdCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'IMAGELINK' &&
                <>
                    {/* {console.log("CoreCustom IMagelink 115 cod1 ", customField, filledCustomFields, (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||

                        filledCustomFields && filledCustomFields[0] == mapName))} */}
                    <ImageLinkCustom
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||

                            //     filledCustomFields && filledCustomFields[0] == mapName) &&
                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'FILELINK' &&
                <>
                    <FileLinkCustom
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'PDFLINK' &&
                <>
                    <PdfCustom
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'COLOR' &&
                <>
                    <ColorCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>
                ||

                customField[1] == 'DATETIME' &&
                <>
                    <DateTimeCustom
                        CF={customField}
                        changeCustomFields={changeCustomFields}
                        key={customField.fieldName}
                        mapName={mapName}
                        setFilledCustomFields={setFilledCustomFields}
                        innerKeyName={filledCustomFields && filledCustomFields[0] || mapName}
                        filledCustomFields={
                            // (filledCustomFields && filledCustomFields[0].split('_').length > 1 && filledCustomFields[0].split('_')[1].toLowerCase() == Object.entries(keyName)[0][0] ||
                            //     filledCustomFields && filledCustomFields[0] == mapName) &&

                            // Object.entries(filledCustomFields[1]).map(item => {
                            //     if (item[0] == customField[0]) {
                            //         return item
                            //     }
                            // }).filter(x => x !== undefined)[0]
                            (filledCustomFields && filledCustomFields.length > 1 && filledCustomFields[0].startsWith(mapName)) &&
                            Object.entries(filledCustomFields[1]).map(item => {
                                // filledCustomFields[1][item[0]] && console.log("CoreCustom TEXT 22 cod1", [item[0], '|', filledCustomFields[1][item[0]]])
                                if (item[0] == customField[0]) {
                                    return [item[0], filledCustomFields[1][item[0]]]
                                }
                            }).filter(x => x !== undefined)[0]
                        }
                    /> <br />
                </>

            }
        </>
    )
}

export default CoreCustom