import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { alertService } from '../_services';
// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const CustomAdminsArray = ({ items, setFieldValue, type, setCustomAdmin, setCustomAdminValidation }) => {

    const onSubmitCustomAdmin = (values) => {
        setCustomAdmin(values.CustomAdmins)
        //console.log("values", values)
        //   setFieldValue('CustomAdmins', arrayHelpers, false)
    }
    return (
        <>
            <div>
                {
                    items && items.length > 0 &&
                    <Formik
                        initialValues={{ CustomAdmins: items }}
                        onSubmit={values =>
                        // setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        // }, 500)
                        {
                            onSubmitCustomAdmin(values)
                            setCustomAdminValidation(false)
                            alertService.success('Custom Admins are submitted', { keepAfterRouteChange: true });
                        }
                        }
                        render={({ values }) => (
                            <Form>
                                <FieldArray
                                    name="CustomAdmins"
                                    render={arrayHelpers => (
                                        <div>

                                            {values.CustomAdmins && values.CustomAdmins.length > 0 ? (
                                                values.CustomAdmins.map((friend, index) => (
                                                    <div key={index} className={'field-arrangement'}>
                                                        <Field name={`CustomAdmins.${index}`} className="form-control" />
                                                        <button
                                                            type="button"
                                                            className="btn btn-inverse-success btn-icon"
                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button" className="btn btn-inverse-success btn-icon"
                                                            onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                    {/* show this when user has removed all CustomAdmins from the list */}
                                                    Add a Custom Admin
                                                </button>
                                            )}
                                            <div>
                                                <button type="submit" className="btn btn-primary">Submit Custom Admin before saving</button>
                                            </div>
                                        </div>
                                    )}
                                />
                            </Form>
                        )}
                    />
                }
                {
                    items && items.length == 0 &&
                    <Formik
                        initialValues={{ CustomAdmins: items }}
                        onSubmit={values =>
                            // setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            // }, 500)
                            onSubmitCustomAdmin(values)
                        }
                        render={({ values }) => (
                            <div>
                                <FieldArray
                                    name="CustomAdmins"
                                    render={arrayHelpers => (
                                        <div>

                                            {values.CustomAdmins && values.CustomAdmins.length > 0 ? (
                                                values.CustomAdmins.map((friend, index) => (
                                                    <div key={index} className={'field-arrangement'}>
                                                        <Field name={`CustomAdmins.${index}`} className="form-control" />
                                                        <button
                                                            type="button"
                                                            className="btn btn-inverse-success btn-icon"
                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button" className="btn btn-inverse-success btn-icon"
                                                            onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                    {/* show this when user has removed all CustomAdmins from the list */}
                                                    Add a Custom Admin
                                                </button>
                                            )}
                                            <div>
                                                <button type="submit" className="btn btn-primary">Submit Custom Admins before saving</button>
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                    />
                }
            </div>
        </>
    )
};

export default CustomAdminsArray;
