import React from 'react'
import MultiFields from './Multi/MultiFields'
import MultiRepeat from './Multi/MultiRepeat'

const Multi = ({multiFields, multiRepeat, multiRepeatFields, setMultiRepeatFields}) => {
    return (
        <>
            {
                Object.keys(multiFields).length > 0 && (
                    <div>
                        <MultiFields multiFields={multiFields}/>
                    </div>
                )
            }
            {
                Object.keys(multiRepeat).length > 0 && (
                    <div>
                        <MultiRepeat multiRepeat={multiRepeat} multiRepeatFields={multiRepeatFields} setMultiRepeatFields={setMultiRepeatFields}/>
                    </div>
                )
            }
        </>
    )
}

export default Multi