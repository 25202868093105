import React from 'react';
import {ThreeDots} from 'react-loader-spinner';

const LoadingIndicator = props => {
  
    return  <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        className="loading"
      >
        <ThreeDots width="100" strokeColor="#FF5733" />
      </div>
  };

  export default LoadingIndicator