import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';

const TextCustom = ({props}) =>{
    const [textVal, setTextVal] = useState('')
    const changeCustomFields = (e) => {
        props.value = e.target.value
        setTextVal(e.target.value)
    }
    useEffect(()=> {
        setTextVal(props.value || '')
    }, [])
        return (
        <>
        <div className="row my-2">
            <div className="col-4 d-flex align-items-center justify-content-end">
            <label>
                {props.friendlyName}
            </label>
            </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                <Field type="text" className={'form-control'} 
                name={props.fieldName} value={textVal}
                onChange={e=>changeCustomFields(e)} 
                />
                </div>
            </div>
        </>
    )
}
export default TextCustom;