import React from 'react'
import Core from '../Core'
import Split from '../Split'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const MultiRepeat = ({ multiRepeat }) => {
    const repeatFields = (repeat) => {
        console.log('repeatFields', repeat)
    }
    const removeFields = (remove) => {
        console.log('removeFields', remove)
    }

    return (
        <div className='position-relative mb-3'>
            <h1>MULTI REPEAT FIELDS</h1>
            <div className='add-remove-icons'>
                <button className='btn btn-link col-1 p-0' onClick={() => repeatFields(multiRepeat)}>
                    <AddCircleIcon />
                </button>
                <button className='btn btn-link col-1' onClick={() => removeFields(multiRepeat)}>
                    <RemoveCircleIcon />
                </button>
            </div>
            {
                Object.values(multiRepeat).map((multiRepeat, k) => {
                    if (multiRepeat.fType === 'CORE') {
                        return (
                            <div key={k}>
                                <label className='main-title'><h3>{multiRepeat.friendlyName}</h3></label>
                                <Core coreFields={multiRepeat} />
                            </div>
                        )
                    }
                    if (multiRepeat.fType === 'SPLIT') {
                        return (
                            <div key={k}>
                                <label className='main-title'><h3>{multiRepeat.friendlyName}</h3></label>
                                <Split splitFields={multiRepeat.splitFields} splitRepeat={multiRepeat.splitRepeat} />
                            </div>
                        )
                    }
                })
            }
            <hr />
        </div>
    )
}

export default MultiRepeat