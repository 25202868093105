import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { alertService } from '../_services';
// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const PakDownloadsArray = ({ items, setFieldValue, type, setPakDownloads, setPakDownloadsValidation }) => {

    const onSubmitPak = (values) => {
        setPakDownloads(values.PakDownloadsRequired)
        //console.log("values", values)
        //   setFieldValue('PakDownloadsRequired', arrayHelpers, false)
    }
    return (
        <div>
            {
                items && items.length > 0 &&
                <Formik
                    initialValues={{ PakDownloadsRequired: items }}
                    onSubmit={values =>
                    // setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    // }, 500)
                    {
                        onSubmitPak(values)
                        setPakDownloadsValidation(false)
                        alertService.success('Pak downloads details are submitted', { keepAfterRouteChange: true })
                    }
                    }
                    render={({ values }) => (
                        <Form>
                            <FieldArray
                                name="PakDownloadsRequired"
                                render={arrayHelpers => (
                                    <div>

                                        {values.PakDownloadsRequired && values.PakDownloadsRequired.length > 0 ? (
                                            values.PakDownloadsRequired.map((friend, index) => (
                                                <div key={index} className={'field-arrangement'}>
                                                    <Field name={`PakDownloadsRequired.${index}`} className="form-control" />
                                                    <button
                                                        type="button"
                                                        className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button" className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                {/* show this when user has removed all PakDownloadsRequired from the list */}
                                                Add a Pak Download
                                            </button>
                                        )}
                                        <div>
                                            <button type="submit" className="btn btn-primary">Submit a Pak Download before saving</button>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    )}
                />
            }
            {
                items && items.length == 0 &&
                <Formik
                    initialValues={{ PakDownloadsRequired: items }}
                    onSubmit={values =>
                        // setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        // }, 500)
                        onSubmitPak(values)
                    }
                    render={({ values }) => (
                        <Form>
                            <FieldArray
                                name="PakDownloadsRequired"
                                render={arrayHelpers => (
                                    <div>

                                        {values.PakDownloadsRequired && values.PakDownloadsRequired.length > 0 ? (
                                            values.PakDownloadsRequired.map((friend, index) => (
                                                <div key={index} className={'field-arrangement'}>
                                                    <Field name={`PakDownloadsRequired.${index}`} className="form-control" />
                                                    <button
                                                        type="button"
                                                        className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button" className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                {/* show this when user has removed all PakDownloadsRequired from the list */}
                                                Add a Pak Download
                                            </button>
                                        )}
                                        <div>
                                            <button type="submit" className="btn btn-primary">Submit Pak Download before saving</button>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    )}
                />
            }
        </div>
    )
};

export default PakDownloadsArray;
