
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PhotoshopPicker } from 'react-color';
import InputColor from 'react-input-color';

import { accountService, alertService } from '../_services';
import { Alert } from '../_components';
// import "react-datepicker/dist/react-datepicker.css";
import classnames from 'classnames'
import useWindowWidth from '../_hooks/useWindowWidth';
import { Role } from '../_helpers';
const Avatar = ({ history }) => {
  const user = accountService.userValue;
  const windowWidth = useWindowWidth();
  //console.log("user in profile",user)
  const [groups, setGroups] = useState({})
  const [fieldsForUpdatingUser, setFieldsForUpdatingUser] = useState([])
  const MALE = [{ label: 'male1', s3FileKey: 'male1' },
  { label: 'male2', s3FileKey: 'male2' },
  { label: 'male3', s3FileKey: 'male3' },
  { label: 'male4', s3FileKey: 'male4' },
  { label: 'male5', s3FileKey: 'avatar-base' },
  { label: 'female1', s3FileKey: 'female1' },
  { label: 'female2', s3FileKey: 'female2' },
  { label: 'female3', s3FileKey: 'female3' },
  { label: 'female4', s3FileKey: 'female4' },
  { label: 'female5', s3FileKey: 'female5' },
  { label: 'other1', s3FileKey: 'avatar-base' },
  { label: 'other2', s3FileKey: 'avatar-base' },
  { label: 'other3', s3FileKey: 'avatar-base' },
  { label: 'other4', s3FileKey: 'avatar-base' },
  { label: 'other5', s3FileKey: 'avatar-base' }
  ]
  // const FEMALE = ['Female 1', 'Female 2', 'Female 3', 'Female 4', 'Female 5']
  // const OTHER = ['Other 1', 'Other 2', 'Other 3', 'Other 4', 'Other 5']

  const [companyChooseImage, setCompanyChooseUrl] = useState(true)
  const [profileChooseImage, setProfileChooseUrl] = useState(true)
  const [owningGroupName, setOwningGroupName] = useState('');

  const [allGroupNames, setAllGroupNames] = useState({ name: [] });

  const [avatarMdl, setAvatarMdl] = useState(MALE)
  useEffect(() => {
    if (Object.keys(fieldsForUpdatingUser).length > 0) {
      accountService.getGroupsForSearchEquals(fieldsForUpdatingUser.OwningGroup).then(x => {
        setOwningGroupName(x.Items[0].ClientName)
      })
      const allGroups = []
      user.Item.Groups && user.Item.Groups.map(group =>
        accountService.getGroupsForSearchEquals(group).then(groupName => {
          allGroups.push(groupName.Items[0].ClientName)
          setAllGroupNames({ name: allGroups })
        })
      )

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(fieldsForUpdatingUser).length > 0])

  useEffect(() => {
    accountService.getById(user.Item.username).then(x => {
      console.log("prof details", x)
      setFieldsForUpdatingUser(x)
      setAvatarMdl(MALE)
      setCompanyChooseUrl(x.CompanyImageURL === "" ? true : false)
      setProfileChooseUrl(x.FaceImageURL === "" ? true : false)
      // const checkAvatarType = x.Avatar.AvatarModel.split(' ')[0]
      // if(checkAvatarType === 'Male') {
      //   setAvatarMdl(MALE)
      // }
      // if(checkAvatarType === 'Female') {
      //   setAvatarMdl(FEMALE)
      // }
      // if(checkAvatarType === 'Other') {
      //   setAvatarMdl(OTHER)
      // }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.Item.Role != Role.Admin) {
      accountService.getGroups().then(x => {
        console.log("x", x)
        setGroups(x)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initialValues = {
    username:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.username
        : "",
    AvatarModel:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.AvatarModel
        : "",
    Gender:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.Gender
        : "",
    Height:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.Height
        : "",
    PrimaryColor:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.PrimaryColor
        : "",
    SecondaryColor:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.SecondaryColor
        : "",
    SkinColor:
      Object.keys(fieldsForUpdatingUser).length > 0
        ? fieldsForUpdatingUser.Avatar.SkinColor
        : "",
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    console.log("124 changeHandler, Avatar =====> ", name, value, fieldsForUpdatingUser)
    setAvatarMdl(MALE)
    // if(value && value.toLowerCase() === 'male') {
    //   setAvatarMdl(MALE)
    // }
    // if(value && value.toLowerCase() === 'female') {
    //   setAvatarMdl(FEMALE)
    // } 
    // if(value && value.toLowerCase() === 'other') {
    //   setAvatarMdl(OTHER)
    // }
    if (value == null && name === "CompanyImageURL") {
      setCompanyChooseUrl(value === undefined ? true : false)
      setFieldsForUpdatingUser(prevState => ({
        ...prevState,
        [name]: value || ''
      }))
    }
    if (value == null && name === "FaceImageURL") {
      setProfileChooseUrl(value === undefined ? true : false)
      setFieldsForUpdatingUser(prevState => ({
        ...prevState,
        [name]: value || ''
      }))
    }
    else if (["DisplayName", "CompanyName", "StatusLine"].includes(name)) {
      setFieldsForUpdatingUser(prevState => ({
        ...prevState,
        [name]: value || ''
      }))
    }
    else {
      setFieldsForUpdatingUser((prevState) => ({
        ...prevState,
        Avatar: {
          ...prevState.Avatar,
          [name]: value
        }
      }));
    }


  };

  const colorChangeHandler = (e, type) => {
    const { hex } = e;

    setFieldsForUpdatingUser((prevState) => ({
      ...prevState,
      Avatar: {
        ...prevState.Avatar,
        [type]: hex,
      },
    }));
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();

    //console.log("fields for submitting", fieldsForUpdatingUser);
    accountService
      .update(user.Item.username, fieldsForUpdatingUser)
      .then(() => {
        alertService.success("Avatar updated successfully", {
          keepAfterRouteChange: true,
        });
        setSubmitting(false);
        history.push("/avatar");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  const [isDeleting, setIsDeleting] = useState(false);
  function onDelete() {
    if (window.confirm("Are you sure?")) {
      setIsDeleting(true);
      accountService
        .delete(user.id)
        .then(() => alertService.success("Account deleted successfully"));
    }
  }

  const fileUpload = (event, type) => {

    const files = event.target.files[0];
    let myFiles = Array.from(files);
    if (type == 'company') {
      accountService
        .uploadFile(files)
        .then((data) => {
          setFieldsForUpdatingUser(prevState => ({
            ...prevState,
            CompanyImageURL: data.url
          }))
          setCompanyChooseUrl(data.url ? false : true)
        });
    }
    if (type == 'face') {
      accountService
        .uploadFile(files)
        .then((data) => {
          setFieldsForUpdatingUser(prevState => ({
            ...prevState,
            FaceImageURL: data.url
          }))
          setProfileChooseUrl(data.url ? false : true)
        });
    }


  }

  if (Object.keys(fieldsForUpdatingUser).length == 0) return null;


  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          <span className="text-white mr-2">
          </span> Avatar </h3>
      </div>
      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Alert />
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Username (your login name)</label>
                                <Field
                                  name="username"
                                  type="text"
                                  disabled
                                  className={
                                    "form-control" +
                                    (errors.userName && touched.userName ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="username"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                            </div>
                            <div className='form-row'>
                              <div className="form-group col-12">
                                <label>First Name</label>
                                <Field
                                  name="FirstName"
                                  type="text"
                                  disabled
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.FirstName}
                                  className={
                                    "form-control" +
                                    (errors.firstName && touched.firstName ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="FirstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className='form-row'>
                              <div className="form-group col-12">
                                <label>Last Name</label>
                                <Field
                                  name="LastName"
                                  type="text"
                                  disabled
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.LastName}
                                  className={
                                    "form-control" +
                                    (errors.lastName && touched.lastName ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="LastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Body height (in cm)</label>
                                <Field
                                  name="Height"
                                  type="text"
                                  value={fieldsForUpdatingUser.Avatar.Height}
                                  className={"form-control"}
                                  onChange={changeHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {user.Item.Role != Role.Admin && Object.keys(groups).length > 0 &&

                              <div className="col-14 grid-margin stretch-card">
                                <div className="card">

                                  <div className="card-body">
                                    <h4><b>Groups</b></h4>
                                    <p>
                                      <u>Main Group</u>
                                    </p>
                                    <ul>
                                      <li key={owningGroupName}>
                                        {user.Item.OwningGroup && owningGroupName}
                                      </li>
                                    </ul>
                                    <p>
                                      <p>
                                        <u>Other Groups</u>
                                      </p>
                                      {allGroupNames.name.length > 0 && <ul>
                                        {allGroupNames.name.map(group => (
                                          <li key={group}>
                                            {group}
                                          </li>
                                        ))}
                                      </ul> || 'No Other Groups found'}
                                    </p>
                                    {user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.length > 0 &&
                                      <p>
                                        <u>You are an admin to</u>
                                        <ul>
                                          {user.Item.Abilities.GroupAdmin && user.Item.Abilities.GroupAdmin.map((admin) => (
                                            <li key={admin}>
                                              {admin}
                                            </li>
                                          ))}
                                        </ul>
                                      </p>
                                    }
                                    <br />
                                  </div>
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-5 mb-5">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Display Name (as shown on your nametag in VR)</label>
                                <Field
                                  name="DisplayName"
                                  type="text"
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.DisplayName}
                                  className={
                                    "form-control" +
                                    (errors.displayName && touched.displayName ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="LastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Company Name</label>
                                <Field
                                  name="CompanyName"
                                  type="text"
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.CompanyName}
                                  className={
                                    "form-control" +
                                    (errors.companyName && touched.companyName ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="LastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Status Line</label>
                                <Field
                                  name="StatusLine"
                                  type="text"
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.StatusLine}
                                  className={
                                    "form-control" +
                                    (errors.statusLine && touched.statusLine ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage
                                  name="LastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>Avatar Model</label>
                                <Field
                                  name="AvatarModel"
                                  as="select"
                                  className={"form-control"}
                                  onChange={changeHandler}
                                  value={fieldsForUpdatingUser.Avatar.AvatarModel}
                                >
                                  {avatarMdl.map(({ label, s3FileKey }, key) => (
                                    <option value={s3FileKey} key={key}>{label}</option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="AvatarModel"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group  col-sm-7 col-12">
                                <label>Profile Picture</label>
                                {profileChooseImage && <Field name="FaceImageURL" value={undefined} className={'form-control'}
                                  onChange={e => fileUpload(e, 'face')}
                                  type="file" accept="image/*"
                                  single="true"
                                />}
                                {fieldsForUpdatingUser.FaceImageURL && <div className="image-area">
                                  <img src={fieldsForUpdatingUser.FaceImageURL} width="100" height="100" />
                                  <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); changeHandler({ target: { name: 'FaceImageURL', value: undefined } }) }}>&#215;</a>
                                </div>}
                              </div>

                            </div>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label>T-shirt logo (front side)</label>
                                {companyChooseImage && <Field name="U_CompanyImageURL" type="file" value={undefined} className={'form-control'}
                                  onChange={e => fileUpload(e, 'company')} accept="image/*"
                                  single="true" />
                                }

                                {fieldsForUpdatingUser.CompanyImageURL && <div className="image-area">
                                  <img src={fieldsForUpdatingUser.CompanyImageURL} width="100" height="100" />
                                  <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); changeHandler({ target: { name: 'CompanyImageURL', value: undefined } }) }}>&#215;</a>
                                </div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="col-12 d-flex align-items-start justify-content-center" style={{ gap: 10 }}>
                              {fieldsForUpdatingUser?.FaceImageURL &&
                                <img
                                  src={fieldsForUpdatingUser?.FaceImageURL} alt=""
                                  width="100px" height="100px" />}
                              <div>
                                {fieldsForUpdatingUser?.DisplayName &&
                                  <h3>{fieldsForUpdatingUser?.DisplayName}</h3>}
                                {fieldsForUpdatingUser?.CompanyName &&
                                  <h3>{fieldsForUpdatingUser?.CompanyName}</h3>}
                                {fieldsForUpdatingUser?.StatusLine &&
                                  <h3>{fieldsForUpdatingUser?.StatusLine}</h3>}
                              </div>
                            </div>
                            <div className={classnames(windowWidth >= "1500" ? "justify-content-center" : "justify-content-start",
                              "col-sm-1 col-md-2 d-flex align-items-start")} style={{ 'height': '550px', marginTop: '5px', width: '100%' }}>
                              <div>
                                <div className="avatar">
                                  <svg id="shirt-svg" className="shirt__overlay" fill={fieldsForUpdatingUser.Avatar.PrimaryColor} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" width="800" height="800">
                                    <defs>
                                      <path id="shirt-shape" d="M338.5 287.3h-2.3l-27.9 15.6-31.3 15.4-25.9 15.4-20 12.3-10.9 18.9-8.9 58-3.9 27.5-3.9 30.6-1.7 11.6 2.2 4.7 21.2 6.6 22.8 4.4 14.6 4.1 3.7-.8 13.2 85.2 1.8 52.1-4.5 57.5-4 34.9 19.2 3.6 66.5 1.3h76l62.4-.6 34.4-.9 16.1-3.5-13.3-96.6-.3-54.2 9.9-86.5-.8-9.5 19.9-1.6 27.7-9.6 22.5-12.9 6.7-11-12.2-46.6L592 363l-6.4-20.7-12.9-17.3-51.7-17.5-30.1-8.2-30-15.4h-1.5l-16.9 4.1 4.7 15.6.2 3-7.5 11.1-9.4 9.9-33.6 10-35.8-9-12.3-12.8-5.9-7.8-1.6-3.9 4.5-15.8z" />
                                    </defs>
                                    <use xlinkHref="#shirt-shape" />
                                  </svg>
                                  <svg id="face-svg" className="face__overlay" fill={fieldsForUpdatingUser.Avatar.SkinColor} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" width="800" height="800">
                                    <defs>
                                      <path id="face-shape" d="m334.9 134.1-6.5-12.2-4.3 15-.1 8.6h7l1.1-6.6zM331.3 168.6l-8.7-.6v20l-3.8-1-5.5-19.7-2.7 1.3-3 3.1-2.1 12.1.6 13.3 2 8.4 4.7 7.1 7.5 7.2h5.8l4.6 21.3 3.9 8.1 6.1 7.6 5.9 6.2 1.2 7.1v13.8l-1.8 4.9-2 6.3-2.5 8.4.2 1.8 2.7 5 4.1 4.8 5.3 5.6 5.8 6.2 1.6 1.2 35.7 9.2 33.8-9.9 11.6-12.9 3.6-4.8 2.1-4.7-2.2-7.1-3.5-10.9-1-2.9-.6-14.8 1.3-6.8 6.5-6.1 4.4-5.9 3.1-5.7 2.1-4 2.1-10.3 2.2-10.8 5.9-.3 4.5-4.2 4.2-4.5 3.3-5.2V203l2.2-6.9v-12.9l-1-8.2-.6-3.9-4.2-3.8-11.5 1.6-3.5-.9-1.2 5.7-4.7 9.5-5.4 5.5-9.1 4.5-9 1-13.4.3-5.1-2h-26l-6.8.7-5.9.6-13.3.6-9.1-1.6-9.9-3.8-5.9-6.3-3.6-8.1-1.3-3.8z" />
                                    </defs>
                                    <use xlinkHref="#face-shape" />
                                  </svg>
                                  <img className="shirt__img"
                                    src={`https://wisprofileimages.s3.ap-south-1.amazonaws.com/${String(fieldsForUpdatingUser.Avatar.AvatarModel).toLowerCase()}.png`}
                                    alt=""
                                    width={800} height={800}
                                  />
                                  {fieldsForUpdatingUser?.CompanyImageURL &&
                                    <img className="shirt__img" style={{ top: 370, left: 350 }}
                                      src={fieldsForUpdatingUser?.CompanyImageURL} alt=""
                                      width="100px" height="100px" />}
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="form-group col-6 text-center">
                                <label>Shirt Color</label>
                                <div>
                                  <InputColor name="PrimaryColor" className="SkinColor"
                                    initialValue={fieldsForUpdatingUser.Avatar.PrimaryColor}
                                    onChange={e => colorChangeHandler(e, 'PrimaryColor')}
                                    placement="right"
                                  />
                                </div>
                              </div>
                              <div className="form-group col-6 text-center">
                                <label>Skin Color</label>
                                <div>
                                  <InputColor name="SkinColor" className="SkinColor"
                                    initialValue={fieldsForUpdatingUser.Avatar.SkinColor}
                                    onChange={e => colorChangeHandler(e, 'SkinColor')}
                                    placement="right"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary mr-2"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Submit
                      </button>

                      <Link to="." className="btn text-avatar">
                        Cancel
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}

export default Avatar;