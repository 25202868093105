import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { alertService } from '../_services';
// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const PaksArray = ({ items, setFieldValue, type, setPak }) => {

    const onSubmitPak = (values) => {
        setPak(values.Paks)
        //console.log("values", values)
        //   setFieldValue('Paks', arrayHelpers, false)
        // if (!values.Paks?.length) alertService.error('Add atleast 1 Pak detail', { keepAfterRouteChange: true })
        // if (values.Paks?.length) alertService.success('Pak details are submitted', { keepAfterRouteChange: true })
    }
    return (
        <div>
            {
                items && items.length > 0 &&
                <Formik
                    initialValues={{ Paks: items }}
                    onSubmit={values =>
                        // setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        // }, 500)
                        onSubmitPak(values)
                    }
                    render={({ values }) => (
                        <Form>
                            <FieldArray
                                name="Paks"
                                render={arrayHelpers => (
                                    <div>

                                        {values.Paks && values.Paks.length > 0 ? (
                                            values.Paks.map((friend, index) => (
                                                <div key={index} className={'field-arrangement'}>
                                                    <Field name={`Paks.${index}`} className="form-control" />
                                                    <button
                                                        type="button"
                                                        className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button" className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                {/* show this when user has removed all Paks from the list */}
                                                Add Pak
                                            </button>
                                        )}
                                        <div>
                                            <button type="submit" className="btn btn-primary">Submit a Pak before saving</button>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    )}
                />
            }
            {
                items && items.length == 0 &&
                <Formik
                    initialValues={{ Paks: items }}
                    onSubmit={values =>
                    // setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    // }, 500)
                    {
                        onSubmitPak(values)
                        if (!values.Paks?.length) alertService.error('Add atleast 1 Pak detail', { keepAfterRouteChange: true })
                        if (values.Paks?.length) alertService.success('Pak details are submitted', { keepAfterRouteChange: true })
                    }
                    }
                    render={({ values }) => (
                        <Form>
                            <FieldArray
                                name="Paks"
                                render={arrayHelpers => (
                                    <div>

                                        {values.Paks && values.Paks.length > 0 ? (
                                            values.Paks.map((friend, index) => (
                                                <div key={index} className={'field-arrangement'}>
                                                    <Field name={`Paks.${index}`} className="form-control" />
                                                    <button
                                                        type="button"
                                                        className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button" className="btn btn-inverse-success btn-icon"
                                                        onClick={() => arrayHelpers.insert(index + 1, "")} // insert an empty string at a position
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <button type="button" onClick={() => arrayHelpers.push("")} className="btn btn-primary mb-2">
                                                {/* show this when user has removed all Paks from the list */}
                                                Add Pak
                                            </button>
                                        )}
                                        <div>
                                            <button type="submit" className="btn btn-primary">Submit Pak before saving</button>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form>
                    )}
                />
            }
        </div>
    )
};

export default PaksArray;
