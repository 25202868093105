import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Avatar from './avatar/Avatar';
import DashboardPage from './dashboard-page/DashboardPage';
import GroupAccess from './group-access/GroupAccess';
import ListMaps from './maps/listMaps';
import Profile from './profile/Profile';
import RequestAccess from './request-access/RequestAccess';
import AddUsers from './users/addUsers';
import ListUsers from './users/listUsers';
import { PrivateRoute } from './_components';
import AddMap from './maps/addMap';
import ListWorlds from './worlds/listWorlds';
import AddWorld from './worlds/addWorld';

import AddGroup from './groups/addGroup';
import ListGroups from './groups/listGroups';
import AddWorlds from './world/addWorlds';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));



const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));


const BasicElements = lazy(() => import('./group-access/GroupAccess'));

const BasicTable = lazy(() => import('./tables/BasicTable'));



const Mdi = lazy(() => import('./icons/Mdi'));


const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register = lazy(() => import('./user-pages/Register'));

const ResetPassword = lazy(() => import('./user-pages/ResetPassword'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const ForgotPassword = lazy(() => import('./user-pages/ForgotPassword'));
const ChangePassword = lazy(() => import('./user-pages/ChangePassword'));




class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <PrivateRoute exact path="/dashboard1" component={ Dashboard } />
          <PrivateRoute exact path="/dashboard" component={ DashboardPage } />

          <PrivateRoute path="/avatar" component={ Avatar } />
          <PrivateRoute path="/profile" component={ Profile } />
          <PrivateRoute path="/group-access" component={ GroupAccess } />
          <PrivateRoute path="/request-access" component={ RequestAccess } />
          <PrivateRoute path="/users" component={ ListUsers } />
          <PrivateRoute path="/add" component={AddUsers} />
          <PrivateRoute path="/edit/:id" component={AddUsers} />
          <PrivateRoute path="/maps" component={ ListMaps } />
          <PrivateRoute path="/add-map" component={ AddMap } />
          <PrivateRoute path="/edit-maps/:id" component={ AddMap } />
          <PrivateRoute path="/groups" component={ ListGroups } />
          <PrivateRoute path="/add-group" component={AddGroup} />
          <PrivateRoute path="/edit-group/:id" component={ AddGroup } />
          <PrivateRoute path="/worlds" component={ ListWorlds } />
          <PrivateRoute path="/add-worlds" component={ AddWorld } />
          <PrivateRoute path="/edit-world/:id" component={ AddWorld } />

          <PrivateRoute path="/add-world-new" component={ AddWorlds } />
          <PrivateRoute path="/edit-world-new/:id" component={ AddWorlds } />

          <PrivateRoute path="/basic-ui/typography" component={ Typography } />

          <PrivateRoute path="/form-Elements/basic-elements" component={ BasicElements } />

          <PrivateRoute path="/tables/basic-table" component={ BasicTable } />


          <PrivateRoute path="/icons/mdi" component={ Mdi } />


          <PrivateRoute path="/charts/chart-js" component={ ChartJs } />


          <Route path="/user-pages/login" component={ Login } />
          <Route path="/user-pages/register" component={ Register } />
          <Route path="/user-pages/reset-password" component={ ResetPassword } />
          <Route path="/user-pages/forgot-password" component={ ForgotPassword } />
          <Route path="/user-pages/change-password" component={ ChangePassword } />
          <PrivateRoute path="/user-pages/lockscreen" component={ Lockscreen } />

          <PrivateRoute path="/error-pages/error-404" component={ Error404 } />
          <PrivateRoute path="/error-pages/error-500" component={ Error500 } />

          <PrivateRoute path="/general-pages/blank-page" component={ BlankPage } />


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;