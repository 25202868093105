import React, { useEffect, useState } from 'react'
import Core from '../Core'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
const SplitRepeat = ({splitRepeat, splitRepeatFields, setSplitRepeatFields, allCustomFields, setAllCustomFields, splitFieldsKey}) => {
    const [counter, setCounter] = useState(Object.keys(splitRepeatFields).length);
    const repeatFields = (splitRepeat)=> {
        
        let newField = {}
        for(const property in splitRepeat){
            findPath()
            let newObj = Object.assign({}, splitRepeat[property])
            newField = {
                [property]: {[property+`_${counter+1}`]: newObj}
            }
            setCounter(counter+1)
            console.log('newField', newField)
            setSplitRepeatFields(prevState => {
                // prevState[property] = {
                //     prevState[property], ...newField[property]
                // }
                console.log('newField prevState', prevState)
                return {...prevState, [property]: {...prevState[property],...newField[property]}}
            })
            
        }
    }
    const findPath = (ob, key) => {
        const path = [];
        const keyExists = (obj) => {
          if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
            return false;
          }
          else if (obj.hasOwnProperty(key)) {
            return true;
          }
          else if (Array.isArray(obj)) {
            let parentKey = path.length ? path.pop() : "";
      
            for (let i = 0; i < obj.length; i++) {
              path.push(`${parentKey}[${i}]`);
              const result = keyExists(obj[i], key);
              if (result) {
                return result;
              }
              path.pop();
            }
          }
          else {
            for (const k in obj) {
              path.push(k);
              const result = keyExists(obj[k], key);
              if (result) {
                return result;
              }
              path.pop();
            }
          }
          return false;
        };
      
        keyExists(ob);
      
        return path.join(".");
      }
   
    useEffect(()=> {
        if(Object.entries(splitRepeatFields).length > 0){
            
            Object.entries(splitRepeatFields).map(([key, val])=> {
                console.log('findPath all', allCustomFields, 'key', key)
                console.log('findPath', findPath(allCustomFields, key))
            })
            //console.log('repeatFields on load', splitRepeatFields)
            // setAllCustomFields(prevState => {
            //     console.log('prevState', prevState)
            //     return {...prevState, ...splitRepeatFields}
            // })
        }
    }, [splitRepeatFields])

    const removeFields = (splitRepeat)=> {
        console.log('removeFields', splitRepeat)
    }

   

    return (
        <div className='position-relative'>
         <h1>SPLIT REPEAT FIELDS</h1>
         <div className='add-remove-icons'>
            <button className='btn btn-link col-1 p-0' onClick={()=>repeatFields(splitRepeat)}>
                <AddCircleIcon />
            </button>
            <button className='btn btn-link col-1' onClick={()=>removeFields(splitRepeat)}>
                <RemoveCircleIcon />
            </button>
         </div>
        
            {
                Object.values(splitRepeat).map((splitRepeat, k) => {
                    if(splitRepeat.fType === 'CORE') {
                        return (
                            <div key={k}>
                                <Core coreFields={splitRepeat} />
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default SplitRepeat