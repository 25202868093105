import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';

const ImageLinkCustom = ({props}) =>{
    const [uploadedImage, setUploadedImage] = useState('')
    const fileUpload = (event, type) =>{
        const files = event.target.files[0];
            accountService
                .uploadFile(files)
                .then((data) =>{
                    setUploadedImage(data.url)
                    props.value = data.url
          });
    }

    const changeImageLinkText = (e) => {
        props.value = e.target.value
        setUploadedImage = e.target.value
    }

    return (
        <>
            <div className="row my-2">
            <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                    <label>
                        {props.friendlyName}
                    </label>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                    <Field name={props.fieldName} onChange={e=>changeImageLinkText(e)} value={props.value || ''} type="text" className={'form-control'}/>
                </div>
                <div className="offset-4 col-6 d-flex align-items-center justify-content-center">
                { <Field name={props.fieldName} type="file"  value={undefined}  className={'form-control'} onChange={e => fileUpload(e, props.fieldName)} single="true"/>}
                    </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                        {props.value && <img src={props.value} width="50" height="50"/>}
                </div>
            </div>
        </>
    )
}


export default ImageLinkCustom;