import { ButtonToolbar, ProgressBar } from 'react-bootstrap';
import {Bar, Doughnut} from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; 
import PakDownloadsArray from './pakDownloadsRequired';
import DownloadsArray from './downloadsRequired';
import CustomAdminsArray from './customAdmins';
import { Alert } from '../_components';

import _ from 'lodash'
import CustomFields from './CustomFields/CustomFields';
// import { CustomFields } from './CustomFields';

const AddWorlds = ({ history, match  }) => {
    const { id } = match.params;
    const user = accountService.userValue;
    const [downloads, setDownloads] = useState([])
    const [pakDownloads, setPakDownloads] = useState([])
    
    const [customAdmin, setCustomAdmin] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    //setting running Map
    const [runningMapForCustomFields, setRunningMapForCustomFields] = useState('')
    //get all custom fields
    const [allCustomFields, setAllCustomFields]  = useState([])

    const isAddMode = !id;
    const initialValues = {
        CustomFields: {
            Connect: "",
        },
        RunningMap: "",
        TalkDistance: "800",
        WorldDescription: "",
        WorldFriendlyName: "",
        WorldID: "",
        WorldImageURL: "",
        WorldStatus: "",
        isVisible: "",
        PakDownloadsRequired: [" "],
        DownloadsRequired:[" "],
        CustomAdmins:[" "]
    };
    const formikRef = useRef();
    const validationSchema = Yup.object().shape({
        WorldID: Yup.string()
            .required('World Id is required'),
        WorldFriendlyName: Yup.string()
            .required('World Name is required'),
    });

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        accountService.getGroupsData().then(x => {
            setGroups(x.Items)
            setIsLoading(false)
        });
        
    }, []);

    useEffect(() => {
        setIsLoading(true)
        accountService.getGroupsData().then(x => {
            setGroups(x.Items)
            setIsLoading(false)
        });
        
    }, []);

    useEffect(() => {
        if(runningMapForCustomFields != '' && isAddMode){
            setIsLoading(true)
            accountService.getAllCustomFields(runningMapForCustomFields).then(x => {
                console.log('Custom Fields', x)
                setAllCustomFields(x.Item.CFNew)
                setIsLoading(false)
            });
        }
    }, [runningMapForCustomFields != '' && isAddMode]);

    useEffect(() => {
        if(runningMapForCustomFields == ''){
            setAllCustomFields([])
        } 
    }, [runningMapForCustomFields == '']);

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createWorlds(fields, setSubmitting);
        } else {
            updateWorlds(id, fields, setSubmitting);
        }
    }

    const [downloadsValidation, setDownloadsValidation] = useState(false);
    const [customAdminValidation, setCustomAdminValidation] = useState(false);
    const [pakDownloadsValidation, setPakDownloadsValidation] = useState(false);
    function createWorlds(fields, setSubmitting) {
        if(checkEmptyValuesForDownloadsRequired() && checkEmptyValuesForPakDownloads() && checkEmptyValuesForCustomAdmin()){
            setDownloadsValidation(false)
            setCustomAdminValidation(false)
            setPakDownloadsValidation(false)
            fields.PakDownloadsRequired = pakDownloads
            fields.CustomAdmins = customAdmin
            fields.DownloadsRequired = downloads
            console.log("New fields ", fields)
            // accountService.createWorlds(fields)
            //     .then(() => {
            //         alertService.success('World added successfully', { keepAfterRouteChange: true });
            //         history.push(`/edit-world/${fields.WorldID}`);
            //         window.location.reload()
            //     })
            //     .catch(error => {
            //         setSubmitting(false);
            //         alertService.error(error);
            //     });
        } 
        if(checkEmptyValuesForDownloadsRequired() == false) {
            setDownloadsValidation(true)
        }
        if(checkEmptyValuesForPakDownloads() == false) {
            setPakDownloadsValidation(true)
        }
        if(checkEmptyValuesForCustomAdmin() == false) {
            setCustomAdminValidation(true)
        } 
        
    }

    function checkEmptyValuesForDownloadsRequired() {
        if(downloads.length > 0){
            return true
        } else{
            return false
        }
    }
    function checkEmptyValuesForPakDownloads() {
        if(pakDownloads.length > 0){
            return true
        } else{
            return false
        }
    }
    function checkEmptyValuesForCustomAdmin() {
        if(customAdmin.length > 0){
            return true
        } else{
            return false
        }
    }
    function updateWorlds(id, fields, setSubmitting) {
        setSubmitting(false)
        console.log('formikRef.current.values', allCustomFields)
        if(checkEmptyValuesForDownloadsRequired() && checkEmptyValuesForPakDownloads() && checkEmptyValuesForCustomAdmin()){
        fields.PakDownloadsRequired = pakDownloads
        fields.CustomAdmins = customAdmin
        fields.DownloadsRequired = downloads
        }
        // var object = formikRef.current.values.CustomFields.reduce(
        //     (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
        
        //  fields.CustomFields = formikRef.current.values.CustomFields
         //console.log("fields after update", fields)
        // accountService.updateWorlds(id, fields)
        //     .then(() => {
        //         alertService.success('Update successful', { keepAfterRouteChange: true });
        //        // history.push('/worlds');
        //     })
        //     .catch(error => {
        //         setSubmitting(false);
        //         alertService.error(error);
        //     });
        // }
    }


    const deleteWorld = (e, world) =>{
        e.preventDefault();
        if (window.confirm('Are sure you want to delete this World. This is an irreversible action?') == true) {
            // //console.log('deleted World', world)
            accountService.deleteWorld(world).then(res => {
                // //console.log("res", res)
                history.push('/worlds');
            })
          } else {
            // //console.log('cancelled', world)
          }
        
    }

    const fileUpload = (event, type) =>{
        setIsLoading(true)
        const files = event.target.files[0];
        const allFiles = event.target.files;
        let myFiles = Array.from(allFiles);

        if(type == 'world'){
          accountService
					.uploadFile(files)
					.then((data) =>{

        formikRef.current.setFieldValue('WorldImageURL', data.url, false)
        setIsLoading(false)
        // //console.log("formikRef.current",formikRef.current)
            // setFieldsForUpdatingUser(prevState => ({
            //   ...prevState,
            //   WorldImageURL: data.url
            // }))
          });
        }
        const uploadingFiles = []
        if(type == 'items'){
            myFiles.map(items=>
                    accountService
                        .uploadFile(items)
                        .then((data) =>{
                    // setAllItemsInUpload(oldArray => [...oldArray,data.url] );
                    setIsLoading(false)
                })
            )
            
          }
    }
    const changeDropdown = (e) => {
        formikRef.current.setFieldValue('RunningMap', e.target.value, false)
       // //console.log("e", e.target.value)
      
    }
    const changeDropdownWorldStatus = (e) => {
        formikRef.current.setFieldValue('WorldStatus', e.target.value, false)
       // //console.log("e", e.target.value)
      
    }

    const changeRunningMap = e => {
        const {key, value} = e.target
        console.log('Running Map', value)
        formikRef.current.setFieldValue('RunningMap', value, false)
        setRunningMapForCustomFields(value)
    }
   

    useEffect(() => {
        if (!isAddMode && formikRef.current) {
            // get user and set form fields
            const allCustomFields = []
            accountService.getByWorldName(id).then(world => {
                const fields = ['WorldID', 'RunningMap', 'TalkDistance', 'WorldDescription', 'WorldFriendlyName', 'WorldImageURL', 'WorldStatus', 'isVisible', 'PakDownloadsRequired', 'DownloadsRequired', 'CustomAdmins','CustomFields', 'CustomFieldsNew'];
                fields.forEach(field => formikRef.current.setFieldValue(field, world.Item[field], false));
                setAllCustomFields(formikRef.current.values.CustomFieldsNew)
                setDownloads(formikRef.current.values.DownloadsRequired)
                setPakDownloads(formikRef.current.values.PakDownloadsRequired)
                setCustomAdmin(formikRef.current.values.CustomAdmins)
                  
            });
        }
        
    }, []);
    useEffect(() => {
    if (isAddMode && formikRef.current) {
        // get user and set form fields
        const fields = ['WorldID', 'RunningMap', 'TalkDistance', 'WorldDescription', 'WorldFriendlyName', 'WorldImageURL', 'WorldStatus', 'isVisible', 'PakDownloadsRequired', 'DownloadsRequired', 'CustomAdmins', 'CustomFields'];
        fields.forEach(field => formikRef.current.setFieldValue(field, initialValues[field], false));
    }
    }, [isAddMode]);


    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            <span className="page-title-icon bg-gradient-primary text-white mr-2">
              <i className="mdi mdi-account-plus"></i>
            </span> {isAddMode ? 'Add World' : 'Edit World'} </h3>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                  </li>
                </ul>
              </nav>
            </div>
            
            <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <Alert />
                <div className="table-responsive">
                  
                <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formikRef} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting, setFieldValue }) => {
                    

                return (
                    <div>
                    
                    {<Form>
                        
                        <div className="form-row">
                            <div className="form-group col-6">
                                <label>World Id</label>
                                <Field name="WorldID" type="text" disabled={!isAddMode} className={'form-control' + (errors.WorldID && touched.WorldID ? ' is-invalid' : '')} />
                                <ErrorMessage name="WorldID" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-6">
                                <label>World Status</label>
                                {/* <Field name="WorldStatus" type="text"  className={'form-control' + (errors.WorldStatus && touched.WorldStatus ? ' is-invalid' : '')} />
                                <ErrorMessage name="WorldStatus" component="div" className="invalid-feedback" /> */}

                                {groups.length > 0 &&
                                <Field name="WorldStatus" as="select" className={'form-control' + (errors.WorldStatus && touched.WorldStatus ? ' is-invalid' : '')} onChange={e=>changeDropdownWorldStatus(e)}>
                                    {groups.map(group => (
                                        <option value={group.name} key={group.name}>{group.ClientName}</option>
                                    ))}
                                </Field>
                                
                                }
                            </div>
                            
                            <div className="form-group col-6">
                                <label>Running Map</label>
                                <Field name="RunningMap" type="text" onChange={e=>changeRunningMap(e)} className={'form-control' + (errors.RunningMap && touched.RunningMap ? ' is-invalid' : '')} />

                                {/* {id && maps.length > 0 &&
                                <Field name="RunningMap" as="select" className={'form-control' + (errors.RunningMap && touched.RunningMap ? ' is-invalid' : '')} onChange={e=>changeDropdown(e)}>
                                    {maps.map(map => (
                                        <option value={map.mapName} key={map.mapName}>{map.WorldName}</option>
                                    ))}
                                </Field>
                                
                                } */}
                                <ErrorMessage name="RunningMap" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            
                            <div className="form-group col-6">
                                <label>Display Name</label>
                                <Field name="WorldFriendlyName" type="text" className={'form-control' + (errors.WorldFriendlyName && touched.WorldFriendlyName ? ' is-invalid' : '')} />
                                <ErrorMessage name="WorldFriendlyName" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-6">
                                <label>Description</label>
                                <Field name="WorldDescription" type="text" className={'form-control' + (errors.WorldDescription && touched.WorldDescription ? ' is-invalid' : '')} />
                                <ErrorMessage name="WorldDescription" component="div" className="invalid-feedback" />
                            </div>
                            
                        </div>
                        <div className="form-row">
                        <div className="form-group col-6">
                                <label>World Image</label>
                                    <Field name="WorldImageURL" type="file" value={undefined}  className={'form-control'} onChange={e => fileUpload(e, 'world')} single="true"/>
                                   {formikRef.current && formikRef.current.values.WorldImageURL && <img src={formikRef.current && formikRef.current.values.WorldImageURL} width="100" height="100"/>}
                                <ErrorMessage name="WorldImageURL" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-6">
                                <label>Visible</label>
                                <Field  type="checkbox" className={'form-control'} name="isVisible" style={{"width": "2rem"}}/>
                                {/* <Field name="isVisible" as="select" className={'form-control'}>
                                    <option value=""></option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </Field> */}
                            </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col-12">
                                <label>Conversational Distance in cm</label>
                                <Field name="TalkDistance" type="number"  className={'form-control' + (errors.TalkDistance && touched.TalkDistance ? ' is-invalid' : '')} />
                                <ErrorMessage name="TalkDistance" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        

                        <div className="form-row">
                                
                            <div className="form-group col-4">
                            <div className="card bg-light">
                                    <div className="card-body">
                                        <div className='row'>
                                <label> Downloads Required</label>
                                {formikRef.current && (formikRef.current.values.DownloadsRequired || formikRef.current.values.DownloadsRequired == null) &&
                                                
                                                <DownloadsArray items={formikRef.current && formikRef.current.values.DownloadsRequired} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setDownloads={setDownloads}/>}
                            </div>
                            </div></div>
                            {
                                downloadsValidation && <div className='invalid-world-items'>Downloads cannot be empty</div>
                            }
                            </div>
                            <div className="form-group col-4">
                            <div className="card bg-light">
                                    <div className="card-body">
                                        <div className='row'>
                                <label> Custom Admins</label>
                                {formikRef.current && formikRef.current.values.CustomAdmins &&
                                                
                                                <CustomAdminsArray items={formikRef.current && formikRef.current.values.CustomAdmins} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setCustomAdmin={setCustomAdmin}/>}
                            </div></div></div>
                            {
                                customAdminValidation && <div className='invalid-world-items'>Custom Admins cannot be empty</div>
                            }</div>
                            <div className="form-group col-4">
                            <div className="card bg-light">
                                    <div className="card-body">
                                        <div className='row'>
                                <label>Add Pak Downloads</label>
                                {formikRef.current && formikRef.current.values.PakDownloadsRequired &&
                                                
                                                <PakDownloadsArray items={formikRef.current && formikRef.current.values.PakDownloadsRequired} setFieldValue={setFieldValue} type={"PakDownloadsRequired"} setPakDownloads={setPakDownloads}/>}
                            </div>
                            </div></div>
                            {
                                pakDownloadsValidation && <div className='invalid-world-items'>Pak Downloads Required cannot be empty</div>
                            }</div>
                        </div>
                        
                        <div className="form-group col-12">
                            {/* <div className="form-group col-4">
                                <label>Phone Number</label>
                                <Field name="phoneNumber" type="number" className={'form-control'} />
                            </div> */}
                              <div className="card bg-light">
                              

                            </div>
                            
                        </div>

                        {/* <div className="form-group col-12">
                            <div className="card bg-light p-3">
                                
                                <CustomFields setIsLoading={setIsLoading} customFields={customFieldStructure} formikRef={formikRef} changeCustomFields={changeCustomFields} setFilledCustomFields={setFilledCustomFields} filledCustomFields={filledCustomFields}/>
                            </div>
                        </div> */}
                        {Object.keys(allCustomFields).length > 0 &&
                        <div className="form-group col-12 justify-content-center d-flex">
                            <div className="card bg-light p-3">
                                <CustomFields allCustomFields={allCustomFields} setAllCustomFields={setAllCustomFields}/>
                            </div>
                        </div>
                        }      
                            <div className="form-group">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Save
                                </button>
                                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
                                {id && (user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') && <button onClick={(e)=>deleteWorld(e,formikRef.current.values.WorldID)} className="btn btn-danger">
                                Delete
                            </button>}
                            </div>
                    </Form>}
                    </div>
                );
            }}
        </Formik>
            </div>
        </div>
        </div>
      </div> 
      </div>
    );
}

export default AddWorlds;