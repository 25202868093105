import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';

const FileLinkCustom = ({props}) =>{
    const [uploadedFile, setUploadedFile] = useState('')
    const fileUpload = (event, type) =>{
        const files = event.target.files[0];
            accountService
                .uploadFile(files)
                .then((data) =>{
                    setUploadedFile(data.url)
                    props.value = data.url
            // changeCustomFields({target:{name:CF[0], value:data.url}}, CF[0], mapName)
          });
       
    }

    const changeFileUpload = (e) => {
        const {name, value} = e.target
    }
   
    return (
        <>
        <div className="row my-2">
        <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
            <label>
                {props.friendlyName}
            </label>
             </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                <Field name={props.fieldName} type="text" className={'form-control'} value={props.value || ''} onChange={e=>changeFileUpload(e)}/>
                </div> 
                <div className="offset-4 col-6 d-flex align-items-center justify-content-center">
            
                <Field type="file" value={undefined}  className={'form-control'} onChange={e => fileUpload(e)} single="true"/> 
                </div> 
            </div> 
        </>
    )
}
export default FileLinkCustom;