import React from 'react'
import Core from '../Core'
import Split from '../Split'

const MultiFields = ({ multiFields }) => {
    return (
        <div className='mb-3'>
            <h1>MULTI FIELDS</h1>
            {
                Object.values(multiFields).map((multiFields, k) => {
                    if (multiFields.fType === 'CORE') {
                        return (
                            <div key={k}>
                                <label className='main-title'><h3>{multiFields.friendlyName}</h3></label>
                                <Core coreFields={multiFields} />
                            </div>
                        )
                    }
                    if (multiFields.fType === 'SPLIT') {
                        return (
                            <div key={k}>
                                <label className='main-title'><h3>{multiFields.friendlyName}</h3></label>
                                <Split splitFields={multiFields.splitFields} splitRepeat={multiFields.splitRepeat} />
                            </div>
                        )
                    }
                })
            }
            <hr />
        </div>
    )
}

export default MultiFields