import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import InputColor from 'react-input-color';

const ColorCustom = ({CF, changeCustomFields,innerKeyName, mapName, filledCustomFields}) =>{
    const [color, setColor] = useState(filledCustomFields && filledCustomFields[1])
    const colorChangeHandler = (e) => {
    //     const { hex } = e;
    //    // //console.log("hex", hex, ' type', type)
    //    setColor(hex)
    //    changeCustomFields({target:{name:customName[0], value:hex}}, mapName)
       //formikRef.current.setFieldValue('Avatar', {...formikRef.current.values.Avatar, [type]: hex}, false)

        // formikRef.current.setFieldValue((prevState) => ({
        //   ...prevState,
        //   Avatar: {
        //     ...prevState.Avatar,
        //     [type]: hex,
        //   },
        // }));
      };

    return (
        <>
          <div className="row">
          <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
         <label>
                {CF[0]}
            </label>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
            <Field name={CF[0]} type="text" className={'form-control'} />
                <InputColor name={CF[0]}
                initialValue={color}
                onChange={e => colorChangeHandler(e)}
                placement="right"
            />
             </div>
            </div>
        </>
    )
}
export default ColorCustom;