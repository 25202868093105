import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import PaksArray from './paksRequired';
import { Alert } from '../_components';


const AddMap = ({ history, match }) => {
    const { id } = match.params;
    const user = accountService.userValue;
    const [customFieldsKeyValues, setCustomFieldsKeyValues] = useState([])
    const customFields = ["poll", "serverTravel", "elevatorPanel", "contactDetails", "playlistPlayer", "FILELINK", "TEXT", "IMAGELINK", "BOOLEAN", "pollElement", "presentation", "WORLDID"]
    const [pak, setPak] = useState([]);
    const isAddMode = !id;
    const initialValues = {
        mapName: '',
        WorldName: '',
        Live: '',
        Paks: [],
        CF: {
            "iPushMMT": "",
            "BackMMT": "",
            "MoveTo": "",
            "FrontMMT": "",
            "iShelfMMT": "",
            "TagBoxMMT": ""
        }
    };

    const validationSchema = Yup.object().shape({
        mapName: Yup.string()
            .required('Map Name is required'),
        // WorldName: Yup.string()
        //     .required('World Name is required'),
        // Live: Yup.string()
        //     .required('Live is required'),

    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createMaps(fields, setSubmitting);
        } else {
            updateMaps(id, fields, setSubmitting);
        }
    }

    function createMaps(fields, setSubmitting) {
        fields.Paks = pak
        var object = Array.isArray(fields.CF) ? fields.CF.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }), {}) : fields.CF;
        fields.CF = object
        accountService.createMaps(fields)
            .then(() => {
                alertService.success('Maps added successfully', { keepAfterRouteChange: true });
                setTimeout(() => {
                    history.push('/maps');
                }, 2000)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateMaps(id, fields, setSubmitting) {
        //console.log("maps",fields)
        setSubmitting(true);
        fields.Paks = pak
        var object = Array.isArray(fields.CF) ? fields.CF.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.value }), {}) : fields.CF;

        fields.CF = object
        accountService.updateMaps(id, fields)
            .then(() => {
                alertService.success('Update successful', { keepAfterRouteChange: true });
                setTimeout(() => {
                    history.push('/maps');
                }, 2000)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const deleteMap = (e, map) => {
        e.preventDefault();
        if (window.confirm('Are sure you want to delete this Map. This is an irreversible action?') == true) {
            //console.log('deleted Map', map)
            accountService.deleteMap(map).then(res => {
                //console.log("res", res)
                history.push('/maps');
            })
        } else {
            //console.log('cancelled', map)
        }

    }
    const formikRef = useRef();

    useEffect(() => {
        if (!isAddMode && formikRef.current) {
            const allCustomFields = []
            // get user and set form fields
            accountService.getByMapName(id).then(map => {
                const fields = ['mapName', 'WorldName', 'Paks', 'Live', 'CF'];
                fields.forEach(field => formikRef.current.setFieldValue(field, map.Item[field], false));
                setPak(formikRef.current.values.Paks)
                //console.log("custom fields here", formikRef.current.values.CF)
                Object.entries(formikRef.current.values.CF).map(customFieldsData =>
                    allCustomFields.push({ "key": customFieldsData[0], "value": customFieldsData[1] })
                )
                setCustomFieldsKeyValues(allCustomFields)
            });
        }
    }, []);

    useEffect(() => {
        if (isAddMode && formikRef.current) {
            // get user and set form fields
            const fields = ['mapName', 'WorldName', 'Paks', 'Live', 'CF'];
            fields.forEach(field => formikRef.current.setFieldValue(field, initialValues[field], false));
        }
    }, [isAddMode]);

    const changeKeyValue = (e, type, key) => {
        // console.log("value", type, ': ', e.target.value, "---key: ", key)
        if (type == 'key') {
            customFieldsKeyValues[key].key = e.target.value.replace(/_/g, '')
            customFieldsKeyValues[key].value = customFieldsKeyValues[key].value
            setCustomFieldsKeyValues(customFieldsKeyValues)
        }
        if (type == 'value') {
            customFieldsKeyValues[key].key = customFieldsKeyValues[key].key
            customFieldsKeyValues[key].value = e.target.value

            setCustomFieldsKeyValues(customFieldsKeyValues)
        }
        formikRef.current.setFieldValue('CF', customFieldsKeyValues, false)
    }

    const addCustomFields = (e) => {
        e.preventDefault();
        const newCustomField = { key: "", value: "poll" }
        setCustomFieldsKeyValues(prevState => [...prevState, newCustomField])
    }

    const deleteCustomField = (e, key) => {
        e.preventDefault();
        customFieldsKeyValues.splice(key, 1);
        setCustomFieldsKeyValues(customFieldsKeyValues)
        formikRef.current.setFieldValue('CF', customFieldsKeyValues, false)
    }
    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="text-white mr-2">
                    </span> {isAddMode ? 'Add Map' : 'Edit Map'} </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">

                        </li>
                    </ul>
                </nav>
            </div>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <Alert />
                        <div className="table-responsive">

                            <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formikRef} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting, setFieldValue }) => {


                                    return (
                                        <Form>

                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label>Map Name</label>
                                                    <Field name="mapName" type="text" disabled={!isAddMode} className={'form-control' + (errors.mapName && touched.mapName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="mapName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>World Name</label>
                                                    <Field name="WorldName" type="text" className={'form-control' + (errors.WorldName && touched.WorldName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="WorldName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>Live</label>
                                                    <Field name="Live" as="select" className={'form-control'}>
                                                        <option value=""></option>
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="form-group col-mod-4">
                                                <div className="card bg-light">
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <div className="col">
                                                                <label>Paks</label>
                                                                {formikRef.current && formikRef.current.values.Paks &&
                                                                    <PaksArray items={formikRef.current && formikRef.current.values.Paks} setFieldValue={setFieldValue} type={"Paks"} setPak={setPak} />}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-12">
                                                {/* <div className="form-group col-4">
                                <label>Phone Number</label>
                                <Field name="phoneNumber" type="number" className={'form-control'} />
                            </div> */}
                                                <div className="card bg-light">

                                                    <div className="card bg-light">


                                                        <div className="card-body" style={{ "border": "2px solid red" }}>
                                                            <label>Custom Fields</label><br /><br />
                                                            {
                                                                customFieldsKeyValues.map((customFieldsKeyValue, id) => (
                                                                    <>
                                                                        <div className="row" key={id}>
                                                                            <div className="col-md-5">
                                                                                <Field type="text" className={'form-control'} name={customFieldsKeyValue['key']} value={customFieldsKeyValue['key']} onChange={e => changeKeyValue(e, 'key', id)} />
                                                                            </div>

                                                                            <select className="col-5 form-control" id="customFieldId" name={customFieldsKeyValue['value']} value={customFieldsKeyValue['value']} defaultValue={'DEFAULT'} onChange={e => changeKeyValue(e, 'value', id)}>
                                                                                <option value="DEFAULT" disabled>Choose a Custom Field...</option>
                                                                                {
                                                                                    customFields.map(customField => (
                                                                                        <option name={customField} value={customField} key={customField}>{customField}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <div className="col-2">
                                                                                <button onClick={e => deleteCustomField(e, id)} className="btn btn-danger"> Delete</button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                            Underscores are not allowed for custom field names<br />
                                                            <button onClick={e => addCustomFields(e)} className="btn btn-primary"> New Field</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="form-group">
                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Save
                                                </button>
                                                <Link to={isAddMode ? '.' : '..'} className="btn text-avatar">Cancel</Link>
                                                {id && (user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') && <button onClick={(e) => deleteMap(e, formikRef.current.values.mapName)} className="btn btn-danger">
                                                    Delete
                                                </button>}
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddMap;