import { ProgressBar } from 'react-bootstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import { accountService, alertService } from '../_services';
import LoadingIndicator from '../_components/LoadingIndicator';
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import Favorites from './fieldsArray';
import AbilitiesList from './abilitiesArray';
import GroupAdmin from './abilitiesArray';
import GroupsArray from './groupsArray';
import { Alert } from '../_components';
//import { Role } from '../../../../../server/accounts/user.model';
import InputColor from 'react-input-color';


const AddUsers = ({ history, match }) => {
    const { path } = match;
    const user = accountService.userValue;

    const { id } = match.params;
    const isAddMode = !id;
    //console.log("Addmode", match)
    const [fav, setFav] = useState([]);
    const [groups, setGroups] = useState([]);
    const [abilities, setAbilities] = useState([]);
    const [avatar, setAvatar] = useState({});
    const [images, setImages] = useState({ company: "", face: "" })
    // const [isSubmitting, setIsSubmitting] = useState(false);
    const [allAbilities, setAllAbilities] = useState({ abilities: [] })
    const initialValues = {
        username: '',
        FirstName: '',
        LastName: '',
        Email: '',
        // Role: '',
        DisplayName: '',
        Gender: '',
        FaceImageURL: '',
        CompanyImageURL: '',
        Locale: '',
        Company: '',
        Avatar: {
            SecondaryColor: "",
            Height: "",
            Gender: "",
            SkinColor: "",
            PrimaryColor: "",
            AvatarModel: "",
        },
        Abilities: {
            MuteOthers: "false",
            CanTalk: "false",
            GroupAdmin: [],
            ControlUserPTT: "false",
            AllowedOnBeta: "false",
            isDeveloper: "false",
            CanSpawnAsDrone: "false",
            GatherUsers: "false",
            ChangeVoiceChannel: "false",
            KickUser: "false",
            isDashboardSuperadmin: "false",
            CanChangeServerCode: "false",
            isGlobalAdmin: "false",
            ForceCreateSession: "false",
            canOpenDoors: "false"
        },
        Favorites: [],
        Groups: [],
        PhoneNumber: '+919999999999',
        // password: '',
        // confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('User Name is required'),
        FirstName: Yup.string()
            .required('First Name is required'),
        LastName: Yup.string()
            .required('Last Name is required'),
        // PhoneNumber: Yup.string()
        //     .required('Phone Number is required'),
        Email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        // role: Yup.string()
        //     .required('Role is required'),
        // password: Yup.string()
        //     .concat(isAddMode ? Yup.string().required('Password is required') : null)
        //     .min(6, 'Password must be at least 6 characters'),
        // confirmPassword: Yup.string()
        //     .when('password', (password, schema) => {
        //         if (password) return schema.required('Confirm Password is required');
        //     })
        //     .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createUser(fields, setSubmitting);
        } else {
            updateUser(id, fields, setSubmitting);
        }
    }
    const changeName = (k) => {
        switch (k) {
            case 'SecondaryColor':
                return 'Face Mask Color'
                break;
            case 'SkinColor':
                return 'Skin Color'
                break;
            case 'PrimaryColor':
                return 'Shirt Color'
                break;
            case 'AvatarModel':
                return 'Avatar Model'
                break;
            default:
                return k
        }

    }

    function createUser(fields, setSubmitting) {
        fields.Favorites = fav
        fields.Groups = groups
        fields.Abilities.GroupAdmin = abilities
        accountService.create(fields)
            .then(() => {
                alertService.success('User added successfully', { keepAfterRouteChange: true });
                setSubmitting(false);
                setTimeout(() => {
                    history.push("/users");
                }, 2000)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }
    const fileUpload = (event, type) => {

        const files = event.target.files[0];
        if (type == 'company') {
            accountService
                .uploadFile(files)
                .then((data) => {
                    setImages(prevState => ({ ...prevState, company: data.url }))
                });
        }
        if (type == 'face') {
            accountService
                .uploadFile(files)
                .then((data) => {
                    setImages(prevState => ({ ...prevState, face: data.url }))
                });
        }


    }

    const [primary, setPrimary] = useState('')
    const [secondary, setSecondary] = useState('')
    const [skin, setSkin] = useState('')
    const [colorType, setColorType] = useState('')

    useEffect(() => {
        if (colorType == 'PrimaryColor') {
            formikRef.current.setFieldValue('Avatar', { ...formikRef.current.values.Avatar, ['PrimaryColor']: primary }, false)
            setColorType('')
        }
        if (colorType == 'SecondaryColor') {
            formikRef.current.setFieldValue('Avatar', { ...formikRef.current.values.Avatar, ['SecondaryColor']: secondary }, false)
            setColorType('')
        }
        if (colorType == 'SkinColor') {
            formikRef.current.setFieldValue('Avatar', { ...formikRef.current.values.Avatar, ['SkinColor']: skin }, false)
            setColorType('')
        }
    }, [colorType != ''])

    const colorChangeHandler = (e, type) => {
        const { hex } = e;
        // //console.log("hex", hex, ' type', type)
        setColorType(type)
        if (type == 'PrimaryColor') {
            setPrimary(hex)
        }
        if (type == 'SecondaryColor') {
            setSecondary(hex)
        }
        if (type == 'SkinColor') {
            setSkin(hex)
        }
        //formikRef.current.setFieldValue('Avatar', {...formikRef.current.values.Avatar, [type]: hex}, false)

        // formikRef.current.setFieldValue((prevState) => ({
        //   ...prevState,
        //   Avatar: {
        //     ...prevState.Avatar,
        //     [type]: hex,
        //   },
        // }));
    };
    function updateUser(id, fields, setSubmitting) {
        setSubmitting(false);
        // if(fields.Role == 'Developer'){
        //     fields.Abilities.isDeveloper = "true"
        //     fields.Abilities.isGlobalAdmin = "false"
        //     fields.Role='Admin'
        // }
        // if(fields.Role == 'Admin'){
        //     fields.Abilities.isGlobalAdmin = "true"
        //     fields.Abilities.isDeveloper = "false"
        //     fields.Role='Admin'
        // } 
        // if(fields.Role != 'Developer' && fields.Role != 'Admin'){
        //     fields.Abilities.isDeveloper = "false";
        //     fields.Abilities.isGlobalAdmin = "false"
        //     fields.Role='User'
        // }
        fields.Favorites = fav
        fields.Groups = groups
        fields.Abilities.GroupAdmin = abilities
        fields.CompanyImageURL = images.company;
        fields.FaceImageURL = images.face;
        Object.entries(formikRef.current.values.Abilities).forEach(function (key) {

            if (!Array.isArray(key[1])) {
                if (key[1] == true) {
                    key[1] = 'true'
                }
                if (key[1] == false) {
                    key[1] = 'false'
                }
                fields.Abilities[key[0]] = key[1]
            }
        })
        //console.log("Fields", fields)
        accountService.update(id, fields)
            .then(() => {
                window.scrollTo(0, 0)
                alertService.success('User Updated successfully', { keepAfterRouteChange: true });
                setTimeout(() => {
                    setSubmitting(false);
                    window.location.reload();
                }, 2000);
                // history.push("/users");
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const formikRef = useRef();
    useLayoutEffect(() => {
        if (!isAddMode && formikRef.current) {
            // get user and set form fields
            accountService.getById(id).then(user => {
                //console.log("Users", user)
                const fields = ['username', 'FirstName', 'LastName', 'Email', 'DisplayName', 'OwningGroup', 'Status', 'Gender', 'Favorites', 'FaceImageURL', 'CompanyImageURL', 'phoneNumber', 'Company', 'Locale', 'StatusLine', 'Groups', 'Abilities', 'Avatar'];
                fields.forEach(field => formikRef.current.setFieldValue(field, user[field], false));
                if (user.Abilities.GroupAdmin == undefined) {
                    formikRef.current.setFieldValue('Abilities', { ...formikRef.current.values.Abilities, ['GroupAdmin']: [] }, false)
                }

                const objAbility = Object.entries(formikRef.current.values.Abilities).forEach(function (key) {

                    if (!Array.isArray(key[1])) {
                        if (key[1] == 'true') {
                            key[1] = true
                        }
                        if (key[1] == 'false') {
                            key[1] = false
                        }
                        formikRef.current.setFieldValue('Abilities', { ...formikRef.current.values.Abilities, [key[0]]: key[1] }, false)
                    }

                    //   //console.log("value123", key[1]);
                    //   //console.log("key123", key[0])
                    // formikRef.current.setFieldValue(key, user[key], false)
                    // spy[key] = value == 'true' ? true : false

                });
                //    //console.log("objAbility", objAbility)
                //   formikRef.current && formikRef.current.values.Abilities.map(val => {
                //       if(!Array.isArray(val)){
                //           if(val == 'true'){
                //               return true
                //           }
                //           if(val == 'false'){
                //               return false
                //           }
                //       }
                //   })
                setImages({ company: user.CompanyImageURL, face: user.FaceImageURL })
                setFav(user.Favorites)
                setAbilities(user.Abilities.GroupAdmin && user.Abilities.GroupAdmin.length > 0 ? user.Abilities.GroupAdmin : [])
                setGroups(user.Groups)
            });
        }
    }, []);

    const checkDisabled = (key, value) => {
        // //console.log("key", key, " value : ", value)
        if ((user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true')) {
            if ((key == 'isGlobalAdmin' || key == 'isDeveloper' || key == 'isDashboardSuperadmin')) {
                return false
            }

        } if ((user.Item.Abilities.isGlobalAdmin == 'false' || user.Item.Abilities.isDeveloper == 'false')) {
            if ((key == 'isGlobalAdmin' || key == 'isDeveloper' || key == 'isDashboardSuperadmin')) {
                return true
            }

        }
    }

    const changeCheckbox = (e) => {
        setAllAbilities(prevState => { })
    }

    const confirmUser = (e) => {
        e.preventDefault()
        //console.log("user", e)
        accountService.confirmUser(formikRef.current.values.username)
            .then(() => {
                alertService.success('User Confirmed Successfully', { keepAfterRouteChange: true });
                // setIsSubmitting(false);
                window.location.reload()
            })
            .catch(error => {
                // setIsSubmitting(false);
                alertService.error(error);
            });
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="text-white mr-2">

                    </span> {isAddMode ? 'Add User' : 'Edit User'} </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">

                        </li>
                    </ul>
                </nav>
            </div>

            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <Alert />
                        <div className="table-responsive">

                            <Formik initialValues={initialValues} validationSchema={validationSchema} innerRef={formikRef} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting, setFieldValue }) => {
                                    console.log("371 addUsers editUsers", isSubmitting)

                                    return (
                                        <Form>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label title='Display name of the user in toolbox '><h4>User Name</h4></label>
                                                    <Field name="username" type="text" disabled={!isAddMode} className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                                </div>
                                                {formikRef.current &&
                                                    <div className="form-group col-md-6">
                                                        <label title='User verification status'><h4>Status</h4></label>
                                                        <div className="">
                                                            <div className='form-control border-0 d-flex col-6'>{formikRef.current && formikRef.current.values.Status || 'Unassigned'}</div>
                                                            {formikRef.current && formikRef.current.values.Status == 'UNCONFIRMED' && (user.Item.Abilities.isGlobalAdmin == 'true' || user.Item.Abilities.isDeveloper == 'true') &&
                                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary" onClick={e => confirmUser(e)}>
                                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                                    Confirm User
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group col-md-6">
                                                    <label><h4>First Name</h4></label>
                                                    <Field name="FirstName" type="text" className={'form-control' + (errors.FirstName && touched.FirstName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="FirstName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label><h4>Last Name</h4></label>
                                                    <Field name="LastName" type="text" className={'form-control' + (errors.LastName && touched.LastName ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="LastName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <Field name="phoneNumber" type="hidden" className={'form-control' + (errors.PhoneNumber && touched.PhoneNumber ? ' is-invalid' : '')} />
                                                </div>

                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label><h4>Email</h4></label>
                                                    <Field name="Email" type="text" className={'form-control' + (errors.Email && touched.Email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="Email" component="div" className="invalid-feedback" />
                                                </div>
                                                {/* <div className="form-group col">
                                <label title='Display name of the user in toolbox '><h4>Role</h4></label>
                                <Field name="Role" as="select" className={'form-control' + (errors.Role && touched.Role ? ' is-invalid' : '')}>
                                    <option value=""></option>
                                    <option value="User">User</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Developer">Developer</option>
                                </Field>
                                <ErrorMessage name="Role" component="div" className="invalid-feedback" />
                            </div> */}
                                                <div className="form-group col-md-6">
                                                    <label title='Display name of the user in toolbox '><h4>Display Name</h4></label>
                                                    <Field name="DisplayName" type="text" className={'form-control'} />
                                                    <ErrorMessage name="DisplayName" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label><h4>Gender</h4></label>
                                                    <Field name="Gender" as="select" className={'form-control'}>
                                                        <option value=""></option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </Field>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label><h4>Profile Picture</h4></label>
                                                    <Field name="FaceImageURL" type="file" accept="image/*" className={'form-control'}
                                                        onChange={e => fileUpload(e, 'face')} value={undefined}
                                                        single="true" />
                                                    {images.face != '' && <div className="image-area">
                                                        <img src={images.face} width="100" height="100" />
                                                        <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); setImages({ face: "" }) }}>&#215;</a>
                                                    </div>}
                                                    {/* {images.face != '' && <img src={images.face} width="100" height="100"/>} */}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label title='Logo on the Avatar body'><h4>Your Logo</h4></label>
                                                    <Field name="CompanyImageURL" type="file" accept="image/*" className={'form-control'}
                                                        onChange={e => fileUpload(e, 'company')} value={undefined}
                                                        single="true" />
                                                    {images.company != '' && <div className="image-area">
                                                        <img src={images.company} width="100" height="100" />
                                                        <a className="remove-image" href="#" style={{ "display": "inline" }} onClick={e => { e.preventDefault(); setImages({ company: "" }) }}>&#215;</a>
                                                    </div>}
                                                    {/* {images.company != ''&&<img src={images.company} width="100" height="100"/>} */}
                                                </div>
                                                {/* <div className="form-group col-4">
                                <label title='Display name of the user in toolbox '>Phone Number</label>
                                <Field name="phoneNumber" type="number" className={'form-control'} />
                            </div> */}
                                                <div className="form-group col-md-6">
                                                    <label><h4>Company</h4></label>
                                                    <Field name="Company" type="text" className={'form-control'} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label><h4>Locale</h4></label>
                                                    <Field name="Locale" type="text" className={'form-control'} />
                                                </div>
                                                {(user.Item.Abilities.isGlobalAdmin == "true" || user.Item.Abilities.isDeveloper == "true") &&
                                                    <div className="form-group col-md-6">
                                                        <label title='DGroup assigned to the user'><h4>Owning Group</h4></label>
                                                        <Field name="OwningGroup" type="text" className={'form-control'} />
                                                    </div>
                                                }
                                            </div>

                                            <hr />
                                            <div className="form-row">
                                                {/* <div className="form-group col-4">
                                    <label title='Display name of the user in toolbox '>Phone Number</label>
                                    <Field name="phoneNumber" type="number" className={'form-control'} />
                                </div> */}


                                                <div className="form-group col-12">
                                                    <label title='Permissions granted to the user'><h4 className='form-titles'>Abilities</h4></label>
                                                </div>
                                                {
                                                    formikRef.current && Object.keys(formikRef.current.values.Abilities).length > 0 && (
                                                        Object.entries(formikRef.current.values.Abilities).sort().map(([k, value]) => (
                                                            // <div key={key}>{value}</div>
                                                            // <AbilitiesList items={formikRef.current && formikRef.current.values.Abilities} setFieldValue={setFieldValue} type={"Abilities"} key={key} value={value} setAbilities={setAbilities}/> value={`${k}`} value={`${value}`} 
                                                            <div className="form-group col-md-4" key={k}>
                                                                <>

                                                                    {<div className="d-flex align-items-center justify-content-between">
                                                                        <label >{!Array.isArray(value) && k}</label>
                                                                        {!Array.isArray(value) &&
                                                                            <>
                                                                                {/* <Field  type="text" className={'form-control'}  name={`Abilities[${k}]`} value={k}/> */}
                                                                                {/* <Field  type="text" className={'form-control'} disabled={checkDisabled(k, value)} name={`Abilities[${k}]`}/> */}
                                                                                <Field type="checkbox" className={'form-control'} disabled={checkDisabled(k, value)} name={`Abilities[${k}]`} style={{ "height": "1.5rem", "width": '8rem' }} />
                                                                                {/* <Field type="text" className={'form-control'} name={Array.isArray(value) && value.toString() || value}/> */}
                                                                                {/* <button
                                                                                        type="button"
                                                                                        className="btn btn-inverse-success btn-icon"
                                                                                        onClick={() => {delete formikRef.current.values.Abilities[k]; 
                                                                                            formikRef.current.setFieldValue('Abilities', formikRef.current.values.Abilities, false)}} // remove a friend from the list
                                                                                    >
                                                                                        -
                                                                                    </button> */}
                                                                            </>
                                                                        }

                                                                    </div>}
                                                                </>

                                                                {(user.Item.Abilities.isGlobalAdmin == "true" || user.Item.Abilities.isDeveloper == "true") && <div className='mt-1 mb-5 group-admin-arr'>
                                                                    <label >{Array.isArray(value) && k}</label>
                                                                    {Array.isArray(value) &&
                                                                        <div className="card  bg-light">

                                                                            {formikRef.current && formikRef.current.values.username &&
                                                                                <div className="card-body">
                                                                                    <GroupAdmin ability={formikRef.current && formikRef.current.values.Abilities.GroupAdmin} setFieldValue={setFieldValue} type={"GroupAdmin"} setAbilities={setAbilities} />
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    }

                                                                </div>
                                                                }
                                                            </div>
                                                        )))
                                                }
                                                {/* <button
                                                    type="button" className="btn btn-inverse-success btn-icon"
                                                    onClick={() => {
                                                        let keyName = `key ${Object.entries(formikRef.current.values.Abilities).length + 1}`;
                                                        Object.assign(formikRef.current.setFieldValue('Abilities', {...formikRef.current.values.Abilities, [keyName]: ''}, false))} }
                                                        
                                                        //   formikRef.current.values.Abilities, {"key": "val"})} // insert an empty string at a position
                                                >
                                                    +
                                                </button> */}
                                                {/* <AbilitiesList items={formikRef.current && formikRef.current.values.Abilities} setFieldValue={setFieldValue} type={"Abilities"} setAbilities={setAbilities}/> */}
                                                {/* <Field name="Company" type="text" className={'form-control'} /> */}



                                            </div>
                                            <hr />
                                            <div className="form-row">
                                                {/* <div className="form-group col-4">
                                    <label >Phone Number</label>
                                    <Field name="phoneNumber" type="number" className={'form-control'} />
                                </div> */}


                                                <div className="form-group col-12">
                                                    <label ><h4 className='form-titles'>Avatar</h4></label>
                                                </div>
                                                {
                                                    formikRef.current && Object.keys(formikRef.current.values.Avatar).length > 0 && (
                                                        Object.entries(formikRef.current.values.Avatar).map(([key, value]) => (
                                                            // <div key={key}>{value}</div>
                                                            // <AbilitiesList items={formikRef.current && formikRef.current.values.Abilities} setFieldValue={setFieldValue} type={"Abilities"} key={key} value={value} setAbilities={setAbilities}/> value={`${k}`} value={`${value}`} 
                                                            <div className="form-group col-md-6">
                                                                <>

                                                                    <div key={key}>
                                                                        <label >{!Array.isArray(value) && changeName(key)}</label>
                                                                        {!Array.isArray(value) &&
                                                                            <>
                                                                                {/* <Field  type="text" className={'form-control'}  name={`Abilities[${k}]`} value={k}/> */}
                                                                                {key == ('PrimaryColor') &&
                                                                                    <>
                                                                                        <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                        <InputColor name={`Avatar[${key}]`}
                                                                                            initialValue={formikRef.current && formikRef.current.values.Avatar[key]}
                                                                                            onChange={e => colorChangeHandler(e, key)}
                                                                                            placement="right"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                                {key == ('SecondaryColor') &&
                                                                                    <>
                                                                                        <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                        <InputColor name={`Avatar[${key}]`}
                                                                                            initialValue={formikRef.current && formikRef.current.values.Avatar[key]}
                                                                                            onChange={e => colorChangeHandler(e, key)}
                                                                                            placement="right"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                                {key == ('SkinColor') &&
                                                                                    <>
                                                                                        <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                        <InputColor name={`Avatar[${key}]`}
                                                                                            initialValue={formikRef.current && formikRef.current.values.Avatar[key]}
                                                                                            onChange={e => colorChangeHandler(e, key)}
                                                                                            placement="right"
                                                                                        />
                                                                                    </>

                                                                                }
                                                                                {key == ('AvatarModel') &&
                                                                                    <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                }
                                                                                {key == ('Gender') &&
                                                                                    <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                }
                                                                                {key == ('Height') &&
                                                                                    <Field type="text" className={'form-control'} name={`Avatar[${key}]`} />
                                                                                }
                                                                                {/* <Field type="text" className={'form-control'} name={Array.isArray(value) && value.toString() || value}/> */}
                                                                                {/* <button
                                                                                        type="button"
                                                                                        className="btn btn-inverse-success btn-icon"
                                                                                        onClick={() => {delete formikRef.current.values.Abilities[k]; 
                                                                                            formikRef.current.setFieldValue('Abilities', formikRef.current.values.Abilities, false)}} // remove a friend from the list
                                                                                    >
                                                                                        -
                                                                                    </button> */}
                                                                            </>
                                                                        }

                                                                    </div>
                                                                </>

                                                                {/* <div className='mt-1 mb-5'>
                                                                                <label >{Array.isArray(value) && k}</label>
                                                                                {Array.isArray(value) &&
                                                                                 <div className="card  bg-light">
                                                                                 <div className="card-body">
                                                                                     {formikRef.current && formikRef.current.values.username &&
                                                                                    <GroupAdmin ability={formikRef.current && formikRef.current.values.Avatar.GroupAdmin} setFieldValue={setFieldValue} type={"GroupAdmin"} setAbilities={setAbilities}/>
                                                                                     }
                                                                                    </div>
                                                                            </div>
                                                                                }
                                                                           
                                                                    </div>  */}
                                                            </div>
                                                        )))
                                                }
                                                {/* <button
                                                    type="button" className="btn btn-inverse-success btn-icon"
                                                    onClick={() => {
                                                        let keyName = `key ${Object.entries(formikRef.current.values.Abilities).length + 1}`;
                                                        Object.assign(formikRef.current.setFieldValue('Abilities', {...formikRef.current.values.Abilities, [keyName]: ''}, false))} }
                                                        
                                                        //   formikRef.current.values.Abilities, {"key": "val"})} // insert an empty string at a position
                                                >
                                                    +
                                                </button> */}
                                                {/* <AbilitiesList items={formikRef.current && formikRef.current.values.Abilities} setFieldValue={setFieldValue} type={"Abilities"} setAbilities={setAbilities}/> */}
                                                {/* <Field name="Company" type="text" className={'form-control'} /> */}



                                            </div>
                                            <div className="form-row">
                                                {/* <div className="form-group col-4">
                                <label >Phone Number</label>
                                <Field name="phoneNumber" type="number" className={'form-control'} />
                            </div> */}
                                                {/* <div className='form-group col-6'>
                                <div className="card bg-light">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="form-group col-12">
                                                <label ><h4>Favorites</h4></label>
                                                {formikRef.current && formikRef.current.values.username &&
                                                
                                                <Favorites items={formikRef.current && formikRef.current.values.Favorites} setFieldValue={setFieldValue} type={"Favorites"} setFav={setFav}/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div> */}
                                                <div className='form-group col-md-6'>
                                                    <div className="card bg-light">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className="form-group col-12">
                                                                    <label ><h4>Groups</h4></label>
                                                                    {formikRef.current && formikRef.current.values.username ?
                                                                        (<GroupsArray items={formikRef.current && formikRef.current.values.Groups} setFieldValue={setFieldValue} type={"Favorites"} setGroups={setGroups} />) : <p style={{ color: "red" }}>Set User Name to add to Group</p>
                                                                    }
                                                                    {/* <Field name="Company" type="text" className={'form-control'} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="form-group">

                                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Save
                                                </button>
                                                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUsers;