import { Field } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { accountService } from '../../../_services';

const PdfCustom = ({props}) =>{
    // const uploadedImage = {}
   
    return (
        <>
        <div className="row my-2">
        <div className="col-4 d-flex align-items-center justify-content-end">
            <label>
                {props.friendlyName}
            </label>
             </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                <Field  type="text" className={'form-control'} />
                </div> <div className="offset-4 col-4 d-flex align-items-center justify-content-center">
                <Field type="file" value={undefined}  className={'form-control'} single="true"/> 
            </div> 
            <div className="col-2 d-flex align-items-center justify-content-center">
                      &nbsp;
                </div>
            </div> 
        </>
    )
}
export default PdfCustom;